//Data for English language Start
const chinese = {
  ttPeopleInvolved: "参与人员",
  msgSent: "您的信息发送成功",
  msgSendFailed: "无法发送信息",
  daysAgo: "几天前",
  enterRepNo: "请输入您的道德案例编号",
  invalidCredentials: "无效的道德案例编号/密码",
  resetPassSuc: "密码重置成功。您的新密码是 ",
  clickHere: "点击这里",
  tfollowUp: "跟进",
  tyourCase: "您的举报. ",
  followUpTxt: "请输入举报编号和密码以跟进此案", // report follow up,
  //report home page
  emergencyNote: `本网站不得用于需要立即口头通知的紧急情况。紧急情况下，请联系您当地的紧急服务`,
  understand: "我了解",
  ttWhatHappened: "发生了什么？",
  lblIncidentDetails: "事故详情",
  ttWhereHappened: "事件发生地点？",
  pSelectLocation: "从菜单选项中选择地点",
  lblCountry: "国家",
  lblState: "州/省",
  lblCity: "市",
  lblLocation: "地点",
  phMissingPlace: "如果在上方找不到对于的地点，请在此处输入地点",
  requestInfo: "请提供以下信息:",
  liDateofSituation: "事件发生的日期/时间",
  liAssociatesInvovled:
    "涉及的员工姓名，包括可能了解或目睹您所描述的事件的人员",
  liSituationOccured: "事件发生的地点",
  liReportedAnyone:
    "您是否向公司中的任何人举报了您的疑虑？如果是，请提供是在什么时候，向谁报告的？",
  spIfNoLocation: "仅在上方未找到地点时才需要",
  ttWhoInvolved: "涉及人员？",
  pOtherPerson: "请提供涉及的其他人的姓名",
  btnAddPerson: "外加",
  ttContactInfo: "联系信息",
  pAnonymous: "您想保持匿名吗？",
  btnReviewForm: "审查文件",
  //details page - child component of report home page
  phFirstName: "名",
  phLastName: "姓",
  lblJobTitle: "职称",
  IncidentRole: "事件中的角色",
  btnRemove: "删除",
  phPhoneNo: "电话号码",
  phEmail: "电子邮件",
  valEmail: "请输入有效的电子邮件",

  // Report Review
  ttReportReview: "举报审查",
  ttConfirmDetails: "请确认举报的详细信息都是正确的，并创建密码",
  ttIncidentDetails: "事故明细",
  ttIncidentDescription: "事件描述",
  ttIncidentLocation: "事故地点",
  pImAnonymous: "匿名",
  btnEditReport: "编辑举报内容",
  fhtPasswordDesc: "密码将用于跟进所反映/提出的问题",
  pPasswordNote: ` 重要说明：您需要您的举报编号和密码才能登录到后续页面，以查看举报的状态或提供其他信息。为了保护您的隐私，以后将无法检索此信息（举报编号和密码）。我们不会直接回复或跟进您的电子邮件地址。`,
  btnCompleteReport: "完整举报",
  // follow up confirmation
  ttReportNo: "举报的后续跟进",
  ttAdditionalInfo: ` 感谢您提供更多有关道德操守的问题。我们将审核您的提供的信息，并可能使用“后续报告”页面要求您提供其他或更详细的信息，因此请定期检查该网站。`,
  // report confirm

  hReport: "举报",
  hCreated: "被创造",
  ttThanksforReport: "感谢您的举报",
  progressMsg: "我们正在创建您的道德问题。请耐心等待。。",
  ttFollowUpDet: "跟进细节",
  pReportReview: `我们的全球道德办公室将审核您提交的内容，并会在3-5个工作日内使用“后续报告”页面提供初步答复。`,
  pAdditionalInforReq: ` 我们的团队可能会使用“后续报告”页面来请求您提供其他或更详细的信息，因此请定期查看该网站`,
  pNeedPd: `您需要记下密码和举报编号，以便对该举报进行后续操作。为了保护您的隐私，以后将无法检索此信息 （密码和举报编号）`,
  pReportNo: "举报编号",
  ttReportFlwUp: "举报跟进",
  ttEnterReportNo: "请输入举报编号和密码以跟进此案",
  btnFindReport: "查找举报",
  ttFollowReview: "后续审查",
  ttConfirmDet: "请确认举报的细节",
  ttAddDetRes: "其他详细信息/回复",
  btnEdit: "编辑",
  btnSubmit: "提交",

  //Follow up reponse
  dReportDet: "举报详情",
  sIncidentDesc: "事件描述",
  ttReportHistory: "举报历史",
  sRepSubOn: "提交报告于",
  btnReview: "查看",
  pCaseClosed:
    "  此案件已结案。如果您还有其他问题，请访问“举报问题”页面以举报您的问题",
  ComFrmEthics: "来自道德办公室的沟通",
  Reporter: "举报人",
  System: "系统",

  //Reset Password,
  justNow: "刚才",
  fewMinutes: "几分钟前",
  hours: "一个小时前",
  month: "一个月前",
  year: "一年前",
  additionalInfo: "更多/补充信息",
  forgotPd: "忘记密码",
  yes: "是",
  start: "开始",
  end: "结束",
  no: "否",
  genricError: `请选择使用其它方式联络我们。您可以发送邮件到邮箱 "Ethics@Wal-mart.com"`,
  resetError:
    "禁止在24小时内再次重置。如果您还有其他信息要提供，请明天再试一次或发送电子邮件至Ethics@Wal-mart.com。您必须在电子邮件主题行中包含您的道德案例编号。",
  wrongAnswer: "你的回答与我们系统信息不符，请重试。",
  resetLimit:
    "3次登录失败系统将禁止重新设置密码。请在15分钟后再次尝试，或发送邮件至“Ethics@Wal-mart.com. ” 如果您还想提供更多信息，请在邮件标题中标注您的案件编号",
  resetPasswordHeader: "重新设置案件编号查询密码",
  answerTxt:
    "您需要正确回答安全问题方可重新设置密码。（您的答案要与之前设置的答案完全一致，包括空格和大写）",
  question1: "问题 1",
  question2: "问题 2",
  question3: "问题 3",
  btnVerify: "验证",
  btnReset: "重置",
  securtiyQuestionHdr:
    "选择3个安全问题并逐一作答后系统即可生成用于案件编号查询的密码。",
  generateButton: "密码生成",
  pPasswordtxt: "密码",
  SecurityQuestions: [
    { id: 1, question: "您在哪个城市遇到了您的第一任配偶/伴侣？" },
    { id: 2, question: "你父亲在哪个城市出生？" },
    { id: 3, question: "您的第一份工作是在哪个城市？" },
    { id: 4, question: "你母亲在哪个城市出生？" },
    {
      id: 5,
      question: "您最喜欢的高中老师的姓氏是什么？"
    },
    {
      id: 6,
      question: "您申请了但没有上的大学叫什么名字？"
    },
    { id: 7, question: "您父亲的中间名是什么？" },
    { id: 8, question: "您母亲的中间名是什么？" },
    {
      id: 9,
      question: "您最大的兄弟/姐妹的生日月份和年份是？ （例如1985年11月）"
    },
    { id: 10, question: "您最大的兄弟/姐妹的中间名是什么？" },
    { id: 11, question: "你最小的兄弟/姐妹的中间名是什么？" },
    { id: 12, question: "您六年级上的是哪所学校？" },
    {
      id: 13,
      question: "您小时候最好的朋友的名字是什么？"
    }
  ],
  roleIncidentOptions: [
    { value: "subject", label: "当事人" },
    { value: "witness", label: "证人" },
    { value: "affected_individual", label: "受影响的个人" },
    { value: "other", label: "其他" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "高级管理层" },
    { value: "management", label: "经理" },
    { value: "hourly_associate", label: "小时工/员工" },
    { value: "vendor_supplier", label: "供应商" },
    { value: "customer", label: "顾客" },
    { value: "other", label: "其他" }
  ]
};

export default chinese;
n
//Data for urdu language Start
const urdu = {
  msgSendFailed: "پیغام نہیں بھیجا جا سکا",
  ttPeopleInvolved: "شامل افراد",
  msgSent: "آپ کا پیغام کامیابی سے بھیج دیا گیا ہے",
  invalidCredentials: "غلط کیس نمبر/پاسورڈ",
  enterRepNo: "براہ کرم اپنا رپورٹ نمبر درج کریں",
  followUpTxt:
    "براہِ کرم اس کیس پر فالو اپ کے لیے رپورٹ نمبر اور پاسورڈ درج کریں", // report follow up,
  //report home page
  emergencyNote: `یہ ویب سائٹ ایسے ہنگامی معاملات کے لیے استعمال نہیں کی جانی چاہیے جن میں فوری زبانی اطلاع درکار ہو۔ ہنگامی صورتحال میں، براہِ کرم اپنی مقامی ہنگامی سروسز سے رابطہ کریں۔`,
  understand: "میں سمجھتا/سمجھتی ہوں",
  ttWhatHappened: "کیا ہوا؟",
  lblIncidentDetails: "واقعے کی تفصیلات",
  ttWhereHappened: "یہ کہاں رونما ہوا؟",
  pSelectLocation: "مینو کے آپشنز سے مقام منتخب کریں",
  lblCountry: "ملک",
  lblState: "ریاست",
  lblCity: "شہر",
  lblLocation: "مقام",
  phMissingPlace: "اگر اوپر مقام نہ مل پائے، تو یہاں درج کریں",
  requestInfo: "براہِ کرم اپنے خدشے کے حوالے سے درج ذیل معلومات فراہم کریں",
  liDateofSituation: "صورتحال کی تاریخ/وقت",
  liAssociatesInvovled:
    "واقعے میں شامل ساتھیوں کے نام بشمول وہ افراد جنہیں آپ کی بیان کردہ صورتحال کا ذاتی مشاہدہ یا علم ہو",
  liSituationOccured: "صورتحال رونما ہونے کا مقام",
  liReportedAnyone:
    "کیا آپ نے اپنے خدشے کی اطلاع کمپنی میں کسی اور کو دی ہے؟ اگر ہاں، تو کسے اور کب؟",
  spIfNoLocation: "صرف اسی صورت میں درکار ہوگا جب مقام اوپر نہ مل پائے",
  ttWhoInvolved: "کون شامل تھا؟",
  pOtherPerson: "براہِ کرم واقعے میں شامل دیگر افراد کے نام مہیا کریں",
  btnAddPerson: "مزید شامل کریں",
  ttContactInfo: "رابطے کی معلومات",
  pAnonymous: "کیا آپ گمنام رہنا چاہتے ہیں؟",
  btnReviewForm: "فارم کا جائزہ لیں",
  //details page - child component of report home page
  phFirstName: "پہلا نام",
  phLastName: "آخری نام",
  lblJobTitle: "ملازمتی عہدہ",
  IncidentRole: "واقعے میں کردار",
  btnRemove: "حذف کریں",
  phPhoneNo: "فون نمبر",
  phEmail: "ای میل",
  valEmail: "براہِ کرم درست ای میل درج کریں",

  // Report Review
  ttReportReview: "جائزے کی اطلاع دیں",
  ttConfirmDetails:
    "براہِ کرم رپورٹ کی تفصیلات کی تصدیق کریں اور پاسورڈ تخلیق کریں",
  ttIncidentDetails: "واقعے کی تفصیلات",
  ttIncidentDescription: "واقعے کے کوائف",
  ttIncidentLocation: "واقعے کا مقام",
  pImAnonymous: "گمنام",
  btnEditReport: "رپورٹ میں ترمیم کریں",
  fhtPasswordDesc: "آپ کی رپورٹ پر فالو اپ کے لیے پاسورڈ استعمال کیا جائے گا",
  pPasswordNote: ` اہم: اپنی رپورٹ کی کیفیت دیکھنے یا اضافی معلومات فراہم کرنے کے لیے فالو اپ صفحے پر لاگ ان کرنے کے لئے آپ کو اپنے رپورٹ نمبر اور پاسورڈ کی ضرورت ہے۔ آپ کی رازداری کے تحفظ کے لیے، ان معلومات کا حصول تاریخ گزرنے کے بعد نہیں ہوسکتا ہے۔ ہم براہِ راست آپ کے ای میل پتے پر جواب نہیں دیتے نہ ہی اس پر فالو اپ کرتے ہیں۔`,
  btnCompleteReport: "مکمل رپورٹ",

  // follow up confirmation
  ttReportNo: "رپورٹ کے لیے فالو اپ کی تصدیق",
  ttAdditionalInfo: ` اپنے اخلاقیاتی خدشے پر اضافی معلومات فراہم کرنے پر آپ کا شکریہ۔ ہماری ٹیم آپ کے جواب کا جائزہ لے گی اور رپورٹ کا فالو اپ صفحہ استعمال کرتے ہوئے اضافی معلومات یا تفصیلات طلب کرسکتی ہے، لہذا براہِ کرم باقاعدگی سے سائٹ ملاحظہ کریں۔
    `,

  // report confirm
  hReport: "رپورٹ",
  hCreated: "تخلیق کردہ",
  ttThanksforReport: "اپنا اخلاقیاتی خدشہ جمع کروانے پر آپ کا شکریہ",
  progressMsg: "ہم آپ کی اخلاقی تشویش پیدا کرنے کے عمل میں ہیں۔ صبر کرو..",
  ttFollowUpDet: "فالو اپ کی تفصیلات",
  pReportReview: `ہمارا عالمی اخلاقیاتی دفتر آپ کی جمع کردہ تفصیلات کا جائزہ لے گا اور ہمارا فالو اپ رپورٹ کا صفحہ استعمال کرتے ہوئے 3 سے 5 کاروباری دنوں کے اندر اندر ابتدائی جواب سے آگاہ کرے گا۔`,
  pAdditionalInforReq: ` ہماری ٹیم فالو اپ رپورٹ کا صفحہ استعمال کرتے ہوئے اضافی معلومات یا تفصیلات طلب کرسکتی ہے، لہذا براہِ کرم باقاعدگی سے سائٹ ملاحظہ کریں`,
  pNeedPd: `آپ کو اس رپورٹ پر فالو اپ کے لیے پاسورڈ اور رپورٹ نمبر درج کرنا ہوگا۔ آپ کی رازداری کے تحفظ کے لیے، یہ معلومات تاریخ گزرنے کے بعد دوبارہ حاصل نہیں کی جاسکتیں۔`,
  pReportNo: "رپورٹ نمبر",
  ttReportFlwUp: "رپورٹ کا فالو اپ",
  ttEnterReportNo:
    "اس کیس پر فالو اپ کے لیے براہِ کرم رپورٹ نمبر اور پاسورڈ درج کریں",
  btnFindReport: "رپورٹ تلاش کریں",
  ttFollowReview: "فالو اپ جائزہ",
  ttConfirmDet: "براہِ کرم رپورٹ کی تفصیلات کی تصدیق کریں",
  ttAddDetRes: "اضافی تفصیلات/جواب",
  btnEdit: "ترمیم کریں",
  btnSubmit: "جمع کروائیں",

  //Follow up reponse
  dReportDet: "مکمل رپورٹ",
  sIncidentDesc: "واقعے کے کوائف",
  ttReportHistory: "رپورٹ کا سابقہ ریکارڈ",
  sRepSubOn: "رپورٹ جمع کردہ مؤرخہ",
  btnReview: "جائزہ",
  pCaseClosed:
    "  یہ کیس بند ہوچکا ہے۔ اگر آپ کو کوئی اور خدشہ لاحق ہو، تو براہِ کرم اپنے خدشے کی اطلاع کے لیے خدشے کی اطلاع فراہم کریں کا صفحہ ملاحظہ کریں",
  ComFrmEthics: "اخلاقیاتی شعبے سے گفت و شنید",
  Reporter: "رپورٹر",
  System: "سسٹم",
  resetPassSuc: "پاسورڈ کامیابی سے دوبارہ سیٹ ہو گیا۔ آپ کا نیا پاسورڈ ہے: ",
  clickHere: "کے لئے یہاں کلک کریں ",
  tfollowUp: "پیروی",
  tyourCase: "آپ کا کیس. ",
  //Reset Password,
  justNow: "ابھی ابھی",
  fewMinutes: "چند منٹ پہلے",
  hours: "گھنٹہ پہلے",
  month: "مہینہ پہلے",
  year: "سال پہلے",
  additionalInfo: "اضافی معلومات",
  forgotPd: "پاسورڈ بھول گئے",
  daysAgo: " دن قبل",
  yes: "ہاں",
  start: "شروع کریں",
  end: "اختتام",
  no: "نہیں",
  genricError:
    "براہِ کرم ہم سے رابطے کے لیے متبادل طریقہ استعمال کریں۔ آپ Ethics@Wal-mart.com پر ای میل بھیج سکتے ہیں۔",
  wrongAnswer:
    "آپ کے جوابات ہمارے ڈیٹا سے مماثلت نہیں رکھتے۔ براہِ کرم دوبارہ کوشش کریں۔",
  resetError:
    "24 گھنٹوں کے دوران دوسری مرتبہ دوبارہ سیٹ کرنا ممنوع ہے۔ براہ کرم کل دوبارہ کوشش کریں یا اگر آپ اضافی معلومات فراہم کرنا چاہتے ہیں تو Ethics@Wal-mart.com پر ای میل کریں۔ ای میل کی سبجیکٹ لائن میں اپنا ایتھکس کیس نمبر ضرور شامل کریں۔",
  resetLimit:
    "تین ناکام کوششوں کے بعد ری سیٹ ممنوع ہے۔ براہِ کرم 15 منٹ بعد دوبارہ کوشش کریں یا اگر آپ کو اضافی معلومات فراہم کرنی ہیں تو Ethics@Wal-mart.com پر ای میل بھیجیں۔ ای میل کے عنوان میں آپ کو اپنا اخلاقیاتی کیس نمبر لازماً شامل کرنا ہوگا۔",
  resetPasswordHeader: "رپورٹ نمبر کے لیے پاسورڈ دوبارہ ترتیب دیں",
  answerTxt:
    "نیا پاسورڈ مرتب کرنے کے لیے درج ذیل سیکیورٹی سوالات کے جوابات دیں (جوابات مکمل طور پر مماثل ہونے چاہئیں، بشمول حروف کے درمیان وقفہ اور بڑے حروفِ تہجی)",

  question1: "1 سوال",
  question2: "2 سوال",
  question3: "3 سوال",
  btnVerify: "توثیق کریں",
  btnReset: "دوبارہ ترتیب دیں",
  securtiyQuestionHdr:
    "اپنی رپورٹ کے لیے پاسورڈ مرتب کرنے کے لیے تین سیکیورٹی سوالات منتخب کریں اور ان کے جوابات دیں:",
  generateButton: "پاسورڈ مرتب کریں",
  pPasswordtxt: "پاسورڈ",
  SecurityQuestions: [
    {
      id: 1,
      question: "آپ اپنے پہلے شریکِ حیات/پارٹنر سے کس شہر میں ملے تھے؟"
    },
    { id: 2, question: "آپ کے والد کس شہر میں پیدا ہوئے تھے؟" },
    { id: 3, question: "آپ کی پہلی ملازمت کس شہر میں تھی؟" },
    { id: 4, question: "آپ کی والدہ کس شہر میں پیدا ہوئی تھیں؟" },
    {
      id: 5,
      question: "ہائی اسکول میں آپ کے پسندیدہ استاد کا آخری نام کیا ہے؟"
    },
    {
      id: 6,
      question:
        "آپ کے اُس کالج کا نام کیا ہے جہاں آپ نے داخلے کی درخواست تو دی تھی لیکن کبھی گئے نہیں؟"
    },
    { id: 7, question: "آپ کے والد کا درمیانی نام کیا ہے؟" },
    { id: 8, question: "آپ کی والدہ کا درمیانی نام کیا ہے؟" },
    {
      id: 9,
      question:
        "آپ کے سب سے بڑے بھائی/بہن کی سالگرہ کا مہینہ اور سال کیا ہے؟ (مثلاً نومبر 1985)"
    },
    { id: 10, question: "آپ کے سب سے بڑے بھائی/بہن کا درمیانی نام کیا ہے؟" },
    { id: 11, question: "آپ کے سب سے چھوٹے بھائی/بہن کا درمیانی نام کیا ہے؟" },
    { id: 12, question: "چھٹے گریڈ کے لیے آپ کونسے اسکول میں گئے تھے؟" },
    {
      id: 13,
      question: "آپ کے بچپن کے بہترین دوست کا پہلا نام کیا تھا؟"
    }
  ],
  roleIncidentOptions: [
    { value: "subject", label: "فاعل" },
    { value: "witness", label: "گواہ" },
    { value: "affected_individual", label: "متاثرہ فرد" },
    { value: "other", label: "دیگر" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "افسر" },
    { value: "management", label: "انتظامیہ" },
    { value: "hourly_associate", label: "گھنٹہ وار رفیقِ کار" },
    { value: "vendor_supplier", label: "وینڈر/سپلائر" },
    { value: "customer", label: "کسٹمر" },
    { value: "other", label: "دیگر" }
  ]
};

export default urdu;
n
//Data for korean language Start
const korean = {
  msgSendFailed: "메시지 전송 실패",
  ttPeopleInvolved: "관련된 사람",
  msgSent: "메시지가 성공적으로 전송되었습니다",
  invalidCredentials: "유효하지 않은 사건 번호/패스워드",
  enterRepNo: "보고서 번호를 입력하십시오",
  followUpTxt:
    "사건에 대한 후속 처리를 위한 보고서 번호와 비밀번호를 입력하십시오", // report follow up,
  //report home page
  emergencyNote: `이 웹 사이트는 즉각적인 구두 통지가 필요한 긴급 상황에 사용해서는 안 됩니다. <br/> 응급 상황 발생 시에는 지역 응급 서비스에 문의하십시오.`,
  understand: "알겠습니다",
  ttWhatHappened: "어떤 일이 발생했습니까?",
  lblIncidentDetails: "사건 세부사항",
  ttWhereHappened: "어디에서 발생했습니까?",
  pSelectLocation: "메뉴 옵션에서 위치를 선택하십시오",
  lblCountry: "국가",
  lblState: "주",
  lblCity: "도시",
  lblLocation: "위치",
  phMissingPlace:
    "상기 목록에서 위치를 찾지 못하셨으면, 여기에 직접 입력하십시오",
  requestInfo: "우려사항과 관련하여 다음 정보를 제공해 주십시오",
  liDateofSituation: "상황이 발생한 날짜/시간",
  liAssociatesInvovled:
    "귀하가 설명 중인 상황에 대해 알고 있거나 목격한 자를 포함하여, 관련된 직원의 성명",
  liSituationOccured: "상황이 발생한 위치",
  liReportedAnyone:
    "회사 내의 누군가에게 우려사항을 보고한 적이 있습니까? 만약 그렇다면, 누구에게 언제 보고하였습니까?",
  spIfNoLocation: "상기 목록에서 위치를 찾지 못한 경우에만 필요함",
  ttWhoInvolved: "관련자는 누구입니까?",
  pOtherPerson: "해당 사건에 연루된 다른 사람들의 성명을 알려 주십시오",
  btnAddPerson: "기타 추가사항",
  ttContactInfo: "연락처 정보",
  pAnonymous: "익명을 유지하시겠습니까?",
  btnReviewForm: "양식 검토",
  //details page - child component of report home page
  phFirstName: "이름",
  phLastName: "성",
  lblJobTitle: "직책",
  IncidentRole: "해당 사건에서의 역할",
  btnRemove: " 삭제",
  phPhoneNo: "전화번호",
  phEmail: "이메일",
  valEmail: "유효한 이메일 주소를 입력하십시오",

  // Report Review
  ttReportReview: "보고서 검토",
  ttConfirmDetails: "보고서의 세부사항을 확인하신 후 비밀번호를 만드십시오",
  ttIncidentDetails: "사건 세부사항",
  ttIncidentDescription: "사건 설명",
  ttIncidentLocation: "사건 발생 장소",
  pImAnonymous: "익명",
  btnEditReport: "보고서 편집",
  fhtPasswordDesc: "비밀번호는 보고서 추후 확인 시 사용됩니다",
  pPasswordNote: `  중요: 보고서 처리 상태 확인, 또는 추가 정보 입력을 위해 후속 처리 페이지에 로그인하려면, 보고서 번호 및 비밀번호가 필요합니다. 귀하의 개인 정보 보호를 위해, 이 정보는 나중에 검색될 수 없습니다. 저희는 귀하의 이메일 주소로 답신하거나 직접적으로 추후 연락을 취하지 않습니다.`,
  btnCompleteReport: "보고서 작성 완료",

  // follow up confirmation
  ttReportNo: "보고서 후속 처리 확인",
  ttAdditionalInfo: ` 윤리 관련 우려사항에 대한 추가 정보를 제공해 주셔서 감사합니다. 저희 팀에서 귀하의 답변을 검토한 후 보고서 후속 처리 페이지를 통해 추가 정보나 세부사항을 요청할 수 있으므로, 정기적으로 사이트를 확인해 주십시오.
    `,

  // report confirm
  hReport: "보고서",
  hCreated: "작성됨",
  ttThanksforReport: "윤리 관련 우려사항을 제출해 주셔서 감사합니다",
  progressMsg: "우리는 윤리 문제를 만드는 과정에 있습니다. 기다려주십시오..",
  ttFollowUpDet: "후속 처리 세부사항",
  pReportReview: `글로벌 윤리 사무국에서 귀하가 제출하신 내용을 검토한 후, 보고서 후속 처리 페이지를 통해 영업일 기준 3-5일 이내에 최초 답변을 드릴 것입니다.`,
  pAdditionalInforReq: ` 저희 팀에서 보고서 후속 처리 페이지를 통해 추가 정보나 세부사항을 요청할 수 있으므로, 정기적으로 사이트를 확인해 주십시오`,
  pNeedPd: `이 보고서에 대한 후속 처리를 위해서는 보고서 번호 및 비밀번호가 필요합니다. 귀하의 개인 정보 보호를 위해, 이 정보는 나중에 검색될 수 없습니다`,
  pReportNo: "보고서 번호",
  ttReportFlwUp: "보고서 후속 처리",
  ttEnterReportNo:
    "사건 후속 처리를 위한 보고서 번호와 비밀번호를 입력하십시오",
  btnFindReport: "보고서 찾기",
  ttFollowReview: "후속 검토",
  ttConfirmDet: "보고서의 세부사항을 확인하십시오",
  ttAddDetRes: "추가 세부정보/답변",
  btnEdit: "편집",
  btnSubmit: "제출",

  //Follow up reponse
  dReportDet: "보고서 세부사항",
  sIncidentDesc: "사건 설명",
  ttReportHistory: "보고서 이력",
  sRepSubOn: "보고서 제출 날짜",
  btnReview: "검토",
  pCaseClosed:
    "  이 사건 처리는 종료되었습니다. 다른 우려사항이 있으실 경우, 우려사항 보고 페이지를 방문하여 문제를 보고하십시오",
  ComFrmEthics: "윤리팀의 메시지",
  Reporter: "보고자",
  System: "시스템",
  resetPassSuc: "패스워드 리셋 성공. 새 패스워드는 ______ ",
  clickHere: "귀하의 케이스를 ",
  tfollowUp: "후속조치를 하려면",
  tyourCase: "여기를 클릭하십시오. ",
  //Reset Password,
  justNow: "지금 막",
  fewMinutes: "몇 분 전",
  hours: "시간 전",
  month: "개월 전",
  year: "년 전",
  additionalInfo: "추가 정보",
  forgotPd: "비밀번호 분실",
  daysAgo: " __일 전",
  yes: "예",
  start: "시작",
  end: "끝",
  no: "아니요",
  genricError:
    "다른 방법으로 연락해 주십시오. Ethics@Wal-mart.com으로 이메일을 보내실 수 있습니다. ",
  wrongAnswer:
    "귀하의 답변이 데이터와 일치하지 않습니다. 다시 시도해 주십시오.",
  resetError:
    "24시간 내에 두 번째 리셋은 허용되지 않습니다. 추가로 제공할 정보가 있으면 내일 다시 시도하거나 Ethics@Wal-mart.com에 이메일을 보내십시오. 이메일 제목 난에 귀하의 사건 번호를 입력하셔야 합니다.",
  resetLimit:
    "3회 시도하여 실패 시 재설정이 금지됩니다. 15분 후에 다시 시도하거나, 전달하실 추가 정보가 있으면 Ethics@Wal-mart.com으로 이메일을 보내십시오. 이메일 제목에 윤리 사건 번호가 반드시 포함되어야 합니다.",
  resetPasswordHeader: "보고서 번호에 대한 비밀번호 재설정",
  answerTxt:
    "아래의 보안 질문에 답하여 새 비밀번호를 생성하십시오(답변은 공백 및 대문자를 포함하여 정확히 일치해야 합니다)",
  question1: "질문 1",
  question2: "질문 2",
  question3: "질문 3",
  btnVerify: "확인",
  btnReset: "재설정",
  securtiyQuestionHdr:
    "보안 질문 3가지를 선택, 답변하여 보고서 비밀번호를 생성하십시오.",
  generateButton: "비밀번호 생성",
  pPasswordtxt: "비밀번호",
  SecurityQuestions: [
    { id: 1, question: "첫 배우자/파트너를 어느 도시에서 만나셨습니까?" },
    { id: 2, question: "아버지의 고향은 어디입니까?" },
    { id: 3, question: "첫 직장은 어느 도시에서 잡으셨습니까?" },
    { id: 4, question: "어머니의 고향은 어디입니까?" },
    {
      id: 5,
      question: "고등학교 때 가장 좋아했던 선생님의 성은 무엇입니까?"
    },
    {
      id: 6,
      question: "지원했었지만 등록하지 않았던 대학 이름은 무엇입니까?"
    },
    { id: 7, question: "아버지의 중간 이름은 무엇입니까?" },
    { id: 8, question: "어머니의 중간 이름은 무엇입니까?" },
    {
      id: 9,
      question:
        "형제자매 중 맏이가 태어난 연도와 월은 무엇입니까? (예: 1985년 11월)"
    },
    { id: 10, question: "형제자매 중 맏이의 중간 이름은 무엇입니까?" },
    { id: 11, question: "형제자매 중 막내의 중간 이름은 무엇입니까?" },
    { id: 12, question: "6학년 때 다녔던 학교의 이름은 무엇입니까?" },
    {
      id: 13,
      question: "어린 시절 가장 친했던 친구의 이름은 무엇입니까?"
    }
  ],
  roleIncidentOptions: [
    { value: "subject", label: "주제" },
    { value: "witness", label: "목격자" },
    { value: "affected_individual", label: "영향을 받은 자" },
    { value: "other", label: "기타" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "담당자" },
    { value: "management", label: "관리부" },
    { value: "hourly_associate", label: "시간제 직원" },
    { value: "vendor_supplier", label: "판매사/공급업체" },
    { value: "customer", label: "고객" },
    { value: "other", label: "기타" }
  ]
};

export default korean;
n
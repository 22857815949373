import React from "react";

const MaintenancePage = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <h1 style={{ fontSize: "3rem" }}>The Ethics Portal is currently undergoing temporary maintenance.</h1>
      <h2 style={{ fontSize: "1.5rem", margin: 0, marginBottom: 10 }}>
        You can contact Global Ethics and report concerns by
      </h2>

      <h3 style={{ margin: 0 }}>Phone: 1-800-WM-ETHIC (1-800-963-8442)</h3>
      <h3 style={{ margin: 0 }}>Email: Ethics@Walmart.com</h3>
    </div>
  );
};

export default MaintenancePage;

export var Countries = "";

let devCountries={
  records: [
    {
      "name": "Germany",
      "sys_id": "1a81877ddb86c410bcee1f0139961962"
    },
    {
      "name": "Bangladesh",
      "sys_id": "3a584618db166b405a887bbb8c961970"
    },
    {
      "name": "Germany",
      "sys_id": "400aa8bfdb6e0090de3b3a10ad961925"
    },
    {
      "name": "South Korea",
      "sys_id": "440aa8bfdb6e0090de3b3a10ad961926"
    },
    {
      "name": "South Korea",
      "sys_id": "5281877ddb86c410bcee1f0139961964"
    },
    {
      "name": "United Arab Emirates",
      "sys_id": "658512581bba0418dfd6db16ec4bcb15"
    },
    {
      "name": "Kenya",
      "sys_id": "83588a5cdb166b405a887bbb8c9619d1"
    },
    {
      "name": "Mexico",
      "sys_id": "83588a5cdb166b405a887bbb8c9619dc"
    },
    {
      "name": "Nigeria",
      "sys_id": "83588a5cdb166b405a887bbb8c9619e7"
    },
    {
      "name": "Singapore",
      "sys_id": "83588a5cdb166b405a887bbb8c9619f2"
    },
    {
      "name": "Uganda",
      "sys_id": "83588a5cdb166b405a887bbb8c9619fd"
    },
    {
      "name": "Japan",
      "sys_id": "87588a5cdb166b405a887bbb8c9619ce"
    },
    {
      "name": "Mauritius",
      "sys_id": "87588a5cdb166b405a887bbb8c9619d9"
    },
    {
      "name": "Nicaragua",
      "sys_id": "87588a5cdb166b405a887bbb8c9619e4"
    },
    {
      "name": "Puerto Rico",
      "sys_id": "87588a5cdb166b405a887bbb8c9619ef"
    },
    {
      "name": "Tanzania",
      "sys_id": "87588a5cdb166b405a887bbb8c9619fa"
    },
    {
      "name": "Malawi",
      "sys_id": "8b588a5cdb166b405a887bbb8c9619d6"
    },
    {
      "name": "Namibia",
      "sys_id": "8b588a5cdb166b405a887bbb8c9619e1"
    },
    {
      "name": "Peru",
      "sys_id": "8b588a5cdb166b405a887bbb8c9619ec"
    },
    {
      "name": "Swaziland",
      "sys_id": "8b588a5cdb166b405a887bbb8c9619f7"
    },
    {
      "name": "Lesotho",
      "sys_id": "8f588a5cdb166b405a887bbb8c9619d3"
    },
    {
      "name": "Mozambique",
      "sys_id": "8f588a5cdb166b405a887bbb8c9619de"
    },
    {
      "name": "Pakistan",
      "sys_id": "8f588a5cdb166b405a887bbb8c9619e9"
    },
    {
      "name": "South Africa",
      "sys_id": "8f588a5cdb166b405a887bbb8c9619f4"
    },
    {
      "name": "Zambia",
      "sys_id": "9358ca5cdb166b405a887bbb8c961908"
    },
    {
      "name": "Vietnam",
      "sys_id": "9758ca5cdb166b405a887bbb8c961905"
    },
    {
      "name": "United States of America",
      "sys_id": "9b58ca5cdb166b405a887bbb8c961902"
    },
    {
      "name": "United Kingdom",
      "sys_id": "9f588a5cdb166b405a887bbb8c9619ff"
    },
    {
      "name": "Argentina",
      "sys_id": "be584618db166b405a887bbb8c96196c"
    },
    {
      "name": "Botswana",
      "sys_id": "c3588a5cdb166b405a887bbb8c96199e"
    },
    {
      "name": "Chile",
      "sys_id": "c3588a5cdb166b405a887bbb8c9619a9"
    },
    {
      "name": "Ghana",
      "sys_id": "c3588a5cdb166b405a887bbb8c9619b4"
    },
    {
      "name": "Indonesia",
      "sys_id": "c3588a5cdb166b405a887bbb8c9619bf"
    },
    {
      "name": "United Arab Emirates",
      "sys_id": "c40aa8bfdb6e0090de3b3a10ad961927"
    },
    {
      "name": "Canada",
      "sys_id": "c7588a5cdb166b405a887bbb8c9619a6"
    },
    {
      "name": "El Salvador",
      "sys_id": "c7588a5cdb166b405a887bbb8c9619b1"
    },
    {
      "name": "India",
      "sys_id": "c7588a5cdb166b405a887bbb8c9619bc"
    },
    {
      "name": "British Virgin Islands",
      "sys_id": "cb588a5cdb166b405a887bbb8c9619a3"
    },
    {
      "name": "Honduras",
      "sys_id": "cb588a5cdb166b405a887bbb8c9619b9"
    },
    {
      "name": "Brazil",
      "sys_id": "cf588a5cdb166b405a887bbb8c9619a0"
    },
    {
      "name": "China",
      "sys_id": "cf588a5cdb166b405a887bbb8c9619ab"
    },
    {
      "name": "Guatemala",
      "sys_id": "cf588a5cdb166b405a887bbb8c9619b6"
    },
    {
      "name": "United Arab Emirates",
      "sys_id": "d281877ddb86c410bcee1f0139961965"
    },
    {
      "name": "South Korea",
      "sys_id": "e18512581bba0418dfd6db16ec4bcb14"
    },
    {
      "name": "Germany",
      "sys_id": "ed8512581bba0418dfd6db16ec4bcb12"
    }
  ]
};
let certCountries = {
  records: [
    {
      "name": "Bangladesh",
      "sys_id": "3a584618db166b405a887bbb8c961970"
    },
    {
      "name": "United Arab Emirates",
      "sys_id": "658512581bba0418dfd6db16ec4bcb15"
    },
    {
      "name": "Kenya",
      "sys_id": "83588a5cdb166b405a887bbb8c9619d1"
    },
    {
      "name": "Mexico",
      "sys_id": "83588a5cdb166b405a887bbb8c9619dc"
    },
    {
      "name": "Nigeria",
      "sys_id": "83588a5cdb166b405a887bbb8c9619e7"
    },
    {
      "name": "Singapore",
      "sys_id": "83588a5cdb166b405a887bbb8c9619f2"
    },
    {
      "name": "Uganda",
      "sys_id": "83588a5cdb166b405a887bbb8c9619fd"
    },
    {
      "name": "Japan",
      "sys_id": "87588a5cdb166b405a887bbb8c9619ce"
    },
    {
      "name": "Mauritius",
      "sys_id": "87588a5cdb166b405a887bbb8c9619d9"
    },
    {
      "name": "Nicaragua",
      "sys_id": "87588a5cdb166b405a887bbb8c9619e4"
    },
    {
      "name": "Puerto Rico",
      "sys_id": "87588a5cdb166b405a887bbb8c9619ef"
    },
    {
      "name": "Tanzania",
      "sys_id": "87588a5cdb166b405a887bbb8c9619fa"
    },
    {
      "name": "Malawi",
      "sys_id": "8b588a5cdb166b405a887bbb8c9619d6"
    },
    {
      "name": "Namibia",
      "sys_id": "8b588a5cdb166b405a887bbb8c9619e1"
    },
    {
      "name": "Peru",
      "sys_id": "8b588a5cdb166b405a887bbb8c9619ec"
    },
    {
      "name": "Swaziland",
      "sys_id": "8b588a5cdb166b405a887bbb8c9619f7"
    },
    {
      "name": "Lesotho",
      "sys_id": "8f588a5cdb166b405a887bbb8c9619d3"
    },
    {
      "name": "Mozambique",
      "sys_id": "8f588a5cdb166b405a887bbb8c9619de"
    },
    {
      "name": "Pakistan",
      "sys_id": "8f588a5cdb166b405a887bbb8c9619e9"
    },
    {
      "name": "South Africa",
      "sys_id": "8f588a5cdb166b405a887bbb8c9619f4"
    },
    {
      "name": "Zambia",
      "sys_id": "9358ca5cdb166b405a887bbb8c961908"
    },
    {
      "name": "Vietnam",
      "sys_id": "9758ca5cdb166b405a887bbb8c961905"
    },
    {
      "name": "United States of America",
      "sys_id": "9b58ca5cdb166b405a887bbb8c961902"
    },
    {
      "name": "United Kingdom",
      "sys_id": "9f588a5cdb166b405a887bbb8c9619ff"
    },
    {
      "name": "Argentina",
      "sys_id": "be584618db166b405a887bbb8c96196c"
    },
    {
      "name": "Botswana",
      "sys_id": "c3588a5cdb166b405a887bbb8c96199e"
    },
    {
      "name": "Chile",
      "sys_id": "c3588a5cdb166b405a887bbb8c9619a9"
    },
    {
      "name": "Ghana",
      "sys_id": "c3588a5cdb166b405a887bbb8c9619b4"
    },
    {
      "name": "Indonesia",
      "sys_id": "c3588a5cdb166b405a887bbb8c9619bf"
    },
    {
      "name": "Canada",
      "sys_id": "c7588a5cdb166b405a887bbb8c9619a6"
    },
    {
      "name": "El Salvador",
      "sys_id": "c7588a5cdb166b405a887bbb8c9619b1"
    },
    {
      "name": "India",
      "sys_id": "c7588a5cdb166b405a887bbb8c9619bc"
    },
    {
      "name": "British Virgin Islands",
      "sys_id": "cb588a5cdb166b405a887bbb8c9619a3"
    },
    {
      "name": "Costa Rica",
      "sys_id": "cb588a5cdb166b405a887bbb8c9619ae"
    },
    {
      "name": "Honduras",
      "sys_id": "cb588a5cdb166b405a887bbb8c9619b9"
    },
    {
      "name": "Brazil",
      "sys_id": "cf588a5cdb166b405a887bbb8c9619a0"
    },
    {
      "name": "China",
      "sys_id": "cf588a5cdb166b405a887bbb8c9619ab"
    },
    {
      "name": "Guatemala",
      "sys_id": "cf588a5cdb166b405a887bbb8c9619b6"
    },
    {
      "name": "South Korea",
      "sys_id": "e18512581bba0418dfd6db16ec4bcb14"
    },
    {
      "name": "Germany",
      "sys_id": "ed8512581bba0418dfd6db16ec4bcb12"
    }
  ]
};
let prodCountries = {
  records: [
    {
      "name": "Bangladesh",
      "sys_id": "3a584618db166b405a887bbb8c961970"
    },
    {
      "name": "United Arab Emirates",
      "sys_id": "658512581bba0418dfd6db16ec4bcb15"
    },
    {
      "name": "Kenya",
      "sys_id": "83588a5cdb166b405a887bbb8c9619d1"
    },
    {
      "name": "Mexico",
      "sys_id": "83588a5cdb166b405a887bbb8c9619dc"
    },
    {
      "name": "Nigeria",
      "sys_id": "83588a5cdb166b405a887bbb8c9619e7"
    },
    {
      "name": "Singapore",
      "sys_id": "83588a5cdb166b405a887bbb8c9619f2"
    },
    {
      "name": "Uganda",
      "sys_id": "83588a5cdb166b405a887bbb8c9619fd"
    },
    {
      "name": "Japan",
      "sys_id": "87588a5cdb166b405a887bbb8c9619ce"
    },
    {
      "name": "Mauritius",
      "sys_id": "87588a5cdb166b405a887bbb8c9619d9"
    },
    {
      "name": "Nicaragua",
      "sys_id": "87588a5cdb166b405a887bbb8c9619e4"
    },
    {
      "name": "Puerto Rico",
      "sys_id": "87588a5cdb166b405a887bbb8c9619ef"
    },
    {
      "name": "Tanzania",
      "sys_id": "87588a5cdb166b405a887bbb8c9619fa"
    },
    {
      "name": "Malawi",
      "sys_id": "8b588a5cdb166b405a887bbb8c9619d6"
    },
    {
      "name": "Namibia",
      "sys_id": "8b588a5cdb166b405a887bbb8c9619e1"
    },
    {
      "name": "Peru",
      "sys_id": "8b588a5cdb166b405a887bbb8c9619ec"
    },
    {
      "name": "Swaziland",
      "sys_id": "8b588a5cdb166b405a887bbb8c9619f7"
    },
    {
      "name": "Lesotho",
      "sys_id": "8f588a5cdb166b405a887bbb8c9619d3"
    },
    {
      "name": "Mozambique",
      "sys_id": "8f588a5cdb166b405a887bbb8c9619de"
    },
    {
      "name": "Pakistan",
      "sys_id": "8f588a5cdb166b405a887bbb8c9619e9"
    },
    {
      "name": "South Africa",
      "sys_id": "8f588a5cdb166b405a887bbb8c9619f4"
    },
    {
      "name": "Zambia",
      "sys_id": "9358ca5cdb166b405a887bbb8c961908"
    },
    {
      "name": "Vietnam",
      "sys_id": "9758ca5cdb166b405a887bbb8c961905"
    },
    {
      "name": "United States of America",
      "sys_id": "9b58ca5cdb166b405a887bbb8c961902"
    },
    {
      "name": "United Kingdom",
      "sys_id": "9f588a5cdb166b405a887bbb8c9619ff"
    },
    {
      "name": "Argentina",
      "sys_id": "be584618db166b405a887bbb8c96196c"
    },
    {
      "name": "Botswana",
      "sys_id": "c3588a5cdb166b405a887bbb8c96199e"
    },
    {
      "name": "Chile",
      "sys_id": "c3588a5cdb166b405a887bbb8c9619a9"
    },
    {
      "name": "Ghana",
      "sys_id": "c3588a5cdb166b405a887bbb8c9619b4"
    },
    {
      "name": "Indonesia",
      "sys_id": "c3588a5cdb166b405a887bbb8c9619bf"
    },
    {
      "name": "Canada",
      "sys_id": "c7588a5cdb166b405a887bbb8c9619a6"
    },
    {
      "name": "El Salvador",
      "sys_id": "c7588a5cdb166b405a887bbb8c9619b1"
    },
    {
      "name": "India",
      "sys_id": "c7588a5cdb166b405a887bbb8c9619bc"
    },
    {
      "name": "British Virgin Islands",
      "sys_id": "cb588a5cdb166b405a887bbb8c9619a3"
    },
    {
      "name": "Costa Rica",
      "sys_id": "cb588a5cdb166b405a887bbb8c9619ae"
    },
    {
      "name": "Honduras",
      "sys_id": "cb588a5cdb166b405a887bbb8c9619b9"
    },
    {
      "name": "Brazil",
      "sys_id": "cf588a5cdb166b405a887bbb8c9619a0"
    },
    {
      "name": "China",
      "sys_id": "cf588a5cdb166b405a887bbb8c9619ab"
    },
    {
      "name": "Guatemala",
      "sys_id": "cf588a5cdb166b405a887bbb8c9619b6"
    },
    {
      "name": "South Korea",
      "sys_id": "e18512581bba0418dfd6db16ec4bcb14"
    },
    {
      "name": "Germany",
      "sys_id": "ed8512581bba0418dfd6db16ec4bcb12"
    }
  ]
};
const countryEnv = process.env.REACT_APP_APIENV || "production";
if(countryEnv !== "" && countryEnv === "development") {
    Countries = devCountries;
} else if (countryEnv !== "" && countryEnv === "cert") {
    Countries = certCountries;
} else if (countryEnv !== "" && countryEnv === "production") {
    Countries = prodCountries;
}
export default Countries;

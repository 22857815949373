//Data for punjabi language Start
const punjabi = {
  msgSendFailed: "ਸੁਨੇਹਾ ਭੇਜਣ ਵਿੱਚ ਅਸਫਲ",
  ttPeopleInvolved: "ਸ਼ਾਮਲ ਲੋਕ",
  msgSent: "ਤੁਹਾਡਾ ਸੁਨੇਹਾ ਸਫਲਤਾਪੂਰਵਕ ਭੇਜਿਆ ਗਿਆ ਹੈ",
  invalidCredentials: "ਅਵੈਧ ਕੇਸ ਨੰਬਰ/ਪਾਸਵਰਡ",
  enterRepNo: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਰਿਪੋਰਟ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
  followUpTxt:
    "ਕਿਰਪਾ ਕਰਕੇ ਕੇਸ ਨੂੰ ਫਾਲੋ-ਅੱਪ ਕਰਨ ਲਈ ਰਿਪੋਰਟ ਨੰਬਰ ਅਤੇ ਪਾਸਵਰਡ ਦਾਖਲ ਕਰੋ", // report follow up,
  //report home page
  emergencyNote: `ਇਸ ਵੈੱਬਸਾਈਟ ਦੀ ਵਰਤੋਂ ਅਜਿਹੇ ਸੰਕਟਕਾਲੀ ਮਾਮਲਿਆਂ ਲਈ ਨਹੀਂ ਕੀਤੀ ਜਾਣੀ ਚਾਹੀਦੀ ਜਿਨ੍ਹਾਂ ਲਈ ਤੁਰੰਤ ਮੌਖਿਕ ਸੂਚਨਾ ਦੀ ਲੋੜ ਹੁੰਦੀ ਹੈ। ਸੰਕਟਕਾਲ ਦੀ ਹਾਲਤ ਵਿੱਚ ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀਆਂ ਸਥਾਨਕ ਐਮਰਜੈਂਸੀ ਸੇਵਾਵਾਂ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।`,
  understand: "ਮੈਂ ਸਮਝਦਾ/ਸਮਝਦੀ ਹਾਂ",
  ttWhatHappened: "ਕੀ ਹੋਇਆ?",
  lblIncidentDetails: "ਘਟਨਾ ਦੇ ਵੇਰਵੇ",
  ttWhereHappened: "ਇਹ ਕਿੱਥੇ ਹੋਇਆ?",
  pSelectLocation: "ਮੀਨੂ ਵਿਕਲਪਾਂ ਤੋਂ ਸਥਾਨ ਚੁਣੋ",
  lblCountry: "ਦੇਸ਼",
  lblState: "ਰਾਜ",
  lblCity: "ਸ਼ਹਿਰ",
  lblLocation: "ਸਥਾਨ",
  phMissingPlace: "ਜੇ ਸਥਾਨ ਉੱਪਰ ਨਹੀਂ ਲੱਭਿਆ, ਤਾਂ ਇੱਥੇ ਦਾਖਲ ਕਰੋ",
  requestInfo:
    "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਚਿੰਤਾ ਦੇ ਸੰਬੰਧ ਵਿੱਚ ਹੇਠ ਦਿੱਤੀ ਜਾਣਕਾਰੀ ਮੁਹੱਈਆ ਕਰੋ",
  liDateofSituation: "ਸਥਿਤੀ ਦੀ ਮਿਤੀ/ਸਮਾਂ",
  liAssociatesInvovled:
    "ਸ਼ਾਮਲ ਸਹਿਯੋਗੀ ਦੇ ਨਾਮ, ਉਹਨਾਂ ਸਮੇਤ ਜਿਨ੍ਹਾਂ ਨੂੰ ਉਸ ਸਥਿਤੀ ਬਾਰੇ ਜਾਣਕਾਰੀ ਹੋ ਸਕਦੀ ਹੈ ਜਾਂ ਉਸਦੇ ਗਵਾਹ ਹੋ ਸਕਦੇ ਹਨ ਜਿਸਦਾ ਤੁਸੀਂ ਵਰਣਨ ਕਰ ਰਹੇ ਹੋ",
  liSituationOccured: "ਉਹ ਸਥਾਨ ਜਿੱਥੇ ਸਥਿਤੀ ਵਾਪਰੀ",
  liReportedAnyone:
    "ਕੀ ਤੁਸੀਂ ਆਪਣੀ ਚਿੰਤਾ ਬਾਰੇ ਕੰਪਨੀ ਵਿੱਚ ਕਿਸੇ ਨੂੰ ਦੱਸਿਆ ਹੈ? ਜੇ ਹਾਂ, ਤਾਂ ਕਿਸ ਨੂੰ ਅਤੇ ਕਦੋਂ?",
  spIfNoLocation: "ਸਿਰਫ ਤਾਂ ਹੀ ਲੋੜੀਂਦਾ ਹੈ ਜੇ ਉੱਪਰ ਸਥਾਨ ਨਹੀਂ ਮਿਲਿਆ ਸੀ",
  ttWhoInvolved: "ਕੌਣ ਸ਼ਾਮਲ ਸਨ?",
  pOtherPerson: "ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਘਟਨਾ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਰ ਲੋਕਾਂ ਦੇ ਨਾਮ ਦੱਸੋ",
  btnAddPerson: "ਹੋਰ ਸ਼ਾਮਲ ਕਰੋ",
  ttContactInfo: "ਸੰਪਰਕ ਕਰਨ ਦੀ ਜਾਣਕਾਰੀ",
  pAnonymous: "ਕੀ ਤੁਸੀਂ ਅਗਿਆਤ ਰਹਿਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  btnReviewForm: "ਸਮੀਖਿਆ ਫਾਰਮ",
  //details page - child component of report home page
  phFirstName: "ਨਾਮ ਦਾ ਪਹਿਲਾ ਹਿੱਸਾ",
  phLastName: "ਨਾਮ ਦਾ ਆਖਰੀ ਹਿੱਸਾ",
  lblJobTitle: "ਨੌਕਰੀ ਦਾ ਅਹੁਦਾ",
  IncidentRole: "ਘਟਨਾ ਵਿੱਚ ਭੂਮਿਕਾ",
  btnRemove: " ਹਟਾਓ",
  phPhoneNo: "ਫੋਨ ਨੰਬਰ",
  phEmail: "ਈਮੇਲ",
  valEmail: "ਕਿਰਪਾ ਕਰਕੇ ਵੈਧ ਈਮੇਲ ਦਾਖਲ ਕਰੋ",

  // Report Review
  ttReportReview: "ਰਿਪੋਰਟ ਦੀ ਸਮੀਖਿਆ",
  ttConfirmDetails:
    "ਕਿਰਪਾ ਕਰਕੇ ਰਿਪੋਰਟ ਦੇ ਵੇਰਵਿਆਂ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ ਅਤੇ ਇੱਕ ਪਾਸਵਰਡ ਬਣਾਓ",
  ttIncidentDetails: "ਘਟਨਾ ਦੇ ਵੇਰਵੇ",
  ttIncidentDescription: "ਘਟਨਾ ਦੀ ਵਿਵਰਣ",
  ttIncidentLocation: "ਘਟਨਾ ਦਾ ਸਥਾਨ",
  pImAnonymous: "ਗੁਮਨਾਮ",
  btnEditReport: "ਰਿਪੋਰਟ ਸੰਪਾਦਿਤ ਕਰੋ",
  fhtPasswordDesc: "ਤੁਹਾਡੀ ਰਿਪੋਰਟ ਦਾ ਫਾਲੋ-ਅੱਪ ਕਰਨ ਲਈ ਪਾਸਵਰਡ ਵਰਤਿਆ ਜਾਏਗਾ",
  pPasswordNote: `  ਮਹੱਤਵਪੂਰਨ: ਆਪਣੀ ਰਿਪੋਰਟ ਦੀ ਸਥਿਤੀ ਨੂੰ ਵੇਖਣ ਲਈ, ਜਾਂ ਕੋਈ ਹੋਰ ਜਾਣਕਾਰੀ ਪ੍ਰਦਾਨ ਕਰਨ ਲਈ, ਫਾਲੋ-ਅੱਪ ਪੰਨੇ ਤੇ ਲੌਗ-ਇਨ ਕਰਨ ਵਾਸਤੇ ਤੁਹਾਨੂੰ ਆਪਣਾ ਰਿਪੋਰਟ ਨੰਬਰ ਅਤੇ ਪਾਸਵਰਡ ਦੀ ਲੋੜ ਹੈ। ਤੁਹਾਡੀ ਗੋਪਨੀਯਤਾ ਦੀ ਰੱਖਿਆ ਕਰਨ ਲਈ, ਇਹ ਜਾਣਕਾਰੀ ਬਾਅਦ ਵਿੱਚ ਪ੍ਰਾਪਤ ਨਹੀਂ ਕੀਤੀ ਜਾ ਸਕਦੀ ਹੈ। ਅਸੀਂ ਤੁਹਾਡੀ ਈਮੇਲ ਪਤੇ 'ਤੇ ਸਿੱਧਾ ਜਵਾਬ ਨਹੀਂ ਦਿੰਦੇ ਜਾਂ ਫਾਲੋ-ਅੱਪ ਨਹੀਂ ਕਰਦੇ ਹਾਂ।`,
  btnCompleteReport: "ਪੂਰੀ ਰਿਪੋਰਟ",

  // follow up confirmation
  ttReportNo: "ਰਿਪੋਰਟ ਲਈ ਫਾਲੋ-ਅੱਪ ਪੁਸ਼ਟੀਕਰਣ",
  ttAdditionalInfo: ` ਆਪਣੀ ਨੈਤਿਕਤਾ ਸੰਬੰਧੀ ਚਿੰਤਾ ਬਾਰੇ ਵਧੇਰੇ ਜਾਣਕਾਰੀ ਪ੍ਰਦਾਨ ਕਰਨ ਲਈ ਧੰਨਵਾਦ। ਸਾਡੀ ਟੀਮ ਤੁਹਾਡੇ ਜਵਾਬ ਦੀ ਸਮੀਖਿਆ ਕਰੇਗੀ ਅਤੇ ਵਾਧੂ ਜਾਣਕਾਰੀ ਜਾਂ ਵੇਰਵਿਆਂ ਦੀ ਬੇਨਤੀ ਕਰਨ ਲਈ ਫਾਲੋ-ਅੱਪ ਰਿਪੋਰਟ ਪੰਨੇ ਦੀ ਵਰਤੋਂ ਕਰ ਸਕਦੀ ਹੈ, ਇਸ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਨਿਯਮਿਤ ਤੌਰ 'ਤੇ ਸਾਈਟ ਨੂੰ ਵੇਖਦੇ ਰਹੋ।
    `,

  // report confirm
  hReport: "ਰਿਪੋਰਟ",
  hCreated: "ਬਣਾਇਆ",
  ttThanksforReport: "ਆਪਣੀ ਨੈਤਿਕਤਾ ਸੰਬੰਧੀ ਚਿੰਤਾ ਜਮ੍ਹਾਂ ਕਰਨ ਲਈ ਤੁਹਾਡਾ ਧੰਨਵਾਦ",
  progressMsg:
    "ਅਸੀਂ ਤੁਹਾਡੀ ਨੈਤਿਕਤਾ ਸੰਬੰਧੀ ਚਿੰਤਾ ਬਣਾਉਣ ਦੀ ਪ੍ਰਕਿਰਿਆ ਵਿੱਚ ਹਾਂ। ਕਿਰਪਾ ਕਰਕੇ ਸਬਰ ਰੱਖੋ..",
  ttFollowUpDet: "ਫਾਲੋ-ਅੱਪ ਵੇਰਵੇ",
  pReportReview: `ਸਾਡਾ ਗਲੋਬਲ ਨੈਤਿਕਤਾ ਦਫਤਰ ਤੁਹਾਡੀ ਜਮ੍ਹਾਂ ਕੀਤੀ ਬੇਨਤੀ ਦੀ ਸਮੀਖਿਆ ਕਰੇਗਾ ਅਤੇ 3-5 ਕਾਰੋਬਾਰੀ ਦਿਨਾਂ ਦੇ ਅੰਦਰ ਸਾਡੇ ਫਾਲੋ-ਅੱਪ ਰਿਪੋਰਟ ਪੰਨੇ ਦੀ ਵਰਤੋਂ ਕਰਦਿਆਂ ਸ਼ੁਰੂਆਤੀ ਜਵਾਬ ਦੇਵੇਗਾ।`,
  pAdditionalInforReq: ` ਸਾਡੀ ਟੀਮ ਵਾਧੂ ਜਾਣਕਾਰੀ ਜਾਂ ਵੇਰਵਿਆਂ ਦੀ ਬੇਨਤੀ ਕਰਨ ਲਈ ਫਾਲੋ-ਅੱਪ ਰਿਪੋਰਟ ਪੰਨੇ ਦੀ ਵਰਤੋਂ ਕਰ ਸਕਦੀ ਹੈ, ਇਸ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਨਿਯਮਿਤ ਤੌਰ 'ਤੇ ਸਾਈਟ ਨੂੰ ਵੇਖਦੇ ਰਹੋ`,
  pNeedPd: `ਇਸ ਰਿਪੋਰਟ ਦਾ ਫਾਲੋ-ਅੱਪ ਕਰਨ ਲਈ ਤੁਹਾਨੂੰ ਪਾਸਵਰਡ ਅਤੇ ਰਿਪੋਰਟ ਨੰਬਰ ਦੀ ਜ਼ਰੂਰਤ ਹੋਵੇਗੀ। ਤੁਹਾਡੀ ਗੋਪਨੀਯਤਾ ਦੀ ਰੱਖਿਆ ਕਰਨ ਲਈ, ਇਹ ਜਾਣਕਾਰੀ ਬਾਅਦ ਵਿੱਚ ਪ੍ਰਾਪਤ ਨਹੀਂ ਕੀਤੀ ਜਾ ਸਕਦੀ ਹੈ`,
  pReportNo: "ਰਿਪੋਰਟ ਨੰਬਰ",
  ttReportFlwUp: "ਰਿਪੋਰਟ ਦਾ ਫਾਲੋ-ਅੱਪ",
  ttEnterReportNo:
    "ਕਿਰਪਾ ਕਰਕੇ ਕੇਸ ਨੂੰ ਫਾਲੋ-ਅੱਪ ਕਰਨ ਲਈ ਰਿਪੋਰਟ ਨੰਬਰ ਅਤੇ ਪਾਸਵਰਡ ਦਾਖਲ ਕਰੋ",
  btnFindReport: "ਰਿਪੋਰਟ ਲੱਭੋ",
  ttFollowReview: "ਫਾਲੋ-ਅੱਪ ਸਮੀਖਿਆ",
  ttConfirmDet: "ਕਿਰਪਾ ਕਰਕੇ ਰਿਪੋਰਟ ਦੇ ਵੇਰਵਿਆਂ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
  ttAddDetRes: "ਵਾਧੂ ਵੇਰਵੇ/ਜਵਾਬ",
  btnEdit: "ਸੰਪਾਦਿਤ ਕਰੋ",
  btnSubmit: "ਪ੍ਰਸਤੁਤ ਕਰੋ",

  //Follow up reponse
  dReportDet: "ਰਿਪੋਰਟ ਦੇ ਵੇਰਵੇ",
  sIncidentDesc: "ਘਟਨਾ ਦਾ ਵਰਣਨ",
  ttReportHistory: "ਰਿਪੋਰਟ ਇਤਿਹਾਸ",
  sRepSubOn: "ਰਿਪੋਰਟ ਜਮ੍ਹਾਂ ਕਰਨ ਦੀ ਤਾਰੀਖ",
  btnReview: "ਸਮੀਖਿਆ",
  pCaseClosed:
    "  ਇਹ ਕੇਸ ਬੰਦ ਕਰ ਦਿੱਤਾ ਗਿਆ ਹੈ। ਜੇ ਤੁਹਾਨੂੰ ਕੋਈ ਹੋਰ ਚਿੰਤਾਵਾਂ ਹਨ, ਤਾਂ ਆਪਣੀ ਚਿੰਤਾ ਦੀ ਰਿਪੋਰਟ ਕਰਨ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਚਿੰਤਾ ਦੀ ਰਿਪੋਰਟ ਕਰੋ ਪੰਨੇ 'ਤੇ ਜਾਓ",
  ComFrmEthics: "ਨੈਤਿਕਤਾ ਵਿਭਾਗ ਤੋਂ ਸੰਚਾਰ",
  Reporter: "ਰਿਪੋਰਟ ਕਰਤਾ",
  System: "ਸਿਸਟਮ",
  resetPassSuc: "ਪਾਸਵਰਡ ਰੀਸੈੱਟ ਕਰਨਾ ਸਫਲ ਰਿਹਾ। ਤੁਹਾਡਾ ਨਵਾਂ ਪਾਸਵਰਡ ਹੈ ",
  clickHere: "ਇਹ ਕਰਨ ਲਈ ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ ",
  tfollowUp: "ਅਨੁਸਰਣ ਕਰੋ",
  tyourCase: "ਤੁਹਾਡਾ ਮਾਮਲਾ. ",
  //Reset Password,
  justNow: "ਹੁਣੇ-ਹੁਣੇ",
  fewMinutes: "ਕੁਝ ਮਿੰਟ ਪਹਿਲਾਂ",
  hours: "ਘੰਟਾ ਪਹਿਲਾਂ",
  month: "ਮਹੀਨਾ ਪਹਿਲਾਂ",
  year: "ਸਾਲ ਪਹਿਲਾਂ",
  additionalInfo: "ਵਾਧੂ ਜਾਣਕਾਰੀ",
  forgotPd: "ਪਾਸਵਰਡ ਭੁੱਲ ਗਏ",
  daysAgo: " ਦਿਨ ਪਹਿਲਾਂ",
  yes: "ਹਾਂ",
  start: "ਸ਼ੁਰੂ ਕਰੋ",
  end: "ਸਮਾਪਤ",
  no: "ਨਹੀਂ",
  genricError:
    "ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰਨ ਲਈ ਕਿਸੇ ਵਿਕਲਪਿਕ ਢੰਗ ਦੀ ਵਰਤੋਂ ਕਰੋ। ਤੁਸੀਂ Ethics@Wal-mart.com 'ਤੇ ਈਮੇਲ ਭੇਜ ਸਕਦੇ ਹੋ। ",
  wrongAnswer:
    "ਤੁਹਾਡੇ ਜਵਾਬ ਸਾਡੇ ਡੇਟਾ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦੇ। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
  resetError:
    "24-ਘੰਟੇ ਦੀ ਮਿਆਦ ਦੇ ਅੰਦਰ ਦੂਜੇ ਰੀਸੈੱਟ 'ਤੇ ਪਾਬੰਦੀ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਕੱਲ੍ਹ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰਨਾ ਜਾਂ ਜੇ ਤੁਹਾਡੇ ਕੋਲ ਦੇਣ ਲਈ ਵਧੇਰੇ ਜਾਣਕਾਰੀ ਹੈ ਤਾਂ Ethics@Wal-mart.com 'ਤੇ ਈਮੇਲ ਭੇਜੋ। ਤੁਹਾਨੂੰ ਈਮੇਲ ਵਿਸ਼ਾ ਲਾਈਨ ਵਿੱਚ ਆਪਣੇ ਨੈਤਿਕਤਾ ਮਾਮਲੇ ਦਾ ਨੰਬਰ ਲਾਜ਼ਮੀ ਸ਼ਾਮਲ ਕਰਨਾ ਚਾਹੀਦਾ ਹੈ।",
  resetLimit:
    "ਤਿੰਨ ਅਸਫਲ ਕੋਸ਼ਿਸ਼ਾਂ ਤੋਂ ਬਾਅਦ ਰੀਸੈੱਟ ਕਰਨਾ ਵਰਜਿਤ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ 15 ਮਿੰਟਾਂ ਬਾਅਦ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ ਜਾਂ ਜੇ ਤੁਹਾਡੇ ਕੋਲ ਪ੍ਰਦਾਨ ਕਰਨ ਲਈ ਵਧੇਰੇ ਜਾਣਕਾਰੀ ਹੈ ਤਾਂ Ethics@Wal-mart.com 'ਤੇ ਇੱਕ ਈਮੇਲ ਭੇਜੋ। ਤੁਹਾਨੂੰ ਈਮੇਲ ਦੀ ਵਿਸ਼ਾ ਲਾਈਨ ਵਿੱਚ ਆਪਣਾ ਨੈਤਿਕਤਾ ਕੇਸ ਨੰਬਰ ਸ਼ਾਮਲ ਕਰਨਾ ਚਾਹੀਦਾ ਹੈ।",
  resetPasswordHeader: "ਰਿਪੋਰਟ ਨੰਬਰ ਲਈ ਪਾਸਵਰਡ ਰੀਸੈੱਟ ਕਰੋ",
  answerTxt:
    "ਨਵਾਂ ਪਾਸਵਰਡ ਬਣਾਉਣ ਲਈ ਹੇਠਾਂ ਸੁਰੱਖਿਆ ਪ੍ਰਸ਼ਨਾਂ ਦੇ ਉੱਤਰ ਦਿਓ (ਖਾਲੀ ਥਾਂ ਅਤੇ ਅੰਗਰੇਜ਼ੀ ਦੇ ਛੋਟੇ-ਵੱਡੇ ਅੱਖਰਾਂ ਸਮੇਤ, ਉੱਤਰਾਂ ਨੂੰ ਬਿਲਕੁਲ ਸਹੀ-ਸਹੀ ਮੇਲ ਖਾਣਾ ਚਾਹੀਦਾ ਹੈ)",
  question1: "ਪ੍ਰਸ਼ਨ 1",
  question2: "ਪ੍ਰਸ਼ਨ 2",
  question3: "ਪ੍ਰਸ਼ਨ 3",
  btnVerify: "ਤਸਦੀਕ ਕਰੋ",
  btnReset: "ਮੁੜ ਸੈੱਟ ਕਰੋ",
  securtiyQuestionHdr:
    "ਆਪਣੀ ਰਿਪੋਰਟ ਲਈ ਪਾਸਵਰਡ ਬਣਾਉਣ ਲਈ ਤਿੰਨ ਸੁਰੱਖਿਆ ਪ੍ਰਸ਼ਨ ਚੁਣੋ ਅਤੇ ਉਹਨਾਂ ਦੇ ਜਵਾਬ ਦਿਓ:",
  generateButton: "ਪਾਸਵਰਡ ਬਣਾਓ",
  pPasswordtxt: "ਪਾਸਵਰਡ",
  SecurityQuestions: [
    {
      id: 1,
      question: "ਤੁਸੀਂ ਆਪਣੇ ਪਹਿਲੇ ਪਤੀ/ਪਤਨੀ/ਸਾਥੀ ਨੂੰ ਕਿਸ ਸ਼ਹਿਰ ਵਿੱਚ ਮਿਲੇ ਸੀ?"
    },
    { id: 2, question: "ਤੁਹਾਡੇ ਪਿਤਾ ਦਾ ਜਨਮ ਕਿਸ ਸ਼ਹਿਰ ਵਿੱਚ ਹੋਇਆ ਸੀ?" },
    { id: 3, question: "ਤੁਹਾਡੀ ਪਹਿਲੀ ਨੌਕਰੀ ਕਿਸ ਸ਼ਹਿਰ ਵਿੱਚ ਸੀ?" },
    { id: 4, question: "ਤੁਹਾਡੀ ਮਾਤਾ ਦਾ ਜਨਮ ਕਿਸ ਸ਼ਹਿਰ ਵਿੱਚ ਹੋਇਆ ਸੀ?" },
    {
      id: 5,
      question: "ਹਾਈ ਸਕੂਲ ਵਿੱਚ ਤੁਹਾਡੇ ਮਨਪਸੰਦ ਅਧਿਆਪਕ ਦੇ ਨਾਮ ਦਾ ਆਖਰੀ ਹਿੱਸਾ ਕੀ ਹੈ?"
    },
    {
      id: 6,
      question:
        "ਉਸ ਕਾਲਜ ਦਾ ਨਾਮ ਕੀ ਹੈ ਜਿਸ ਵਿੱਚ ਤੁਸੀਂ ਦਰਖਾਸਤ ਦਿੱਤੀ ਸੀ, ਪਰ ਦਾਖਲਾ ਨਹੀਂ ਲਿਆ?"
    },
    { id: 7, question: "ਤੁਹਾਡੇ ਪਿਤਾ ਦੇ ਨਾਮ ਦਾ ਵਿਚਕਾਰਲਾ ਹਿੱਸਾ ਕੀ ਹੈ?" },
    { id: 8, question: "ਤੁਹਾਡੀ ਮਾਤਾ ਦੇ ਨਾਮ ਦਾ ਵਿਚਕਾਰਲਾ ਹਿੱਸਾ ਕੀ ਹੈ?" },
    {
      id: 9,
      question:
        "ਤੁਹਾਡੇ ਸਭ ਤੋਂ ਵੱਡੇ ਭਰਾ/ਭੈਣ ਦੇ ਜਨਮਦਿਨ ਦਾ ਮਹੀਨਾ ਅਤੇ ਸਾਲ ਕੀ ਹੈ? (ਉਦਾਹਰਨ ਲਈ: ਨਵੰਬਰ 1985)"
    },
    {
      id: 10,
      question: "ਤੁਹਾਡੇ ਸਭ ਤੋਂ ਵੱਡੇ ਭਰਾ/ਭੈਣ ਦੇ ਨਾਮ ਦਾ ਵਿਚਕਾਰਲਾ ਹਿੱਸਾ ਕੀ ਹੈ?"
    },
    {
      id: 11,
      question: "ਤੁਹਾਡੇ ਸਭ ਤੋਂ ਵੱਡੇ ਭਰਾ/ਭੈਣ ਦੇ ਨਾਮ ਦਾ ਵਿਚਕਾਰਲਾ ਹਿੱਸਾ ਕੀ ਹੈ?"
    },
    { id: 12, question: "ਤੁਸੀਂ ਛੇਵੀਂ ਜਮਾਤ ਲਈ ਕਿਹੜੇ ਸਕੂਲ ਵਿੱਚ ਪੜ੍ਹੇ ਸੀ?" },
    {
      id: 13,
      question: "ਤੁਹਾਡੇ ਬਚਪਨ ਦੇ ਸਭ ਤੋਂ ਚੰਗੇ ਮਿੱਤਰ ਦੇ ਨਾਮ ਦਾ ਪਹਿਲਾ ਹਿੱਸਾ ਕੀ ਸੀ?"
    }
  ],
  roleIncidentOptions: [
    { value: "subject", label: " ਿਸ਼ਾ" },
    { value: "witness", label: "ਗਵਾਹ" },
    { value: "affected_individual", label: "ਪ੍ਰਭਾਵਿਤ ਵਿਅਕਤੀ" },
    { value: "other", label: "ਕੋਈ ਹੋਰ" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "ਆਫਿਸਰ" },
    { value: "management", label: "ਪ੍ਰਬੰਧਨ" },
    { value: "hourly_associate", label: "ਘੰਟਾਵਾਰ ਸਹਿਯੋਗੀ" },
    { value: "vendor_supplier", label: "ਵਿਕ੍ਰੇਤਾ/ਸਪਲਾਇਰ" },
    { value: "customer", label: "Customer" },
    { value: "other", label: "  ਕੋਈ ਹੋਰ" }
  ]
};

export default punjabi;
n
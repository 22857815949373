//Data for Bengali language Start
const bengali = {
  msgSendFailed: "মেসেজ পাঠাতে ব্যর্থ হয়েছে",
  ttPeopleInvolved: "সংশ্লিষ্ট ব্যক্তিগণ",
  msgSent: "আপনার মেসেজ সফলভাবে পাঠানো হয়েছে",
  invalidCredentials: "কেস নম্বর/পাসওয়ার্ড অকার্যকর",
  enterRepNo: "অনুগ্রহ করে আপনার রিপোর্ট নম্বর লিখুন",
  resetPassSuc: "পাসওয়ার্ড রিসেট সফল হয়েছে৷ আপনার নতুন পাসওয়ার্ড হলো ",
  clickHere: "এটি করতে এখানে ক্লিক করুন ",
  tfollowUp: "ফলোআপ",
  tyourCase: "আপনার কেস. ",

  daysAgo: " দিন আগে",
  followUpTxt:
    "কেসটি সম্পর্কে ফলোআপ করতে অনুগ্রহ করে রিপোর্ট নম্বর ও পাসওয়ার্ড লিখুন",
  emergencyNote:
    "এই ওয়েবসাইটটি জরুরি বিষয়গুলোর জন্য ব্যবহার করা উচিত নয় যেগুলোর জন্য তাৎক্ষণিক মৌখিক বিজ্ঞপ্তি প্রয়োজন। জরুরি পরিস্থিতিতে অনুগ্রহ করে আপনার স্থানীয় জরুরি পরিষেবাগুলোর সাথে যোগাযোগ করুন।",
  understand: "আমি বুঝতে পেরেছি",
  ttWhatHappened: "কী ঘটেছিল?",
  lblIncidentDetails: "ঘটনার বিবরণ",
  ttWhereHappened: "এটি কোথায় ঘটেছে?",
  pSelectLocation: "মেনু অপশন থেকে অবস্থান নির্বাচন করুন",
  lblCountry: "দেশ",
  lblState: "স্টেট",
  lblCity: "শহর",
  lblLocation: "স্থান",
  phMissingPlace: "যদি অবস্থানটি উপরে না পাওয়া যায়, তাহলে এখানে প্রবেশ করান",
  requestInfo: "আপনার উদ্বেগ সম্পর্কে অনুগ্রহ করে নিম্নলিখিত তথ্য দিন",
  liDateofSituation: "ঘটনার তারিখ/সময়",
  liAssociatesInvovled:
    "আপনার বর্ণিত ঘটনা সম্পর্কে যাদের জানা থাকতে পারে বা যারা ঘটনাটি দেখেছেন তাদের নামসহ ঘটনার সাথে জড়িত সহযোগীদের নাম",
  liSituationOccured: "ঘটনাটি যেখানে ঘটেছে সেই স্থান",
  liReportedAnyone:
    "আপনি কি কোম্পানির কারো কাছে আপনার উদ্বেগের কথা জানিয়েছেন? যদি জানিয়ে থাকেন, তাহলে কার কাছে এবং কখন?",
  spIfNoLocation: "যদি স্থানটি উপরে না পাওয়া যায়, শুধু তখন আবশ্যক",
  ttWhoInvolved: "কারা জড়িত ছিল?",
  pOtherPerson: "অনুগ্রহ করে ঘটনার সাথে জড়িত অন্যান্য ব্যক্তিদের নাম দিন",
  btnAddPerson: "আরেকটি যোগ করুন",
  ttContactInfo: "যোগাযোগের তথ্য",
  pAnonymous: "আপনি কি বেনামী থাকতে চান?",
  btnReviewForm: "পর্যালোচনা ফরম",
  phFirstName: "নামের প্রথমাংশ",
  phLastName: "নামের শেষাংশ",
  lblJobTitle: "চাকরির পদবি",
  IncidentRole: "ঘটনায় ভূমিকা",
  btnRemove: " অপসারণ করুন",
  phPhoneNo: "ফোন নম্বর",
  phEmail: "ই-মেইল",
  valEmail: "অনুগ্রহ করে সঠিক ই-মেইল ঠিকানা প্রবেশ করান",
  ttReportReview: "রিপোর্ট পর্যালোচনা",
  ttConfirmDetails:
    "অনুগ্রহ করে রিপোর্টের বিবরণ নিশ্চিত করুন এবং একটি পাসওয়ার্ড তৈরি করুন",
  ttIncidentDetails: "ঘটনার বিস্তারিত বিবরণ",
  ttIncidentDescription: "ঘটনার বর্ণনা",
  ttIncidentLocation: "ঘটনাটি কোথায় ঘটেছে",
  pImAnonymous: "বেনামী",
  btnEditReport: "রিপোর্ট সম্পাদনা করুন",
  fhtPasswordDesc:
    "আপনার রিপোর্ট সম্পর্কে ফলোআপ করতে পাসওয়ার্ড ব্যবহার করা হবে",
  pPasswordNote:
    " গুরুত্বপূর্ণ: আপনার রিপোর্টের স্ট্যাটাস দেখতে, বা বাড়তি কোনো তথ্য প্রদান করতে ফলোআপ পেইজে লগ-ইন করার জন্য আপনার রিপোর্ট নম্বর ও পাসওয়ার্ড প্রয়োজন হবে৷ আপনার গোপনীয়তা সুরক্ষিত রাখার জন্য, পরবর্তীতে এই তথ্য পুনরুদ্ধার করা যাবে না। আমরা সরাসরি আপনার ইমেইল ঠিকানায় জবাব পাঠানো না বা ফলোআপ করবো না৷",
  ttAdditionalInfo:
    "আপনার নৈতিকতা সংক্রান্ত উদ্বেগের বিষয়ে বাড়তি তথ্য প্রদান করার জন্য আপনাকে ধন্যবাদ। আমাদের টিম আপনার জবাব পর্যালোচনা করবে এবং বাড়তি তথ্য বা বিস্তারিত বিবরণের অনুরোধ জানানোর জন্য ফলোআপ রিপোর্ট পেইজ ব্যবহার করতে পারে, তাই অনুগ্রহ করে সাইটটি নিয়মিত চেক করুন৷",
  ttReportNo: "রিপোর্টের জন্য ফলোআপ নিশ্চিতকরণ",
  btnCompleteReport: "পূর্ণাঙ্গ রিপোর্ট",
  dReportDet: "রিপোর্টের বিবরণ",
  sIncidentDesc: "ঘটনার বর্ণনা",
  ttReportHistory: "রিপোর্টের ইতিহাস",
  sRepSubOn: "রিপোর্ট জমা দেয়ার তারিখ",
  btnReview: "পর্যালোচনা",
  pCaseClosed:
    "এই কেসটি বন্ধ হয়ে গেছে৷ যদি আপনার অন্য কোনো উদ্বেগ থাকে, তাহলে আপনার উদ্বেগ সম্পর্কে রিপোর্ট করতে অনুগ্রহ করে ‘একটি উদ্বেগ সম্পর্কে রিপোর্ট করুন’ পেইজে যান",
  ComFrmEthics: "এথিকস বিভাগ থেকে যোগাযোগ",
  Reporter: "রিপোর্টার",
  System: "সিস্টেম",
  hReport: "রিপোর্ট",
  hCreated: "তৈরি করা হয়েছে",
  ttThanksforReport: "আপনার নৈতিকতা সংক্রান্ত উদ্বেগ জমা দেয়ার জন্য ধন্যবাদ",
  progressMsg:
    "আমরা আপনার এথিক্স কনসার্ন তৈরি করার প্রক্রিয়ার মধ্যে আছি। দয়া করে ধৈর্য ধরুন..",
  ttFollowUpDet: "ফলোআপ-এর বিবরণ",
  pReportReview:
    "আমাদের গ্লোবাল এথিকস অফিস আপনার জমা দেয়া রিপোর্ট পর্যালোচনা করবে এবং আমাদের ফলোআপ রিপোর্টের পেইজ ব্যবহার করে 3-5 কর্মদিবসের মধ্যে একটি প্রাথমিক জবাব প্রদান করবে।",
  pAdditionalInforReq:
    "বাড়তি তথ্য বা বিস্তারিত বিবরণের অনুরোধ জানানোর জন্য আমাদের টিম ফলোআপ রিপোর্ট পেইজ ব্যবহার করতে পারে, তাই অনুগ্রহ করে সাইটটি নিয়মিত চেক করুন",
  pNeedPd:
    "এই রিপোর্ট সম্পর্কে ফলোআপ করতে আপনাকে পাসওয়ার্ড ও রিপোর্ট নম্বর লিখে নিতে হবে। আপনার গোপনীয়তা সুরক্ষিত রাখার জন্য, পরবর্তীতে এই তথ্য পুনরুদ্ধার করা যাবে না",
  pReportNo: "রিপোর্ট নম্বর",
  ttReportFlwUp: "রিপোর্টের ফলোআপ",
  ttEnterReportNo:
    "কেসটি সম্পর্কে ফলোআপ করতে অনুগ্রহ করে রিপোর্ট নম্বর ও পাসওয়ার্ড লিখুন",
  btnFindReport: "রিপোর্ট খুঁজুন",
  ttFollowReview: "ফলোআপ পর্যালোচনা",
  ttConfirmDet: "অনুগ্রহ করে রিপোর্টের বিবরণ নিশ্চিত করুন",
  ttAddDetRes: "বাড়তি বিবরণ/জবাব",
  btnEdit: "সম্পাদনা",
  btnSubmit: "জমা দিন",

  yes: "হ্যাঁ",
  no: "না",
  genricError:
    "আমাদের সাথে যোগাযোগের জন্য অনুগ্রহ করে একটি বিকল্প পদ্ধতি ব্যবহার করুন। আপনি Ethics@Wal-mart.com ঠিকানায় একটি ইমেইল পাঠাতে পারেন। ",
  wrongAnswer:
    "আপনার উত্তরগুলো আমাদের ডাটার সাথে মিলেনি। অনুগ্রহ করে আবার চেষ্টা করুন৷",
  resetLimit:
    "তিনবার ব্যর্থ চেষ্টার পরে রিসেট নিষিদ্ধ করা হয়েছে। আপনার কাছে প্রদান করার জন্য বাড়তি তথ্য থাকলে অনুগ্রহ করে 15 মিনিট পরে আবার চেষ্টা করুন অথবা Ethics@Wal-mart.com ঠিকানায় একটি ইমেইল পাঠান৷ ইমেইলের বিষয়বস্তুর ঘরে আপনাকে অবশ্যই আপনার এথিকস কেস নম্বর উল্লেখ করতে হবে।",
  resetPasswordHeader: "রিপোর্ট নম্বরের জন্য পাসওয়ার্ড রিসেট করুন",
  answerTxt:
    "একটি নতুন পাসওয়ার্ড তৈরি করতে নিচের নিরাপত্তা বিষয়ক প্রশ্নগুলোর উত্তর দিন (স্পেসিং ও বড় হাতের অক্ষরসহ উত্তরগুলো সম্পূর্ণভাবে মিলতে হবে)",
  question1: "প্রশ্ন 1",
  question2: "প্রশ্ন 2",
  question3: "প্রশ্ন 3",
  btnVerify: "যাচাই করুন",
  btnReset: "রিসেট করুন",
  securtiyQuestionHdr:
    "আপনার রিপোর্টের জন্য একটি পাসওয়ার্ড তৈরি করতে নিরাপত্তা বিষয়ক তিনটি প্রশ্ন নির্বাচন করুন এবং সেগুলোর উত্তর দিন",
  generateButton: "পাসওয়ার্ড তৈরি করুন",
  pPasswordtxt: "পাসওয়ার্ড",
  forgotPd: "পাসওয়ার্ড ভুলে গেছেন",
  additionalInfo: "বাড়তি তথ্য",
  justNow: "এইমাত্র",
  fewMinutes: "কয়েক মিনিট আগে",
  hours: "ঘণ্টা আগে",
  month: "মাস আগে",
  year: "বছর আগে",
  start: "শুরু",
  end: "শেষ",

  resetError:
    "24-ঘণ্টা সময়ের মধ্যে দ্বিতীয়বার রিসেট করা নিষিদ্ধ। অনুগ্রহ করে আগামীকাল আবার চেষ্টা করুন অথবা প্রদান করার জন্য আপনার কাছে বাড়তি তথ্য থাকলে Ethics@Wal-mart.com ঠিকানায় ইমেইল করুন৷ ইমেইলের সাবজেক্টের ঘরে আপনাকে অবশ্যই আপনার ইথিকস কেস নম্বর অন্তর্ভুক্ত করতে হবে।",

  SecurityQuestions: [
    {
      id: 1,
      question:
        "কোন শহরে আপনার প্রথম স্বামী/স্ত্রী বা পার্টনারের সাথে আপনার দেখা হয়েছিল?"
    },
    { id: 2, question: "আপনার বাবা কোন শহরে জন্মগ্রহণ করেছিলেন?" },
    { id: 3, question: "আপনার প্রথম চাকরি কোন শহরে ছিল?" },
    { id: 4, question: "আপনার মা কোন শহরে জন্মগ্রহণ করেছিলেন?" },
    {
      id: 5,
      question: "হাই স্কুলে আপনার প্রিয় শিক্ষকের নামের শেষাংশ কী ছিল?"
    },
    {
      id: 6,
      question: "আপনি আবেদন করেছিলেন কিন্তু ভর্তি হননি এমন একটি কলেজের নাম কী?"
    },
    { id: 7, question: "আপনার বাবার নামের মধ্যাংশ কী?" },
    { id: 8, question: "আপনার মায়ের নামের মধ্যাংশ কী?" },
    {
      id: 9,
      question:
        "আপনার সবচেয়ে বড় ভাইবোনের জন্মের মাস ও বছর কোনটি? (যেমন নভেম্বর 1985)"
    },
    { id: 10, question: "আপনার সবচেয়ে বড় ভাইবোনের নামের মধ্যাংশ কী?" },
    { id: 11, question: "আপনার সবচেয়ে ছোট ভাইবোনের নামের মধ্যাংশ কী?" },
    { id: 12, question: "আপনি ষষ্ঠ শ্রেণিতে কোন স্কুলে পড়েছেন?" },
    {
      id: 13,
      question: "আপনার শৈশবের সবচেয়ে ভালো বন্ধুর নামের প্রথমাংশ কী ছিল?"
    }
  ],
  roleIncidentOptions: [
    { value: "subject", label: "বিষয়বস্তু" },
    { value: "witness", label: "প্রত্যক্ষদর্শী" },
    { value: "affected_individual", label: "ক্ষতিগ্রস্ত ব্যক্তি" },
    { value: "other", label: "অন্যান্য" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "কর্মকর্তা" },
    { value: "management", label: "ব্যবস্থাপনা কর্তৃপক্ষ" },
    { value: "hourly_associate", label: "ঘণ্টা হিসেবে সহযোগী" },
    { value: "vendor_supplier", label: "ভেন্ডর/সরবরাহকারী" },
    { value: "customer", label: "গ্রাহক" },
    { value: "other", label: "অন্যান্য" }
  ]
};
//bengali end

export default bengali;
n
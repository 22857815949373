export const securityQuesConfig = [
  { id: 1, active: true },
  { id: 2, active: true },
  { id: 3, active: true },
  { id: 4, active: true },
  { id: 5, active: true },
  { id: 6, active: true },
  { id: 7, active: true },
  { id: 8, active: true },
  { id: 9, active: true },
  { id: 10, active: true },
  { id: 11, active: true },
  { id: 12, active: true },
  { id: 13, active: true },
  { id: 14, active: true },
  { id: 15, active: true },
  { id: 16, active: true },
  { id: 17, active: true },
  { id: 18, active: true },
  { id: 19, active: true },
  { id: 20, active: true }
];

export const internalAPI = {
  VerifyApiUrl:
  `${process.env.PUBLIC_URL}/verifyquestion`,
  UpdatePasswordApiUrl:
  `${process.env.PUBLIC_URL}/updatepassword`,
  GetResetQuesApiUrl:
  `${process.env.PUBLIC_URL}/resetquestion`,
  FollowUpApiUrl:
  `${process.env.PUBLIC_URL}/reportfollowup`,
  FollowUpMsgApiUrl:
  `${process.env.PUBLIC_URL}/additonaldata`,
  CreateConcernApiUrl:
  `${process.env.PUBLIC_URL}/reportsubmit`,
  getAllCountries:
  `${process.env.PUBLIC_URL}/getallcountries`,
  citySelctionApiUrl:
  `${process.env.PUBLIC_URL}/getallcities?`,
  stateSelctionApiUrl:
  `${process.env.PUBLIC_URL}/getallstates?`,
  locationSelctionApiUrl:
  `${process.env.PUBLIC_URL}/getalllocations?`
}
export default { securityQuesConfig, internalAPI };

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { internalAPI } from "../../config";
import { selectedLanguage } from "../../lang-config";
import { isEqual } from "lodash";
import "./followupreview.css";
import { currentLang } from "../../helper";

import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

const showLang = currentLang();

const styles = (theme) => ({
  root: {
    margin: "30px auto",
    width: "75%"
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#0071CE !important",
    width: "180px",
    marginTop: "24px",
    height: "40px"
  },
  buttonStyle: {
    width: "180px",
    marginTop: "24px",
    height: "40px",
    textAlign: "center",
    paddingTop: "5px",
    borderRadius: "8px !important",
    color: "white"
  }
});
let localLanguageData = "";
class FollowupReviewComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      responseData: "",
      errorData: "",
      successData: "",
      disableButton: false
    };
    if (this.props.pageData && this.props.pageData !== undefined) {
      this.props.location.responseData = this.props.pageData;
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const { LanguageData } = this.props.location;
    localLanguageData = LanguageData;
    if (this.props.location && this.props.location.pageData === undefined) {
      this.props.history.push({
        pathname: `/followup/locale=${showLang}`,
        LanguageData: localLanguageData
      });
    }
    const parent_origin =
      window.location !== window.parent.location
        ? document.referrer !== ""
          ? document.referrer
          : "*"
        : document.location.href;
    this.myScrollToTop(parent_origin);
  }
  myScrollToTop = (parent_origin) => {
    window.parent.postMessage("scrollToTop", parent_origin);
  };
  handleReview = () => {
    this.props.history.push({
      pathname: `/followupresponse/locale=${showLang}`,
      additionalInfo: this.props.location.additionalInfo,
      reportNumber: this.props.location.reportNumber,
      pin: this.props.location.pin,
      responseData: this.props.location.pageData,
      LanguageData: localLanguageData
    });
  };
  handleSubmit = () => {
    this.setState({ disableButton: true });
    axios
      .post(internalAPI.FollowUpMsgApiUrl, {
        u_case: this.props.location.reportNumber,
        u_message: this.props.location.additionalInfo,
        u_pin: this.props.location.pin,
        u_language: "english"
      })
      .then(
        (res) => {
          let response = { data: res.data.response };
          if (
            response &&
            response.data &&
            response.data !== "" &&
            response.data !== undefined
          ) {
            this.setState(
              {
                responseData: response,
                errorData: "",
                successData: localLanguageData.msgSent
              },
              () => {
                this.props.history.push({
                  pathname: `/followupconfirmation/locale=${showLang}`,
                  responseData: this.state.responseData,
                  reportNumber: this.props.location.reportNumber,
                  LanguageData: localLanguageData
                });
              }
            );
          } else {
            this.setState({
              errorData: localLanguageData.msgSendFailed,
              disableButton: false
            });
          }
        },
        (error) => {
          this.setState({
            errorData: localLanguageData.msgSendFailed,
            disableButton: false
          });
        }
      );
  };
  render() {
    const { classes } = this.props;
    const LanguageData =
      this.props.location.LanguageData &&
      JSON.parse(
        DOMPurify.sanitize(JSON.stringify(this.props.location.LanguageData))
      );
    localLanguageData =
      isEqual(LanguageData, "") || isEqual(LanguageData, undefined)
        ? selectedLanguage()
        : LanguageData;
    return (
      <div className={classes.root} id="mainPage">
        <h1>{localLanguageData.ttFollowReview}</h1>
        <h4>{localLanguageData.ttConfirmDet}</h4>
        <h2 style={{ fontWeight: "bold" }}>{localLanguageData.ttAddDetRes}</h2>
        <span style={{ marginLeft: "50px" }}>
          {this.props.location.additionalInfo}
        </span>
        <br />
        <div className="buttonDiv">
          <div className={classes.buttonStyle} id="button_followup">
            <Button
              variant="contained"
              className={classes.buttonText}
              onClick={this.handleReview}
            >
              {localLanguageData.btnEdit}
            </Button>
          </div>
          <div className={classes.buttonStyle} id="button_followup_submit">
            <Button
              variant="contained"
              className={classes.buttonText}
              onClick={this.handleSubmit}
              disabled={this.state.disableButton}
            >
              {localLanguageData.btnSubmit}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(FollowupReviewComponent));

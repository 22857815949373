//Data for hindi language Start
const hindi = {
  msgSendFailed: "संदेश भेजने में विफल हुआ",
  ttPeopleInvolved: "शामिल लोग",
  msgSent: "आपका संदेश सफलतापूर्वक भेज दिया गया है",
  invalidCredentials: "अमान्य केस संख्या/पासवर्ड",
  enterRepNo: "कृपया अपना रिपोर्ट नंबर दर्ज करें",
  followUpTxt:
    "कृपया इस केस पर फॉलो अप के लिए रिपोर्ट नंबर और पासवर्ड प्रविष्ट करें", // report follow up,
  //report home page
  emergencyNote: `यह वेबसाइट उन आपात स्थितियों के लिए उपयोग नहीं की जानी चाहिए जिन में तत्काल मौखिक सूचना की आवश्यकता होती है। आपातकालीन स्थिति में कृपया अपनी स्थानीय आपातकालीन सेवाओं से संपर्क करें।`,
  understand: "मै समझता/समझती हूँ",
  ttWhatHappened: "क्या हुआ?",
  lblIncidentDetails: "घटना का विवरण",
  ttWhereHappened: "यह कहाँ घटित हुआ?",
  pSelectLocation: "मेनू विकल्प से स्थान का चयन करें",
  lblCountry: "देश",
  lblState: "राज्य",
  lblCity: "नगर",
  lblLocation: "स्थान",
  phMissingPlace: "यदि उपर स्थान नहीं मिले, तो यहाँ प्रविष्ट करें",
  requestInfo: "कृपया अपनी चिंता के बारे में निम्नलिखित जानकारी प्रदान करें",
  liDateofSituation: "परिस्थिति की तिथि/समय",
  liAssociatesInvovled:
    "घटना में शामिल सहयोगियों के नाम जो आप के द्वारा वर्णित परिस्थिति के बारे में संभवतः जानकारी रखते हों या साक्षी हो सकते हैं",
  liSituationOccured: "परिस्थिति घटित होने का स्थान",
  liReportedAnyone:
    "क्या आप ने अपनी चिंता की रिपोर्ट कंपनी में किसी और को दी है? यदि हाँ, तो किसे और कब?",
  spIfNoLocation: "केवल ऐसी स्थिति में दरकार होगा जब स्थान उपर नहीं मिल पाए",
  ttWhoInvolved: "कौन शामिल थे?",
  pOtherPerson: "कृपया घटना में शामिल अन्य व्यक्तियों के नाम उपलब्ध कराएँ",
  btnAddPerson: "एक और जोड़ें",
  ttContactInfo: "संपर्क जानकारी",
  pAnonymous: "क्या आप गुमनाम रहना चाहते हैं?",
  btnReviewForm: "प्रपत्र की समीक्षा करें",
  //details page - child component of report home page
  phFirstName: "प्रथम नाम",
  phLastName: "अंतिम नाम",
  lblJobTitle: "पदनाम",
  IncidentRole: "घटना में भूमिका",
  btnRemove: "हटाएं ",
  phPhoneNo: "फोन नंबर",
  phEmail: "ईमेल",
  valEmail: "कृपया मान्य ईमेल प्रविष्ट करें",

  // Report Review
  ttReportReview: "रिपोर्ट की समीक्षा",
  ttConfirmDetails: "कृपया रिपोर्ट के विवरण की पुष्टि करें और पासवर्ड बनाएँ",
  ttIncidentDetails: "घटना का विवरण",
  ttIncidentDescription: "घटना का निरूपण",
  ttIncidentLocation: "घटना का स्थान",
  pImAnonymous: "गुमनाम",
  btnEditReport: "रिपोर्ट संपादित करें",
  fhtPasswordDesc:
    "आप की रिपोर्ट के फॉलो अप के लिए पासवर्ड का उपयोग किया जाएगा",
  pPasswordNote: ` महत्वपूर्ण: अपनी रिपोर्ट की स्थिति देखने, या अतिरिक्त जानकारी प्रदान करने हेतु आपको फॉलो अप पृष्ठ पर लॉग-इन करने के लिए अपने रिपोर्ट नंबर और पासवर्ड की आवश्यकता है। आपकी गोपनीयता की सुरक्षा के लिए, इस जानकारी को बाद में पुनर्प्राप्त नहीं किया जा सकता है। हम सीधे आपके ईमेल पते पर प्रतिक्रिया नहीं देते हैं न ही फॉलो अप करते हैं।`,
  btnCompleteReport: "संपूर्ण रिपोर्ट",

  // follow up confirmation
  ttReportNo: "रिपोर्ट के लिए फॉलो अप की पुष्टि",
  ttAdditionalInfo: ` अपनी नैतिकता की चिंता पर अतिरिक्त जानकारी प्रदान करने के लिए धन्यवाद। हमारी टीम आपकी प्रतिक्रिया की समीक्षा करेगी और रिपोर्ट के फॉलो अप पृष्ठ का उपयोग करते हुए अतिरिक्त जानकारी या विवरण माँग सकती है, इसलिए कृपया नियमित रूप से साइट देखें।
    `,

  // report confirm
  hReport: "रिपोर्ट",
  hCreated: "बनाई गई",
  ttThanksforReport: "अपनी नैतिकता की चिंता जमा करने के लिए आप का धन्यवाद",
  progressMsg:
    "हम आपकी नैतिकता संबंधी चिंता तैयार करने की प्रक्रिया में हैं। कृपया धैर्य रखें..",
  ttFollowUpDet: "फॉलो अप विवरण",
  pReportReview: `हमारा वैश्विक नैतिकता कार्यालय आप के द्वारा जमा की गई विवरण की समीक्षा करेगा और हमारा फॉलो अप रिपोर्ट पृष्ठ का उपयोग करते हुए 3 से 5 कारोबारी दिनों के अंदर अंदर प्राथमिक प्रतिक्रिया से अवगत कराएगा।`,
  pAdditionalInforReq: ` हमारी टीम अतिरिक्त जानकारी या विवरण का अनुरोध करने के लिए फ़ॉलो अप रिपोर्ट पृष्ठ का उपयोग कर सकती है, इसलिए कृपया नियमित रूप से साइट देखें`,
  pNeedPd: `आप को इस रिपोर्ट पर फॉलो अप के लिए पासवर्ड और रिपोर्ट नंबर प्रविष्ट करना होगा। अपनी गोपनियता की सुरक्षा के लिए, यह जानकारी बाद में दोबारा प्राप्त नहीं की जा सकती है`,
  pReportNo: "रिपोर्ट नंबर",
  ttReportFlwUp: "रिपोर्ट का फॉलो अप",
  ttEnterReportNo:
    "इस केस पर फॉलो अप के लिए कृपया रिपोर्ट नंबर और पासवर्ड प्रविष्ट करें",
  btnFindReport: "रिपोर्ट खोजें",
  ttFollowReview: "फॉलो अप समिक्षा",
  ttConfirmDet: "कृपया रिपोर्ट के विवरण की पुष्टि करें",
  ttAddDetRes: "अतिरिक्त विवरण/प्रतिक्रिया",
  btnEdit: "संपादन करें",
  btnSubmit: "जमा करें",

  //Follow up reponse
  dReportDet: "रिपोर्रट का विवऱण",
  sIncidentDesc: "परिस्थिति का निरूपण",
  ttReportHistory: "रिपोर्ट का इतिहास",
  sRepSubOn: "रिपोर्ट जमा करने की तिथि",
  btnReview: "समीक्षा",
  pCaseClosed:
    "  इस केस को बंद कर दिया गया है। यदि आप को कोई और अतिरिक्त चिंता है, तो कृपया अपनी चिंता की सूचना देने के लिए चिंता की रिपोर्ट करें पृष्ठ पर जाएँ",
  ComFrmEthics: "नैतिकता से संवाद",
  Reporter: "रिपोर्ट-कर्ता",
  System: "सिस्टम",
  resetPassSuc: "पासवर्ड रीसेट सफल। आपका नया पासवर्ड है ",
  clickHere: "यहाँ क्लिक करें ",
  tfollowUp: "फॉलो-अप करने हेतु",
  tyourCase: "आप का केस. ",
  //Reset Password,
  justNow: "अभी अभी",
  fewMinutes: "कुछ मिनट पहले",
  hours: "घंटे पहले",
  month: "महीने पहले",
  year: "वर्ष पहले",
  additionalInfo: "अतिरिक्त जानकारी",
  forgotPd: "पासवर्ड भूल गए",
  daysAgo: " दिन पूर्व",
  yes: "हाँ",
  start: "आरंभ करें",
  end: "समाप्त",
  no: "नहीं",
  genricError:
    "कृपया हम से संपर्क करने लिए वैकल्पिक विधि का उपयोग करें। आप Ethics@Wal-mart.com पर ईमेल भेज सकते हैं।",
  wrongAnswer:
    "आप का उत्तर हमारे डेटा से मेल नहीं खाता है। कृपया पुनः प्रयास करें।",
  resetError:
    "24 घंटे की अवधि के भीतर दूसरा रीसेट निषिद्ध है। यदि आप के पास देने के लिए अतिरिक्त जानकारी है तो कृपया कल फिर से प्रयास करें या Ethics@Wal-mart.com पर एक ईमेल भेजें। ईमेल की विषय-पंक्ति में आप अपना Ethics (आचार-नीति) केस नंबर अवश्य शामिल करें।",
  resetLimit:
    "तीन विफल प्रयासों के बाद रीसेट प्रतिबंधित है। यदि आप को अतिरिक्त सूचना प्रदान करनी है तो कृपया 15 मिनट बाद पुनः प्रयास करें या Ethics@Wal-mart.com पर ईमेल भेजें। ईमेल के विषय में आप को अपना नैतिकता केस नंबर अवश्य शामिल करना चाहिए।",
  resetPasswordHeader: "रिपोर्ट नंबर के लिए पासवर्ड रिसेट करें",
  answerTxt:
    "नया पासवर्ड बनाने के लिए निम्नलिखित सुरक्षा प्रश्नों के उत्तर दें (उत्तर पूर्ण रूप से मेल खाने चाहिए, जिस में खाली स्थान एवं बड़े अक्षर शामिल हैं)",
  question1: "प्रश्न 1",
  question2: "प्रश्न 2",
  question3: "प्रश्न 3",
  btnVerify: "सत्यापित करें",
  btnReset: "रिसेट करें",
  securtiyQuestionHdr:
    "अपने रिपोर्ट के लिए पासवर्ड बनाने के लिए तीन सुरक्षा प्रश्नों का चयन करें और उन के उत्तर दें:",
  generateButton: "पासवर्ड बनाएं",
  pPasswordtxt: "पासवर्ड",
  SecurityQuestions: [
    {
      id: 1,
      question:
        "आप अपने प्रथम जीवन साथी/पार्टनर से किस शहर में मिले थे/मिली थीं?"
    },
    { id: 2, question: "आप के पिता का जन्म किस शहर में हुआ था?" },
    { id: 3, question: "आप की पहली नौकरी किस शहर में थी?" },
    { id: 4, question: "आप की माता का जन्म किस शहर में हुआ था?" },
    {
      id: 5,
      question: "उच्च विद्यलाय में आप के पसंदीदा शिक्षक का अंतिम नाम क्या है?"
    },
    {
      id: 6,
      question:
        "आप के उस महाविद्यालय का नाम क्या है जहाँ आप ने आवेदन तो किया था परंतु कभी गए नहीं?"
    },
    { id: 7, question: "आप के पिता का मध्य नाम क्या है?" },
    { id: 8, question: "आप की माता का मध्य नाम क्या है?" },
    {
      id: 9,
      question:
        "आप के सब से बड़े भाई/बहन के जन्मतिथि का महीना और वर्ष क्या है? (उदाहरण नवंबर 1985)"
    },
    { id: 10, question: "आप के सब से बड़े भाई/बहन का मध्य नाम क्या है?" },
    { id: 11, question: "आप के सब से छोटे भाई/बहन का मध्य नाम क्या है?" },
    { id: 12, question: "छठे ग्रेड के लिए आप किस स्कूल में गए थे?" },
    {
      id: 13,
      question: "आप के बचपन के सब से अच्छे मित्र का प्रथम नाम क्या था?"
    }
  ],
  roleIncidentOptions: [
    { value: "subject", label: " विषय" },
    { value: "witness", label: " गवाह" },
    { value: "affected_individual", label: "प्रभावित व्यक्ति" },
    { value: "other", label: "अन्य" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "ऑफिसर" },
    { value: "management", label: "प्रबंधन" },
    { value: "hourly_associate", label: "घंटेवार सहयोगी" },
    { value: "vendor_supplier", label: "वेंडर/आपूर्तिकर्ता" },
    { value: "customer", label: "ग्राहक" },
    { value: "other", label: "अन्य" }
  ]
};

export default hindi;
n
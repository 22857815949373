import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { selectedLanguage } from "../../lang-config";
import { isEqual } from "lodash";
import "./index.css";
let localLanguageData = "";


const styles = theme => ({
  header: {
    paddingTop: "10px",
    width: "48%"
  },
  root: {
    boxShadow: "0 0 14px #3c505080",
    height: "340px"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 375,
    maxWidth: 1200
  },
  button: {
    marginRight: "18px",
    float: "right",
    color: "#1187c7",
    fontWeight: "bold"
  },
  textBox: {
    width: "90%"
  },
  buttonStyle: {
    paddingTop: "15px",
    paddingRight: "20px",
    paddingBottom: "10px"
  },
  input: {
    "&::placeholder": {
      color: "color: rgba(0,0,0,1) !important;",
      fontWeight: "bold"
    }
  }
});
let jobTitleOptions = [];
let roleIncidentOptions = [];
class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobTitle: "",
      roleInIncident: "",
      firstName: "",
      lastName: "",
      index: this.props.index
    };
  }
  componentDidMount() {
    if (this.props.data != null) {
      this.setState({
        jobTitle: this.props.data.jobTitle,
        roleInIncident: this.props.data.roleInIncident,
        firstName: this.props.data.firstName,
        lastName: this.props.data.lastName,
        index: this.props.data.index
      });
    }
    if (localLanguageData.hasOwnProperty("jobTitleOptions")) {
      jobTitleOptions = localLanguageData.jobTitleOptions;
    }
    if (localLanguageData.hasOwnProperty("roleIncidentOptions")) {
      roleIncidentOptions = localLanguageData.roleIncidentOptions;
    }
  }
  handleBlur = e => {
    this.props.detailsCbFn(this.state);
  };
  handleChange = async e => {
    if (e && e.target && e.target.name === "checked") {
      await this.setState(
        {
          [e.target.name]: !this.state.checked,
          index: this.state.index
        },
        function() {
          this.props.detailsCbFn(this.state);
        }
      );
    } else {
      await this.setState(
        {
          [e.target.name]: e.target.value,
          index: this.state.index
        },
        function() {
          this.props.detailsCbFn(this.state);
        }
      );
    }
    this.props.detailsCbFn(this.state);
  };
  handleRemovePerson(e) {
    this.props.handleRemovePerson(e);
  }

  render() {
    const { classes } = this.props;
    const { LanguageData } = this.props;
    localLanguageData =
      isEqual(LanguageData, "") || isEqual(LanguageData, undefined)
        ? selectedLanguage()
        : LanguageData;
    if (localLanguageData.hasOwnProperty("jobTitleOptions")) {
      jobTitleOptions = localLanguageData.jobTitleOptions;
    }
    if (localLanguageData.hasOwnProperty("roleIncidentOptions")) {
      roleIncidentOptions = localLanguageData.roleIncidentOptions;
    }
    return (
      <div className="persons" onBlur={this.handleBlur}>
        <div className={classes.root} style={{ height: "390px" }}>
          <TextField
            id="filled-full-width"
            style={{ margin: 8 }}
            name="firstName"
            value={this.state.firstName}
            placeholder={localLanguageData.phFirstName}
            fullWidth
            margin="normal"
            variant="filled"
            InputProps={{ classes: { input: this.props.classes["input"] } }}
            className={classes.textBox}
            onChange={this.handleChange}
          />
          <TextField
            id="filled-full-width"
            style={{ margin: 8 }}
            name="lastName"
            value={this.state.lastName}
            placeholder={localLanguageData.phLastName}
            fullWidth
            margin="normal"
            variant="filled"
            InputProps={{ classes: { input: this.props.classes["input"] } }}
            className={classes.textBox}
            onChange={this.handleChange}
          />
          <FormControl className={classes.formControl}>
            <InputLabel>{localLanguageData.lblJobTitle} </InputLabel>
            <Select
              value={this.state.jobTitle}
              onChange={this.handleChange}
              displayEmpty
              name="jobTitle"
            >
              {jobTitleOptions.map((item, index) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel> {localLanguageData.IncidentRole}</InputLabel>
            <Select
              value={this.state.roleInIncident}
              onChange={this.handleChange}
              displayEmpty
              name="roleInIncident"
            >
              {roleIncidentOptions.map((item, index) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {this.props.number > 1 ? (
            <div className={classes.buttonStyle}>
              <Button
                variant="outlined"
                className={classes.button}
                value={this.state.index}
                onClick={e => {
                  this.handleRemovePerson(e);
                }}
              >  {'X '+localLanguageData.btnRemove}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Details);

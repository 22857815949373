import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { isEqual } from "lodash";
import "./reportfollowup.css";
import { selectedLanguage } from "../../lang-config";
import { internalAPI } from "../../config";
import { currentLang } from "../../helper";

const showLang = currentLang();

const styles = (theme) => ({
  root: {
    margin: "0 auto",
    width: "65%"
  },

  data: {
    backgroundColor: "#c2d3da",
    paddingLeft: "25px",
    borderRadius: "10px",
    boxShadow: "0 0 14px #3c505080"
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#0071CE !important",
    width: "230px",
    height: "40px"
  },
  buttonStyle: {
    float: "right",
    marginTop: "30px",
    width: "230px",
    height: "40px",
    textAlign: "center",
    paddingTop: "5px",
    borderRadius: "8px !important",
    color: "white"
  },
  spanText: {
    float: "left",
    marginLeft: "4%",
    width: "320px",
    marginRight: "100%"
  },
  forgetLink: {
    textDecoration: "underline",
    float: "left",
    marginTop: "25px",
    width: "150px",
    cursor: "pointer",
    marginLeft: "8px"
  }
});
let localLanguageData = "";
class ReportFollowupComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      secword: "",
      reportNumber: "",
      disableButton: true,
      responseData: "",
      errorData: ""
    };
    if (this.props.pageData && this.props.pageData !== undefined) {
      this.state.disableButton = this.props.pageData;
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const parent_origin =
      window.location !== window.parent.location
        ? document.referrer !== ""
          ? document.referrer
          : "*"
        : document.location.href;
    this.myScrollToTop(parent_origin);
  }
  myScrollToTop = (parent_origin) => {
    window.parent.postMessage("scrollToTop", parent_origin);
  };
  componentDidUpdate(nextProps, nextState) {
    nextState.disableButton = !(nextState.secword && nextState.reportNumber);
  }
  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
      errorData: ""
    });
    if (this.state.reportNumber !== "" && this.state.secword !== "") {
      await this.setState({ disableButton: false });
    } else {
      await this.setState({ disableButton: true });
    }
  };
  entReport = (e) => {
    if (isEqual(e.key, "Enter")) {
      this.handleSubmit();
    }
  };
  handlesecword = () => {
    if (this.state.reportNumber !== "") {
      this.setState({ errorData: "" });
      axios
        .post(internalAPI.GetResetQuesApiUrl, {
          number: this.state.reportNumber
        })
        .then(
          (res) => {
            let response = { data: res.data.response };
            if (
              response &&
              response.data &&
              response.data !== "" &&
              response.data !== undefined &&
              response.data.is_call_center === false
            ) {
              this.props.history.push({
                pathname: `/resetpassword/locale= ${showLang}`,
                responseData: response,
                reportNumber: this.state.reportNumber,
                LanguageData: localLanguageData
              });
            } else {
              this.setState({
                errorData: localLanguageData.genricError
              });
            }
          },
          (error) => {
            this.setState({
              errorData: localLanguageData.genricError
            });
          }
        );
    } else {
      this.setState({
        errorData: localLanguageData.enterRepNo
      });
    }
  };
  handleSubmit = async () => {
    this.setState({ errorData: "", disableButton: true });
    axios
      .post(internalAPI.FollowUpApiUrl, {
        number: this.state.reportNumber,
        u_pin: this.state.secword
      })
      .then(
        (res) => {
          let responseData = { data: res.data.response };
          if (
            responseData &&
            responseData.data &&
            responseData.data !== "" &&
            responseData.data !== undefined
          ) {
            this.props.history.push({
              pathname: `/followupresponse/locale=${showLang}`,
              responseData: responseData,
              reportNumber: this.state.reportNumber,
              pin: this.state.secword,
              LanguageData: localLanguageData
            });
          } else {
            this.setState({
              errorData: localLanguageData.invalidCredentials,
              disableButton: false
            });
          }
        },
        (error) => {
          this.setState({
            errorData: localLanguageData.invalidCredentials,
            disableButton: false
          });
        }
      );
  };
  render() {
    const { classes } = this.props;
    localLanguageData = isEqual(localLanguageData, "")
      ? selectedLanguage()
      : localLanguageData;
    return (
      <div className={classes.root} id="mainPage">
        <h1>{localLanguageData.ttReportFlwUp}</h1>
        <h4 style={{ marginTop: "10px", fontSize: "21px" }}>
          {localLanguageData.ttEnterReportNo}
        </h4>
        <TextField
          id="filled-full-width"
          style={{ margin: 8, width: "85%" }}
          name="reportNumber"
          label={localLanguageData.pReportNo}
          required
          margin="normal"
          variant="filled"
          onChange={this.handleChange}
          InputProps={{ classes: { input: this.props.classes["input"] } }}
          className={classes.textBox}
        />

        <div style={{ width: "100%" }}>
          <TextField
            id="filled-full-width"
            style={{ margin: 8, width: "85%" }}
            name="secword"
            label={localLanguageData.pPasswordtxt}
            type="password"
            required
            margin="normal"
            variant="filled"
            onKeyDown={this.entReport}
            onChange={this.handleChange}
            InputProps={{ classes: { input: this.props.classes["input"] } }}
            className={classes.textBox}
          />
        </div>

        <span style={{ color: "red" }}>{this.state.errorData}</span>
        <div className={classes.forgetLink}>
          <div onClick={this.handlesecword}>{localLanguageData.forgotPd}?</div>
        </div>
        <div className={classes.buttonStyle}>
          <Button
            variant="contained"
            className={classes.buttonText}
            onClick={this.handleSubmit}
            type="submit"
            disabled={this.state.disableButton}
          >
            {localLanguageData.btnFindReport}
          </Button>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(withRouter(ReportFollowupComponent));

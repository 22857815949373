import { isEqual } from "lodash";
import english from "./langData/englishData";
import arabic from "./langData/arabicData";
import bengali from "./langData/bengaliData";
import japanese from "./langData/japaneseData";
import spanish from "./langData/spanishData";
import chinese from "./langData/chineseData";
import portuguese from "./langData/portuguese";
import hebrew from "./langData/hebrewData";
import french from "./langData/frenchData";
import hindi from "./langData/hindiData";
import indonesian from "./langData/indonesianData";
import korean from "./langData/koreanData";
import punjabi from "./langData/punjabiData";
import urdu from "./langData/urduData";
let langData = "";

export function selectedLanguage() {

    if (!isEqual(window.location.href.indexOf("locale=en"), -1)) {
      langData = english;
    } else if (!isEqual(window.location.href.indexOf("locale=ar"), -1)) {
      langData = arabic;
    } else if (!isEqual(window.location.href.indexOf("locale=bn"), -1)) {
      langData = bengali;
    } else if (!isEqual(window.location.href.indexOf("locale=jp"), -1)) {
      langData = japanese;
    } else if (!isEqual(window.location.href.indexOf("locale=es"), -1)) {
      langData = spanish;
    } else if (!isEqual(window.location.href.indexOf("locale=zn"), -1)) {
      langData = chinese;
    } else if (!isEqual(window.location.href.indexOf("locale=fr"), -1)) {
      langData = french;
    } else if (!isEqual(window.location.href.indexOf("locale=hi"), -1)) {
      langData = hindi;
    } else if (!isEqual(window.location.href.indexOf("locale=id"), -1)) {
      langData = indonesian;
    } else if (!isEqual(window.location.href.indexOf("locale=ko"), -1)) {
      langData = korean;
    } else if (!isEqual(window.location.href.indexOf("locale=pt"), -1)) {
      langData = portuguese;
    } else if (!isEqual(window.location.href.indexOf("locale=pa"), -1)) {
      langData = punjabi;
    } else if (!isEqual(window.location.href.indexOf("locale=ur"), -1)) {
      langData = urdu;
    } else if (!isEqual(window.location.href.indexOf("locale=iw"), -1)) {
      langData = hebrew;
    }
  
  if (langData === "") {
    langData = english;
  }
  return langData;
}

import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import EthicsForm from "./components/review-page/index";
import ReviewReportComponent from "./components/report-review/reviewreport";
import ReportConfirmComponent from "./components/report-confirmation/reportconfirm";
import ReportFollowupComponent from "./components/report-followup/reportfollowup";
import FollowupResponseComponent from "./components/followup-response/followupresponse";
import FollowupReviewComponent from "./components/followup-review/followupreview";
import FollowupConfirmationComponent from "./components/followup-confirmation/followupconfirmation";
import ResetPasswordComponent from "./components/reset-password/resetpassword";
import HealthCheckComponent from "./healthcheck";
import MaintenancePage from "./components/maintenance-page/maintenance-page";

class RouterStack extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/maintenance_page">
              <MaintenancePage />
            </Route>
            <Route
              exact
              path="/api/healthcheck"
              render={(props) => (
                <HealthCheckComponent appData={this.props.appData} />
              )}
            />
            <Route
              exact
              path="/review/:locale?"
              render={(props) => (
                <ReviewReportComponent appData={this.props.appData} />
              )}
            />
            <Route
              exact
              path="/report/:locale?"
              render={(props) => <EthicsForm appData={this.props.appData} />}
            />
            <Route
              exact
              path="/:locale?"
              render={(props) => <EthicsForm appData={this.props.appData} />}
            />
            <Route
              exact
              path="/confirm/:locale?"
              render={(props) => (
                <ReportConfirmComponent appData={this.props.appData} />
              )}
            />
            <Route
              exact
              path="/followup/:locale?"
              render={(props) => (
                <ReportFollowupComponent appData={this.props.appData} />
              )}
            />
            <Route
              exact
              path="/followupresponse/:locale?"
              render={(props) => (
                <FollowupResponseComponent appData={this.props.appData} />
              )}
            />
            <Route
              exact
              path="/followupreview/:locale?"
              render={(props) => (
                <FollowupReviewComponent appData={this.props.appData} />
              )}
            />
            <Route
              exact
              path="/followupconfirmation/:locale?"
              render={(props) => (
                <FollowupConfirmationComponent appData={this.props.appData} />
              )}
            />
            <Route
              exact
              path="/resetpassword/:locale?"
              render={(props) => (
                <ResetPasswordComponent appData={this.props.appData} />
              )}
            />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default RouterStack;

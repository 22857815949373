import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import RouterStack from "./router";

serviceWorker.unregister();
ReactDOM.render(<RouterStack />, document.getElementById('root'));
    





//Data for Portuguese language Start
const portuguese = {
  additionalInfo: "Informações adicionais",
  forgotPd: "Esqueci a senha",
  daysAgo: " dias atrás",
  ttPeopleInvolved: "Pessoas envolvidas",
  resetPassSuc: "Redefinição de senha bem-sucedida. Sua nova senha é ",
  clickHere: "Clique aqui para ",
  tfollowUp: "Acompanhar",
  tyourCase: "seu caso. ",
  msgSendFailed: "Falha ao enviar mensagem",
  msgSent: "Sua mensagem foi enviada com êxito",
  invalidCredentials: "Número de caso/Senha inválido(a)",
  enterRepNo: "Insira o seu número de relatório",
  resetError:
    "Segunda redefinição proibida num prazo de 24 horas. Tente novamente amanhã ou envie um e-mail para Ethics@Wal-mart.com caso você tenha informações adicionais a fornecer. É necessário incluir o número de caso de Ética na linha de assunto do e-mail.",
  followUpTxt:
    "Digite o número e a senha do relatório para acompanhar o andamento do caso", // report follow up,
  //report home page
  emergencyNote: `Este site não deve ser usado em caso de assuntos emergenciais que precisem de notificação verbal imediata.
  <br> Se for um assunto emergencial, procure os serviços locais de atendimento de emergência.`,
  understand: "Eu compreendo",
  ttWhatHappened: "O que aconteceu?",
  lblIncidentDetails: "Detalhes do incidente",
  ttWhereHappened: "Onde aconteceu?",
  pSelectLocation: "Selecione a localização dentre as opções do menu",
  lblCountry: "País",
  lblState: "Estado",
  lblCity: "Cidade",
  lblLocation: "Localização",
  phMissingPlace:
    "Caso não tenha encontrado a localização acima, digite-a aqui",
  requestInfo: "Informe os seguintes dados relativos à sua preocupação",
  liDateofSituation: "Data e hora da situação",
  liAssociatesInvovled:
    "Nomes dos associados envolvidos, incluindo aqueles que podem ter conhecimento ou testemunhado a situação que você está descrevendo",
  liSituationOccured: "O local em que ocorreu a situação",
  liReportedAnyone:
    "Você relatou sua preocupação para alguém da empresa? Se afirmativo, para quem e quando?",
  spIfNoLocation:
    "Somente necessário se a localização não foi encontrada acima",
  ttWhoInvolved: "Quem estava envolvido?",
  pOtherPerson: "Forneça os nomes de outras pessoas envolvidas no incidente",
  btnAddPerson: "Acrescentar outro",
  ttContactInfo: "Dados de contato",
  pAnonymous: "Você gostaria de permanecer anônimo?",
  btnReviewForm: "Revisar o formulário",
  //details page - child component of report home page
  phFirstName: "Nome",
  phLastName: "Sobrenome",
  lblJobTitle: "Cargo",
  IncidentRole: "Participação no incidente",
  btnRemove: "Eliminar",
  phPhoneNo: "Telefone",
  phEmail: "E-mail",
  valEmail: "Por favor digite um e-mail válido",

  // Report Review
  ttReportReview: "Revisão do relatório",
  ttConfirmDetails: "Confirme os detalhes do relatório e crie uma Senha",
  ttIncidentDescription: "Detalhes do incidente",
  ttIncidentDetails: "Descrição do incidente",
  ttIncidentLocation: "Local do incidente",
  pImAnonymous: "Anônimo",
  btnEditReport: "EDITAR O RELATÓRIO",
  fhtPasswordDesc:
    "A senha será usada para acompanhar o andamento do seu relatório",
  pPasswordNote: `   IMPORTANTE: Você precisa do número e senha do seu relatório para entrar na página de acompanhamento e verificar o status do seu relatório, ou para inserir informações adicionais. A fim de proteger sua privacidade, estas informações não poderão ser recuperadas posteriormente. Não enviamos respostas ou mensagens de acompanhamento diretamente para o seu e-mail.`,
  btnCompleteReport: "CONCLUIR O RELATÓRIO",

  // follow up confirmation

  ttReportNo: "Confirmação de Acompanhamento do relatório",
  ttAdditionalInfo: ` Agradecemos pelas informações adicionais relativas à sua preocupação ética. Nossa equipe irá revisar sua resposta e talvez use a página Acompanhar Relatório para solicitar mais informações ou dados. Por isso pedimos que você verifique o site regularmente.`,
  // report confirm

  hReport: "Relatório",
  hCreated: "criado",
  ttThanksforReport: "Obrigado por nos enviar sua preocupação ética.",
  progressMsg:
    "Estamos em processo de criação da sua Preocupação Ética. Por favor, seja paciente..",
  ttFollowUpDet: "Detalhes do acompanhamento",
  pReportReview: `Nosso Departamento de Ética Global irá revisar seu relatório e apresentar uma resposta inicial, <br/> no prazo de 3 a 5 dias úteis, por meio da nossa página Acompanhar Relatório. `,
  pAdditionalInforReq: ` Nossa equipe talvez use a página Acompanhar Relatório para solicitar mais informações ou dados.<br /> Por isso pedimos que você verifique o site regularmente.
`,
  pNeedPd: `Você precisará anotar a senha e o número do relatório para que possa acompanhá-lo.<br /> A fim de proteger sua privacidade, estas informações não poderão ser recuperadas posteriormente.
  `,
  pReportNo: "Número do relatório",
  ttReportFlwUp: "Acompanhamento do relatório",
  ttEnterReportNo:
    "Digite o número e a senha do relatório para acompanhar o andamento do caso",
  btnFindReport: "Encontrar relatório",
  ttFollowReview: "Revisão do acompanhamento",
  ttConfirmDet: "Confirme os detalhes do relatório",
  ttAddDetRes: "Detalhes adicionais/Resposta",
  btnEdit: "EDITAR",
  btnSubmit: "ENVIAR",

  //Follow up reponse
  dReportDet: "Detalhes do relatório",
  sIncidentDesc: "Descrição do incidente",
  ttReportHistory: "Histórico do relatório",
  sRepSubOn: "Relatório enviado em",
  btnReview: "REVISAR",
  pCaseClosed:
    "  Este caso foi encerrado. Se você tiver outras preocupações, visite a página Denunciar Questão e faça o seu relato.",
  ComFrmEthics: "Comunicação do Departamento de Ética",
  Reporter: "Relator",
  System: "Sistema",
  //Reset Password,
  justNow: "Neste momento",
  fewMinutes: "há alguns minutos",
  hours: "há uma hora",
  month: "há um mês",
  year: "há um ano",
  yes: "Sim",
  start: "iniciar",
  end: "parar",
  no: "Não",
  genricError:
    "Pedimos que você use um outro método para entrar em contato conosco. Você pode enviar um e-mail para Ethics@Wal-mart.com. ",
  wrongAnswer: "Suas respostas não coincidem com seus dados. Tente novamente.",

  resetLimit:
    "A redefinição de senha fica proibida após três tentativas malsucedidas. Tente novamente após 15 minutos ou envie um e-mail para Ethics@Wal-mart.com caso você tenha informações adicionais para fornecer. Você deve incluir o número do seu caso de ética na linha de assunto do e-mail.",
  resetPasswordHeader: "Redefina a senha do número do relatório",
  answerTxt:
    "Responda as perguntas de segurança abaixo a fim de gerar uma nova senha (as respostas devem ser exatamente iguais, incluindo espaçamento e capitalização)",
  question1: "Pergunta 1",
  question2: "Pergunta 2",
  question3: "Pergunta 3",
  btnVerify: "Verificar",
  btnReset: "Redefinir",
  securtiyQuestionHdr:
    "Selecione três perguntas de segurança e responda-as a fim de gerar uma senha para abrir seu relatório",
  generateButton: "Gerar senha",
  pPasswordtxt: "Senha",
  SecurityQuestions: [
    {
      id: 1,
      question:
        "Em que cidade você conheceu seu primeiro cônjuge ou seu(sua) primeiro(a) companheiro(a)?"
    },
    { id: 2, question: "Em que cidade seu pai nasceu?" },
    { id: 3, question: "Em que cidade foi seu primeiro emprego?" },
    { id: 4, question: "Em que cidade sua mãe nasceu?" },
    {
      id: 5,
      question: "Qual o sobrenome da sua professora favorita no ensino médio?"
    },
    {
      id: 6,
      question:
        "Qual o nome de uma universidade em que você se inscreveu mas não cursou?"
    },
    { id: 7, question: "Qual o nome do meio do seu pai?" },
    { id: 8, question: "Qual o nome do meio da sua mãe?" },
    {
      id: 9,
      question:
        "Qual o mês e ano de nascimento do seu irmão mais velho? (ex.: novembro de 1985)"
    },
    { id: 10, question: "Qual o nome do meio do seu irmão mais velho?" },
    { id: 11, question: "Qual o nome do meio do seu irmão mais novo?" },
    { id: 12, question: "Em que escola você estudou no sexto ano?" },
    {
      id: 13,
      question: "Qual o nome do seu melhor amigo de infância?"
    }
  ],
  roleIncidentOptions: [
    { value: "subject", label: "Assunto" },
    { value: "witness", label: "Testemunha" },
    { value: "affected_individual", label: "Indivíduo afetado" },
    { value: "other", label: "Outro" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "Executivo" },
    { value: "management", label: "Gerência" },
    { value: "hourly_associate", label: "Associado horista" },
    { value: "vendor_supplier", label: "Vendor/Fornecedor" },
    { value: "customer", label: "Cliente" },
    { value: "other", label: "Outro" }
  ]
};

export default portuguese;
n
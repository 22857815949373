import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import "./reportconfirm.css";
import { selectedLanguage } from "../../lang-config";
import { isEqual } from "lodash";
import { currentLang } from "../../helper";
import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);
const showLang = currentLang();
let localLanguageData = "";
const styles = (theme) => ({
  root: {
    margin: "0 auto",
    width: "65%"
  },

  data: {
    backgroundColor: "#c2d3da",
    paddingLeft: "25px",
    borderRadius: "10px",
    boxShadow: "0 0 14px #3c505080"
  }
});

class ReportConfirmComponent extends Component {
  constructor(props) {
    super(props);
    if (this.props.reportNumber && this.props.reportNumber !== undefined) {
      this.props.location.reportNumber = this.props.reportNumber;
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location && this.props.location.reportNumber === undefined) {
      this.props.history.push({
        pathname: `/locale=${showLang}`
      });
    }
    const parent_origin =
      window.location !== window.parent.location
        ? document.referrer !== ""
          ? document.referrer
          : "*"
        : document.location.href;
    this.myScrollToTop(parent_origin);
  }
  myScrollToTop = (parent_origin) => {
    window.parent.postMessage("scrollToTop", parent_origin);
  };
  render() {
    const { classes } = this.props;
    const LanguageData =
      this.props.location.LanguageData &&
      JSON.parse(
        DOMPurify.sanitize(JSON.stringify(this.props.location.LanguageData))
      );
    localLanguageData =
      isEqual(LanguageData, "") || isEqual(LanguageData, undefined)
        ? selectedLanguage()
        : LanguageData;
    return (
      <div className={classes.root} id="mainPage">
        <h1>
          {" "}
          {localLanguageData.hReport} :{" "}
          {" " + this.props.location.reportNumber + " "}{" "}
          {localLanguageData.hCreated}{" "}
        </h1>
        <h4 style={{ marginTop: "10px" }}>
          {localLanguageData.ttThanksforReport}
        </h4>
        <h2> {localLanguageData.ttFollowUpDet} </h2>

        <p
          dangerouslySetInnerHTML={{ __html: localLanguageData.pReportReview }}
        ></p>
        <p
          dangerouslySetInnerHTML={{
            __html: localLanguageData.pAdditionalInforReq
          }}
        />
        <p dangerouslySetInnerHTML={{ __html: localLanguageData.pNeedPd }} />
        <p>
          {localLanguageData.pReportNo}:{" "}
          <span style={{ fontWeight: "bold" }}>
            {" " + this.props.location.reportNumber}
          </span>
        </p>
        <p>
          {localLanguageData.pPasswordtxt}:{" "}
          <span style={{ fontWeight: "bold" }}>
            {" " + this.props.location.secword}
          </span>
        </p>
      </div>
    );
  }
}
export default withStyles(styles)(withRouter(ReportConfirmComponent));

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { selectedLanguage } from "../../lang-config";
import { isEqual } from "lodash";
import { currentLang } from "../../helper";
import createDOMPurify from "dompurify";
import "./followupresponse.css";

const showLang = currentLang();
const DOMPurify = createDOMPurify(window);
let localLanguageData = "";
const styles = (theme) => ({
  root: {
    margin: "30px auto",
    width: "75%"
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#0071CE !important",
    width: "180px",
    height: "40px"
  },
  buttonStyle: {
    width: "180px",
    marginTop: "24px",
    height: "40px",
    textAlign: "center",
    paddingTop: "5px",
    borderRadius: "8px !important",
    color: "white"
  }
});

export const EthicsCommunication = (props) => {
  localLanguageData = selectedLanguage();
  const txt = props.props;
  return (
    <React.Fragment>
      <div className="custom-user-section"></div>
      <div className="mainDiv">
        <div className="timeline__item__inner">
          <div className="timeline__content__wrap">
            <div className="timeline__content">
              <h3 className="lineHeight" style={{ marginBottom: "1px" }}>
                {localLanguageData.ComFrmEthics}
              </h3>
              <div
                className="lineHeight"
                style={{ fontSize: "14px", marginTop: "0px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                >
                  <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                </svg>{" "}
                <p
                  style={{
                    marginTop: "-24px",
                    marginLeft: "20px"
                  }}
                >
                  {" "}
                  <TimeSince props={txt.created_on} />
                </p>
              </div>{" "}
              <div className="textMessage"> {txt.message}</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export const User = (props) => {
  localLanguageData = selectedLanguage();
  const txt = props.props;
  return (
    <React.Fragment>
      <div className="custom-dp-section "></div>
      <div className="mainDiv">
        <div className="timeline__item__inner">
          <div className="timeline__content__wrap">
            <div className="timeline__content">
              <h3 className="lineHeight" style={{ marginBottom: "1px" }}>
                {localLanguageData.Reporter}
              </h3>
              <div
                className="lineHeight"
                style={{ fontSize: "14px", marginTop: "0px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                >
                  <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                </svg>{" "}
                <p
                  style={{
                    marginTop: "-24px",
                    marginLeft: "20px"
                  }}
                >
                  {" "}
                  <TimeSince props={txt.created_on} />
                </p>
              </div>{" "}
              <div className="textMessage"> {txt.message}</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const System = (props) => {
  const txt = props.props;
  localLanguageData = selectedLanguage();
  return (
    <React.Fragment>
      <div className="custom-user-section"></div>
      <div className="mainDiv">
        <div className="timeline__item__inner">
          <div className="timeline__content__wrap">
            <div className="timeline__content">
              <h3 className="lineHeight" style={{ marginBottom: "1px" }}>
                {localLanguageData.System}
              </h3>
              <div
                className="lineHeight"
                style={{ fontSize: "14px", marginTop: "0px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                >
                  <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                </svg>{" "}
                <p
                  style={{
                    marginTop: "-24px",
                    marginLeft: "20px"
                  }}
                >
                  {" "}
                  <TimeSince props={txt[0].created_on} />
                </p>
              </div>{" "}
              <div className="textMessage">
                {" "}
                {localLanguageData.hReport}: {txt[0].reportNumber}{" "}
                {localLanguageData.hCreated}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export const Start = () => {
  localLanguageData = selectedLanguage();
  return <div className="custom-start-section"> {localLanguageData.start}</div>;
};
export const End = () => {
  localLanguageData = selectedLanguage();
  return <div className="custom-end-section">{localLanguageData.end}</div>;
};
const convertDateToUTC = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};
export const TimeSince = (props) => {
  localLanguageData = selectedLanguage();
  let date = new Date(props.props);

  let seconds = Math.floor((convertDateToUTC(new Date()) - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + localLanguageData.year;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + localLanguageData.month;
  } else if (interval === 1) {
    return "1" + localLanguageData.month;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + localLanguageData.daysAgo;
  } else if (interval === 1) {
    return "1 " + localLanguageData.daysAgo;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + localLanguageData.hours;
  } else if (interval === 1) {
    return localLanguageData.hours;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return localLanguageData.fewMinutes;
  }
  return localLanguageData.justNow;
};
class FollowupResponseComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      additionalInfo: "",
      pageData: this.props.location.responseData
        ? this.props.location.responseData
        : this.props.responseData
        ? this.props.responseData
        : "",
      responseData: "",
      errorData: "",
      successData: "",
      displayMenu: true
    };
    if (this.props.responseData && this.props.responseData !== undefined) {
      this.props.location.responseData = this.props.responseData;
    }
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);

    const { LanguageData } =
      this.props &&
      this.props.location &&
      this.props.location !== undefined &&
      this.props.location !== "" &&
      this.props.location.LanguageData
        ? this.props.location.LanguageData
        : "";
    localLanguageData =
      isEqual(LanguageData, "") || isEqual(LanguageData, undefined)
        ? selectedLanguage()
        : LanguageData;

    if (
      this.props.location &&
      this.props.location.responseData === undefined &&
      (this.props.location.LanguageData === undefined ||
        this.props.location.LanguageData === null)
    ) {
      this.props.history.push({
        pathname: `/followup/locale=${showLang}`
      });
    }
  }
  hideDropdownMenu = async (e) => {
    await this.setState({ displayMenu: !this.state.displayMenu });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (
      this.props.location &&
      this.props.location.responseData === undefined &&
      (this.props.location.LanguageData === undefined ||
        this.props.location.LanguageData === null)
    ) {
      this.props.history.push({
        pathname: `/followup/locale=${showLang}`
      });
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.additionalInfo &&
      this.props.location.additionalInfo != null
    ) {
      this.setState({
        additionalInfo: this.props.location.additionalInfo,
        pageData: this.props.location.responseData
      });
    }
    const parent_origin =
      window.location !== window.parent.location
        ? document.referrer !== ""
          ? document.referrer
          : "*"
        : document.location.href;
    this.myScrollToTop(parent_origin);
  }
  myScrollToTop = (parent_origin) => {
    window.parent.postMessage("scrollToTop", parent_origin);
  };
  handleReview = () => {
    if (
      this.state.additionalInfo !== "" &&
      this.state.additionalInfo !== undefined
    ) {
      this.props.history.push({
        pathname: `/followupreview/locale=${showLang}`,
        pageData: this.state.pageData,
        additionalInfo: this.state.additionalInfo,
        reportNumber: this.props.location.reportNumber,
        pin: this.props.location.pin,
        LanguageData: localLanguageData
      });
    }
  };
  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
      errorData: ""
    });
  };
  getBoxClassNames = () => {
    return this.state.displayMenu ? "button" : "button down";
  };
  CommentSection = () => {
    localLanguageData = selectedLanguage();
    let test = [];
    const data = this.state.pageData ? this.state.pageData.data.comments : "";
    if (data !== "") {
      if (
        this.state.pageData &&
        (this.state.pageData !== undefined || this.state.pageData !== null) &&
        this.state.pageData.data.active_case &&
        this.state.pageData.data.active_case === "false"
      ) {
        test.push(
          <div key="1003">
            <End />
          </div>
        );
      }
      let sortedData = data
        .sort(
          (a, b) =>
            new Date(a.created_on).getTime() - new Date(b.created_on).getTime()
        )
        .reverse();
      sortedData.forEach(function (element, index) {
        if (element.type === "Reply Back From The Reporter") {
          test.push(
            <div
              className="timeline__item timeline__item--left fadeIn"
              key={index}
            >
              {" "}
              <User props={element} />{" "}
            </div>
          );
        } else if (element.type === "Communication from Ethics") {
          test.push(
            <div
              className="timeline__item timeline__item--right fadeIn"
              key={index}
            >
              <EthicsCommunication props={element} />
            </div>
          );
        }
      });
      if (
        this.state.pageData &&
        (this.state.pageData !== undefined || this.state.pageData !== null)
      ) {
        let elementData = [];
        elementData.push({
          reportNumber: this.props.location.reportNumber,
          created_on: this.state.pageData.data.sys_created_on
        });
        test.push(
          <div
            className="timeline__item timeline__item--right fadeIn"
            key="0101"
          >
            <System props={elementData} />{" "}
          </div>
        );
      }
      test.push(
        <div key="1002">
          <Start />{" "}
        </div>
      );
    }
    return test;
  };

  render() {
    const LanguageData =
      this.props.location.LanguageData &&
      JSON.parse(
        DOMPurify.sanitize(JSON.stringify(this.props.location.LanguageData))
      );

    localLanguageData =
      isEqual(LanguageData, "") || isEqual(LanguageData, undefined)
        ? selectedLanguage()
        : LanguageData;
    const { classes } = this.props;
    let createdDate = "";

    if (
      this.state.pageData &&
      this.state.pageData.data &&
      this.state.pageData.data.sys_created_on
    ) {
      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      let now = new Date(this.state.pageData.data.sys_created_on);

      createdDate =
        months[now.getMonth()] + " " + now.getDate() + "," + now.getFullYear();
    }

    return (
      <div className={classes.root} id="mainPage">
        <h1>
          {localLanguageData.hReport}: {this.props.location.reportNumber}
        </h1>
        <br />
        <div
          className="dropdown"
          style={{
            background: "#0071CE",
            width: "100%",
            borderRadius: "5px 5px 0px 0px",
            height: "60px",
            cursor: "pointer"
          }}
        >
          <div
            className={this.getBoxClassNames()}
            onClick={this.hideDropdownMenu}
            style={{ color: "white" }}
          >
            {" "}
            {localLanguageData.dReportDet}
          </div>

          {this.state.displayMenu &&
          this.state.pageData &&
          (this.state.pageData !== undefined ||
            this.state.pageData !== null) ? (
            <div className="content">
              <span style={{ marginTop: "30px", marginLeft: "20px" }}>
                <span style={{ fontSize: "19px" }}>
                  {localLanguageData.sIncidentDesc}:
                </span>
                <br />
                <p
                  style={{ marginLeft: "40px", marginBottom: "0px" }}
                  className="followup_desc"
                >
                  {this.state.pageData.data.issue_detail}
                </p>
                <br />
                <span style={{ fontSize: "19px", marginLeft: "20px" }}>
                  {localLanguageData.ttIncidentLocation}:{" "}
                </span>
                <br />
                <p style={{ marginLeft: "40px" }}>
                  {this.state.pageData.data.country !== "" ? (
                    <React.Fragment>
                      {localLanguageData.lblCountry}:
                      {this.state.pageData.data.country}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {this.state.pageData.data.state_province !== "" ? (
                    <React.Fragment>
                      {" "}
                      {localLanguageData.lblState}:{" "}
                      {this.state.pageData.data.state_province}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {this.state.pageData.data.city !== "" ? (
                    <React.Fragment>
                      {" "}
                      {localLanguageData.lblCity}:{" "}
                      {this.state.pageData.data.city}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </p>
                {createdDate !== "" ? (
                  <span style={{ fontSize: "19px", marginLeft: "20px" }}>
                    {localLanguageData.sRepSubOn}:{" "}
                    <p style={{ fontSize: "16px", marginLeft: "40px" }}>
                      {createdDate}{" "}
                    </p>{" "}
                  </span>
                ) : (
                  ""
                )}
                <br />
              </span>
            </div>
          ) : null}
        </div>
        <br />
        <h2> {localLanguageData.ttReportHistory} </h2>
        {/*this.state.pageData &&
        (this.state.pageData !== undefined || this.state.pageData !== null) &&
        this.state.pageData.data.active_case &&
        this.state.pageData.data.active_case === "true" ? (*/}
        <div className="followup_tree">
          <div className="followup_div">
            <TextField
              id="filled"
              style={{ margin: 8, width: "95%" }}
              name={"additionalInfo"}
              value={this.state.additionalInfo}
              label={localLanguageData.additionalInfo}
              hiddenLabel
              margin="normal"
              variant="filled"
              helperText={`${this.state.additionalInfo.length}/4000`}
              InputProps={{ classes: { input: this.props.classes["input"] } }}
              inputProps={{
                maxLength: 4000
              }}
              onChange={this.handleChange}
            />
          </div>
          <div className={classes.buttonStyle} id="followup_button">
            <Button
              variant="contained"
              className={classes.buttonText}
              onClick={this.handleReview}
            >
              {localLanguageData.btnReview}
            </Button>
          </div>
        </div>
        {/*) : (
          <p>{localLanguageData.pCaseClosed}</p>
        )*/}

        <div
          className="timeline timeline--loaded"
          id="timeline-vertical"
          style={{ marginTop: "60px", opacity: 1 }}
        >
          <div className="timeline__wrap">
            <div className="timeline__items">{this.CommentSection()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(FollowupResponseComponent));

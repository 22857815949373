//Data for indonesian language Start
const indonesian = {
  msgSendFailed: "Gagal mengirim pesan",
  ttPeopleInvolved: "Orang-orang yang Terlibat",
  msgSent: "Pesan Anda berhasil dikirim",
  invalidCredentials: "Nomor kasus/Kata sandi tidak sah",
  enterRepNo: "Masukkan nomor laporan Anda",
  followUpTxt:
    "Masukkan nomor dan kata sandi laporan untuk menindaklanjuti kasus", // report follow up,
  //report home page
  emergencyNote: `Situs web ini tidak seharusnya digunakan untuk masalah darurat yang memerlukan notifikasi lisan segera. Dalam hal kedaruratan, hubungilah dinas kedaruratan setempat.`,
  understand: "Saya memahami",
  ttWhatHappened: "Apakah yang Terjadi?",
  lblIncidentDetails: "Rincian insiden",
  ttWhereHappened: "Di manakah hal itu terjadi?",
  pSelectLocation: "Pilih lokasi dari opsi menu",
  lblCountry: "Negara",
  lblState: "Negara Bagian/Provinsi",
  lblCity: "Kota",
  lblLocation: "Lokasi",
  phMissingPlace: "Jika lokasi tidak ditemukan di atas, masukkan di sini",
  requestInfo: "Berikan informasi berikut mengenai kerisauan Anda",
  liDateofSituation: "Tanggal/Waktu situasi",
  liAssociatesInvovled:
    "Nama-nama rekan kerja yang terlibat, yang mencakup mereka yang mungkin mengetahui atau menyaksikan situasi yang Anda jelaskan",
  liSituationOccured: "Lokasi tempat situasi terjadi",
  liReportedAnyone:
    "Sudahkah Anda melaporkan kerisauan Anda kepada siapa pun di perusahaan? Jika sudah, kepada siapa dan kapankah?",
  spIfNoLocation: "Diperlukan hanya jika lokasi tidak ditemukan di atas",
  ttWhoInvolved: "Siapakah yang terlibat?",
  pOtherPerson: "Berikan nama-nama orang lain yang terlibat dalam insiden",
  btnAddPerson: "Tambahkan yang lain",
  ttContactInfo: "Informasi Kontak",
  pAnonymous: "Apakah Anda tetap ingin merahasiakan nama (anonim)?",
  btnReviewForm: "Tinjau Formulir",
  //details page - child component of report home page
  phFirstName: "Nama Depan",
  phLastName: "Nama Belakang",
  lblJobTitle: "Jabatan Pekerjaan",
  IncidentRole: "Peran dalam Insiden",
  btnRemove: "Hapus",
  phPhoneNo: "Nomor Telepon",
  phEmail: "Surel",
  valEmail: "Masukkan surel yang benar",

  // Report Review
  ttReportReview: "Tinjau Laporan",
  ttConfirmDetails: "Pastikan rincian laporan dan buat kata sandi",
  ttIncidentDetails: "Rincian Insiden",
  ttIncidentDescription: "Uraian Insiden",
  ttIncidentLocation: "Lokasi Insiden",
  pImAnonymous: "Anonim",
  btnEditReport: "EDIT LAPORAN",
  fhtPasswordDesc:
    "Kata sandi akan digunakan untuk menindaklanjuti laporan Anda",
  pPasswordNote: `  PENTING: Anda memerlukan nomor dan kata sandi laporan untuk masuk ke halaman tindak lanjut guna melihat status laporan Anda, atau memberikan informasi tambahan. Untuk melindungi privasi Anda, informasi ini tidak dapat diambil lagi di kemudian hari. Kami tidak menanggapi atau menindaklanjuti secara langsung ke alamat surel Anda.`,
  btnCompleteReport: "LENGKAPI LAPORAN",

  // follow up confirmation
  ttReportNo: "Penegasan Tindak Lanjut untuk laporan",
  ttAdditionalInfo: ` Terima kasih atas penyediaan informasi tambahan tentang kerisauan etika Anda. Tim kami akan meninjau tanggapan Anda dan dapat menggunakan halaman Tindak Lanjut Laporan untuk meminta informasi atau rincian tambahan. Jadi, periksalah situs secara teratur.
    `,

  // report confirm
  hReport: "Laporan",
  hCreated: "dibuat",
  ttThanksforReport: "Terima kasih atas pengajuan kerisauan etika Anda",
  progressMsg:
    "Kami sedang dalam proses membuat Kepedulian Etika Anda. Harap bersabar..",
  ttFollowUpDet: "Rincian Tindak Lanjut",
  pReportReview: `Kantor Etika Global kami akan meninjau ajuan Anda dan memberikan tanggapan awal dalam 3-5 hari kerja melalui halaman Tindak Lanjut Laporan.`,
  pAdditionalInforReq: ` Tim kami dapat menggunakan halaman Tindak Lanjut Laporan untuk meminta informasi atau rincian tambahan. Jadi, periksalah situs secara teratur`,
  pNeedPd: `Anda perlu mencatat kata sandi dan nomor laporan guna menindaklanjuti laporan ini. Untuk melindungi privasi Anda, informasi ini tidak dapat diambil lagi di kemudian hari`,
  pReportNo: "Nomor Laporan",
  ttReportFlwUp: "Tindak Lanjut Laporan",
  ttEnterReportNo:
    "Masukkan nomor dan kata sandi laporan untuk menindaklanjuti kasus",
  btnFindReport: "Temukan Laporan",
  ttFollowReview: "Tinjauan Tindak Lanjut",
  ttConfirmDet: "Pastikan rincian laporan",
  ttAddDetRes: "Rincian/Tanggapan Tambahan",
  btnEdit: "EDIT",
  btnSubmit: "AJUKAN",

  //Follow up reponse
  dReportDet: "Rincian Laporan",
  sIncidentDesc: "Uraian Insiden",
  ttReportHistory: "Riwayat Laporan",
  sRepSubOn: "Laporan diajukan pada",
  btnReview: "TINJAU",
  pCaseClosed:
    "  Kasus ini sudah ditutup. Jika Anda mempunyai kerisauan lain, kunjungi halaman laporkan kerisauan untuk melaporkan kerisauan Anda",
  ComFrmEthics: "Komunikasi dari Bagian Etika",
  Reporter: "Pelapor",
  System: "Sistem",
  resetPassSuc: "Reset kata sandi berhasil. Kata sandi baru Anda adalah ",
  clickHere: "Klik di sini untuk ",
  tfollowUp: "Tindak lanjut",
  tyourCase: "kasus Anda. ",
  //Reset Password,
  justNow: "Baru saja",
  fewMinutes: "beberapa menit yang lalu",
  hours: "jam yang lalu",
  month: "bulan yang lalu",
  year: "tahun yang lalu",
  additionalInfo: "Info Tambahan",
  forgotPd: "Lupa Kata Sandi",
  daysAgo: " hari yang lalu",
  yes: "Ya",
  start: "mulai",
  end: "Akhiri",
  no: "Tidak",
  genricError:
    "Gunakanlah cara alternatif untuk menghubungi kami. Anda bisa mengirim surel ke Ethics@Wal-mart.com. ",
  wrongAnswer: "Jawaban Anda tidak sama dengan data kami. Cobalah lagi.",
  resetError:
    "Reset kedua tidak dibolehkan dalam jangka waktu 24 jam. Cobalah lagi besok atau kirim email kepada Ethics@Wal-mart.com jika Anda ingin memberikan informasi tambahan. Anda harus mencantumkan nomor kasus Ethics dalam baris subjek email.",
  resetLimit:
    "Reset terlarang setelah tiga upaya gagal. Cobalah lagi dalam 15 menit, atau kirim surel ke Ethics@Wal-mart.com jika ada informasi tambahan yang ingin Anda berikan. Anda harus menyertakan nomor kasus Etika Anda di baris perihal surel.",
  resetPasswordHeader: "Reset kata sandi untuk nomor laporan",
  answerTxt:
    "Jawablah pertanyaan keamanan di bawah untuk menghasilkan kata sandi baru (Jawaban harus sama persis, yang mencakup spasi & huruf besar)",
  question1: "Pertanyaan 1",
  question2: "Pertanyaan 2",
  question3: "Pertanyaan 3",
  btnVerify: "Verifikasikan",
  btnReset: "Reset",
  securtiyQuestionHdr:
    "Pilihlah tiga pertanyaan keamanan dan jawablah untuk menghasilkan kata sandi:",
  generateButton: "Hasilkan Kata Sandi",
  pPasswordtxt: "Kata Sandi",
  SecurityQuestions: [
    {
      id: 1,
      question:
        "Di kota manakah Anda berjumpa dengan suami/istri/pasangan pertama Anda?"
    },
    { id: 2, question: "Di kota manakah ayah Anda dilahirkan?" },
    {
      id: 3,
      question: "Di kota manakah Anda mendapatkan pekerjaan pertama Anda?"
    },
    { id: 4, question: "Di kota manakah ibu Anda dilahirkan?" },
    {
      id: 5,
      question: "Apakah nama belakang guru favorit Anda di SMA?"
    },
    {
      id: 6,
      question:
        "Apakah nama perguruan tinggi yang untuknya Anda mendaftar namun tidak Anda masuki?"
    },
    { id: 7, question: "Apakah nama tengah ayah Anda?" },
    { id: 8, question: "Apakah nama tengah ibu Anda?" },
    {
      id: 9,
      question:
        "Di bulan dan tahun berapakah hari lahir saudara sekandung tertua Anda? (misalnya November 1985)"
    },
    { id: 10, question: "Apakah nama tengah saudara sekandung tertua Anda?" },
    { id: 11, question: "Apakah nama tengah saudara sekandung termuda Anda?" },
    { id: 12, question: "Di sekolah manakah Anda belajar ketika kelas enam?" },
    {
      id: 13,
      question: "Apakah nama depan sahabat karib Anda di masa kanak-kanak?"
    }
  ],
  roleIncidentOptions: [
    { value: "subject", label: "Topik" },
    { value: "witness", label: "Saksi" },
    { value: "affected_individual", label: "Orang yang Terdampak" },
    { value: "other", label: "Lainnya" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "Staf" },
    { value: "management", label: "Manajemen" },
    { value: "hourly_associate", label: "Rekan Kerja Jam-jaman" },
    { value: "vendor_supplier", label: "Vendor/Pemasok" },
    { value: "customer", label: "Pelanggan" },
    { value: "other", label: "Lainnya" }
  ]
};

export default indonesian;
n
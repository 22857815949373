import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { isEqual } from "lodash";
import axios from "axios";
import "./resetpassword.css";
import { selectedLanguage } from "../../lang-config";
import { securityQuesConfig, internalAPI } from "../../config";
import { currentLang, RandomPdGen } from "../../helper";

import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

const showLang = currentLang();

const styles = (theme) => ({
  root: {
    margin: "30px auto",
    width: "75%"
  },
  questionTxt: {
    float: "left",
    //width: "450px",
    fontStyle: "italic"
  },
  qyTxt: {
    float: "left",
    width: "90px",
    fontWeight: "bold"
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#0071CE !important",
    width: "230px",
    height: "40px"
  },
  buttonStyle: {
    float: "right",
    marginTop: "30px",
    width: "230px",
    height: "40px",
    textAlign: "center",
    paddingTop: "5px",
    borderRadius: "8px !important",
    color: "white"
  },
  forgetLink: {
    textDecoration: "underline",
    width: "150px",
    cursor: "pointer",
    marginLeft: "8px"
  }
});
let localLanguageData;
class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answer1: "",
      disableButton: true,
      answer2: "",
      errorData: "",
      answer3: "",
      errorCount: 0,
      successData: "",
      enableSuccess: false
    };
    if (
      this.props.responseData &&
      this.props.responseData !== "" &&
      this.props.responseData !== undefined
    ) {
      this.props.location.responseData = this.props.responseData;
      this.props.location.reportNumber = this.props.reportNumber;
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location && this.props.location.responseData === undefined) {
      this.props.history.push({
        pathname: `/followup/locale=${showLang}`
      });
    }
    const parent_origin =
      window.location !== window.parent.location
        ? document.referrer !== ""
          ? document.referrer
          : "*"
        : document.location.href;
    this.myScrollToTop(parent_origin);
  }
  myScrollToTop = (parent_origin) => {
    window.parent.postMessage("scrollToTop", parent_origin);
  };
  getAllSecurityQuestions = (data) => {
    let securityQuesArr = [];
    const ActiveQuestId = securityQuesConfig
      .filter((x) => isEqual(x.active, true))
      .map((a) => a.id);
    data.forEach(function (i, v) {
      if (ActiveQuestId.indexOf(i.id) !== -1) {
        securityQuesArr.push(i);
      }
    });
    return securityQuesArr;
  };
  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
      errorData: ""
    });
    if (
      this.state.answer1 !== "" &&
      this.state.answer2 !== "" &&
      this.state.answer3 !== ""
    ) {
      await this.setState({ disableButton: false });
    }
  };
  handleVerify = () => {
    this.setState({ errorData: "" });
    let errorCount = this.state.errorCount;
    const questionData = this.props.location.responseData;
    // eslint-disable-next-line
    let securityQuestions =
      '[{"question":"' +
      questionData.data.u_security_questions[0] +
      '","answer":"' +
      this.state.answer1 +
      '"},{"question":"' +
      questionData.data.u_security_questions[1] +
      '","answer":"' +
      this.state.answer2 +
      '"},{"question":"' +
      questionData.data.u_security_questions[2] +
      '","answer":"' +
      this.state.answer3 +
      '"}]';

    axios
      .post(internalAPI.VerifyApiUrl, {
        number: this.props.location.reportNumber,
        u_security_questions: securityQuestions
      })
      .then(
        (res) => {
          let response = { data: res.data.response };
          if (
            response &&
            response.data &&
            response.data.correct_answers &&
            response.data.correct_answers === true
          ) {
            this.setState({ errorData: "", disableButton: true });
            this.handleUpdatePassword(response);
          } else {
            errorCount = errorCount + 1;
            this.setState({ errorCount: this.state.errorCount + 1 });
            if (errorCount === 3) {
              this.setState({
                disableButton: true,
                errorData: localLanguageData.resetLimit
              });
            } else {
              this.setState({
                disableButton: true,
                errorData: localLanguageData.wrongAnswer
              });
            }
          }
        },
        (error) => {
          errorCount = errorCount + 1;
          this.setState({ errorCount: this.state.errorCount + 1 });
          if (errorCount === 3) {
            this.setState({
              disableButton: true,
              errorData: localLanguageData.resetLimit
            });
          }
          this.setState({
            disableButton: true,
            errorData: localLanguageData.wrongAnswer
          });
        }
      );
  };

  handleUpdatePassword = (response) => {
    this.setState({ errorData: "" });

    const password = RandomPdGen();

    if (password !== "") {
      axios
        .post(internalAPI.UpdatePasswordApiUrl, {
          case_sys_id: response.data.case_sys_id,
          password: password
        })
        .then(
          (res) => {
            let response = { data: res.data.response };
            if (
              response &&
              response.data &&
              response.data !== "" &&
              response.data !== undefined
            ) {
              this.setState({
                errorData: "",
                enableSuccess: true,
                disableButton: true,
                successData: password
              });
            } else {
              this.setState({
                disableButton: true,
                enableSuccess: false,
                errorData: localLanguageData.resetError
              });
            }
          },
          (error) => {
            this.setState({
              disableButton: true,
              enableSuccess: false,
              errorData: localLanguageData.resetError
            });
          }
        );
    } else {
      this.setState({
        disableButton: true,
        enableSuccess: false,
        errorData: localLanguageData.genricError
      });
    }
  };
  handleFollowup = () => {
    this.props.history.push({
      pathname: `/followup/locale=${showLang}`
    });
  };
  render() {
    const { classes } = this.props;
    const LanguageData =
      this.props.location.LanguageData &&
      JSON.parse(
        DOMPurify.sanitize(JSON.stringify(this.props.location.LanguageData))
      );
    localLanguageData =
      isEqual(LanguageData, "") || isEqual(LanguageData, undefined)
        ? selectedLanguage()
        : LanguageData;
    const questionData =
      this.props.location.responseData &&
      JSON.parse(
        DOMPurify.sanitize(JSON.stringify(this.props.location.responseData))
      );
    const originalQuestonSets = this.getAllSecurityQuestions(
      localLanguageData.SecurityQuestions
    );
    let filtered = "";
    if (questionData !== "" && questionData !== undefined) {
      filtered = originalQuestonSets.filter(function (item) {
        return questionData.data.u_security_questions.indexOf(item.id) !== -1;
      });
    }

    return (
      <div className={classes.root} id="mainPage">
        <h2>
          {localLanguageData.resetPasswordHeader} :{" "}
          {this.props.location.reportNumber}
        </h2>
        <h4>{localLanguageData.answerTxt}</h4>
        {filtered.length === 3 ? (
          <>
            <div className={classes.questionDiv}>
              <div>
                {" "}
                <div className={classes.qyTxt}>
                  {" "}
                  {localLanguageData.question1}:{" "}
                </div>{" "}
                <div className={classes.questionTxt}>
                  {filtered !== undefined &&
                  filtered !== null &&
                  filtered.length === 3 &&
                  filtered[0].question !== undefined
                    ? filtered[0].question
                    : ""}
                </div>
                <TextField
                  id="filled-full-width"
                  className="textBox"
                  name="answer1"
                  label=""
                  required
                  value={this.state.answer1}
                  margin="normal"
                  variant="filled"
                  onChange={this.handleChange}
                  InputProps={{
                    classes: { input: this.props.classes["input"] }
                  }}
                />
              </div>
              <div>
                {" "}
                <div className={classes.qyTxt}>
                  {" "}
                  {localLanguageData.question2}:{" "}
                </div>{" "}
                <div className={classes.questionTxt}>
                  {filtered !== undefined &&
                  filtered !== null &&
                  filtered.length === 3 &&
                  filtered[1].question !== undefined
                    ? filtered[1].question
                    : ""}
                </div>
                <TextField
                  id="filled-full-width"
                  className="textBox"
                  name="answer2"
                  label=""
                  required
                  value={this.state.answer2}
                  margin="normal"
                  variant="filled"
                  onChange={this.handleChange}
                  InputProps={{
                    classes: { input: this.props.classes["input"] }
                  }}
                />
              </div>
              <div>
                {" "}
                <div className={classes.qyTxt}>
                  {" "}
                  {localLanguageData.question3}:{" "}
                </div>{" "}
                <div className={classes.questionTxt}>
                  {filtered !== undefined &&
                  filtered !== null &&
                  filtered.length === 3 &&
                  filtered[2].question !== undefined
                    ? filtered[2].question
                    : ""}
                </div>
                <TextField
                  id="filled-full-width"
                  className="textBox"
                  name="answer3"
                  label=""
                  required
                  value={this.state.answer3}
                  margin="normal"
                  variant="filled"
                  onChange={this.handleChange}
                  InputProps={{
                    classes: { input: this.props.classes["input"] }
                  }}
                />
              </div>
            </div>
            <div className={classes.buttonStyle}>
              <Button
                variant="contained"
                className={classes.buttonText}
                onClick={(evt) => this.handleVerify(evt)}
                disabled={this.state.disableButton}
              >
                {localLanguageData.btnVerify}
              </Button>
            </div>
            <span className="error_reset" style={{ color: "red" }}>
              {this.state.errorData}
            </span>
            {this.state.enableSuccess ? (
              <div className="successData">
                <span>{localLanguageData.resetPassSuc}</span>
                <span style={{ marginLeft: "2px", fontWeight: "bold" }}>
                  {this.state.successData}
                </span>
                <div>
                  {localLanguageData.clickHere}
                  <span
                    className={classes.forgetLink}
                    onClick={this.handleFollowup}
                  >
                    {localLanguageData.tfollowUp}
                  </span>
                  <span style={{ marginLeft: "4px" }}>
                    {localLanguageData.tyourCase}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            {this.props.history.push({
              pathname: `/followup/locale=${showLang}`
            })}
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(ResetPasswordComponent));

//Data for french language Start
const french = {
  msgSendFailed: "L'envoi du message a échoué",
  ttPeopleInvolved: "Personnes concernées",
  msgSent: "Votre message a été envoyé avec succès",
  invalidCredentials: "Numéro de dossier/mot de passe invalide",
  enterRepNo: "Veuillez entrer votre numéro de rapport",
  followUpTxt:
    "Pour faire le suivi du dossier, veuillez saisir le numéro de rapport et le mot de passe.", // report follow up,
  //report home page
  emergencyNote: `Je comprends que ce site Web ne doit pas être utilisé pour des questions d’urgence nécessitant un avis verbal immédiat. <br/> En cas d’urgence, veuillez communiquer avec les services d’urgence locaux.`,
  understand: "Je comprends",
  ttWhatHappened: "Que s’est-il passé?",
  lblIncidentDetails: "Détails de l’incident",
  ttWhereHappened: "Où cela s’est-il produit?",
  pSelectLocation: "Sélectionnez le site à partir du menu",
  lblCountry: "Pays",
  lblState: "Province",
  lblCity: "Ville",
  lblLocation: "Emplacement",
  phMissingPlace:
    "Si l’emplacement ne figure pas dans la liste, entrez-le ici.",
  requestInfo:
    "Si possible, veuillez inclure les éléments suivants dans votre description.",
  liDateofSituation: "Date/heure de la situation",
  liAssociatesInvovled:
    "Noms des associés concernés, notamment des personnes susceptibles d’avoir été informées ou témoins de la situation que vous décrivez.",
  liSituationOccured: "L’endroit où la situation s'est présentée.",
  liReportedAnyone:
    "Avez-vous signalé le problème à quelqu’un de la Compagnie? Si oui, à qui et quand?",
  spIfNoLocation:
    "Nécessaire uniquement si l’endroit n’est pas précisé ci-dessus.",
  ttWhoInvolved: "Qui étaient les personnes en cause?",
  pOtherPerson:
    "Veuillez fournir les noms des autres personnes touchées par l’incident",
  btnAddPerson: "Ajouter une personne",
  ttContactInfo: "Coordonnées de communication",
  pAnonymous: "Souhaitez-vous rester anonyme?",
  btnReviewForm: "Examen de formulaire",
  //details page - child component of report home page
  phFirstName: "Prénom",
  phLastName: "Nom",
  lblJobTitle: "Poste",
  IncidentRole: "Rôle dans cet incident",
  btnRemove: "Supprimer",
  phPhoneNo: "Numéro de téléphone",
  phEmail: "Adresse courriel",
  valEmail: "Veuillez saisir un courriel valide",

  // Report Review
  ttReportReview: "Examen du rapport",
  ttConfirmDetails:
    "Veuillez confirmer les détails du rapport et créer un mot de passe.",
  ttIncidentDetails: "Détails de l’incident",
  ttIncidentDescription: "Description de l’incident",
  ttIncidentLocation: "Lieu de l’incident",
  pImAnonymous: "Anonyme",
  btnEditReport: "MODIFIER LE RAPPORT",
  fhtPasswordDesc: "Le mot de passe servira au suivi de votre rapport.",
  pPasswordNote: ` IMPORTANT : vous avez besoin du numéro de rapport et du mot de passe pour vous connecter à la page de suivi, voir l’état de votre rapport et fournir des précisions. Pour protéger votre vie privée, ces renseignements ne  pourront pas être récupérés plus tard. Nous ne répondons pas directement à votre adresse de courriel ni ne l'utilisons à des fins de suivi.`,
  btnCompleteReport: "RAPPORT COMPLET",

  // follow up confirmation
  ttReportNo: "Confirmation de suivi du rapport",
  ttAdditionalInfo: ` Nous vous remercions d’avoir fourni des précisions sur vos préoccupations d’ordre éthique. Notre équipe examinera votre réponse et pourrait utiliser la page relative au rapport de suivi pour demander de plus amples renseignements. Veuillez donc consulter régulièrement le site.
    `,

  // report confirm
  hReport: "Rapport",
  hCreated: "Créé",
  ttThanksforReport: "Merci d’avoir soumis vos préoccupations d'ordre éthique.",
  progressMsg:
    "Nous sommes en train de créer votre problème d'éthique. S'il vous plaît soyez patient..",
  ttFollowUpDet: "Précisions sur le suivi",
  pReportReview: `Notre Bureau mondial de l’éthique examinera votre soumission et formulera une première réponse dans un délai de 3 à 5 jours ouvrables par le biais de la page relative au rapport de suivi.`,
  pAdditionalInforReq: ` Notre équipe pourrait utiliser la page relative au rapport de suivi pour demander des précisions. Veuillez donc consulter régulièrement le site.`,
  pNeedPd: `Pour faire le suivi de ce rapport, veuillez noter le mot de passe et le numéro de rapport. Afin de protéger votre vie privée, ces renseignements ne pourront pas être récupérés plus tard.`,
  pReportNo: "Numéro de rapport",
  ttReportFlwUp: "Suivi de rapport",
  ttEnterReportNo:
    "Pour faire le suivi du dossier, veuillez saisir le numéro de rapport et le mot de passe.",
  btnFindReport: "Trouver le rapport",
  ttFollowReview: "Examen de suivi",
  ttConfirmDet: "Veuillez confirmer le rapport.",
  ttAddDetRes: "Précisions",
  btnEdit: "MODIFIER",
  btnSubmit: "ENVOYER",

  //Follow up reponse
  dReportDet: "Détails du rapport",
  sIncidentDesc: "Description de l’incident",
  ttReportHistory: "Historique du rapport",
  sRepSubOn: "Date de présentation du rapport",
  btnReview: "EXAMEN",
  pCaseClosed:
    "  Ce dossier est classé. Si vous avez d’autres motifs de préoccupation, veuillez utiliser la page de signalement des problèmes d'éthique .",
  ComFrmEthics: "Communication du service d’Éthique",
  Reporter: "Rapporteur",
  System: "Système",
  resetPassSuc:
    "Mot de passe réinitialisé avec succès. Votre nouveau mot de passe est ",
  clickHere: "Cliquer ici ",
  tfollowUp: "Suivi",
  tyourCase: "votre dossier. ",
  //Reset Password,
  justNow: "Il y a un instant",
  fewMinutes: "Il y a quelques minutes",
  hours: "Il y a une heure",
  month: "Il y a un mois",
  year: "Il y a un an",
  additionalInfo: "Renseignements supplémentaires",
  forgotPd: "Mot de passe oublié",
  daysAgo: " Il y a --- jours",
  yes: "Oui",
  start: "Début",
  end: "Fin",
  no: "Non",
  genricError:
    "Veuillez communiquer avec nous par un autre moyen. Vous pouvez envoyer un courriel à l’adresse Ethics@Wal-mart.com.",
  wrongAnswer:
    "Vos réponses ne correspondent pas à nos données. Veuillez essayer de nouveau.",
  resetError:
    "Une seconde réinitialisation ne pourra pas être effectuée avant 24 heures. Veuillez essayer à nouveau demain ou envoyer un courriel à Ethics@Wal-mart.com si vous souhaitez fournir des informations supplémentaires. Le numéro du dossier d'éthique doit être indiqué dans l'objet du courriel.",
  resetLimit:
    "Réinitialisation interdite après trois tentatives infructueuses. Si vous avez des précisions à apporter, veuillez réessayer dans 15 minutes ou envoyer un courriel à Ethics@Wal-mart.com. Veuillez indiquer dans l’objet du courriel le numéro du dossier d’éthique.",
  resetPasswordHeader:
    "Réinitialiser le mot de passe relatif au numéro de rapport.",
  answerTxt:
    "Répondez aux questions de sécurité ci-dessous pour créer un nouveau mot de passe (les réponses doivent êtres précisément exactes, à l’espacement et à la capitalisation près).",
  question1: "Question 1",
  question2: "Question 2",
  question3: "Question 3",
  btnVerify: "Vérifier",
  btnReset: "Réinitialiser",
  securtiyQuestionHdr:
    "Afin de créer un mot de passe pour votre rapport, choisissez trois questions de sécurité et répondez-y: ",
  generateButton: "Créer un mot de passe",
  pPasswordtxt: "Mot de passe",
  SecurityQuestions: [
    {
      id: 1,
      question: "Dans quelle ville avez-vous rencontré votre premier conjoint?"
    },
    { id: 2, question: "Dans quelle ville est né votre père?" },
    {
      id: 3,
      question: "Dans quelle ville avez-vous occupé votre premier emploi?"
    },
    { id: 4, question: "Dans quelle ville est née votre mère?" },
    {
      id: 5,
      question:
        "Quel était le nom de votre professeur préféré à l’école secondaire?"
    },
    {
      id: 6,
      question:
        "Donnez le nom d’un collège auquel vous avez postulé, mais que vous n’avez pas fréquenté."
    },
    { id: 7, question: "Quel est le deuxième prénom de votre père?" },
    { id: 8, question: "Quel est le deuxième prénom de votre mère?" },
    {
      id: 9,
      question:
        "Quelle est la date de naissance de votre frère aîné ou votre sœur aînée (mois et année, p. ex. novembre 1985)"
    },
    {
      id: 10,
      question:
        "Quel est le deuxième prénom de votre frère aîné ou votre sœur aînée?"
    },
    {
      id: 11,
      question: "Quel est le deuxième prénom de votre plus jeune frère ou sœur?"
    },
    {
      id: 12,
      question:
        "Quelle école avez-vous fréquentée en sixième année du primaire?"
    },
    {
      id: 13,
      question: "Quel est le prénom de votre meilleur ami d’enfance?"
    }
  ],
  roleIncidentOptions: [
    { value: "subject", label: "Sujet" },
    { value: "witness", label: "Témoin" },
    { value: "affected_individual", label: "Personne concernée" },
    { value: "other", label: "Autre" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "Responsable" },
    { value: "management", label: "Gestion" },
    { value: "hourly_associate", label: "Associé horaire" },
    { value: "vendor_supplier", label: "Fournisseur" },
    { value: "customer", label: "Client" },
    { value: "other", label: "Autre" }
  ]
};

export default french;
n
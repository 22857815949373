const japanese = {
  ttPeopleInvolved: "関与した人々",
  msgSent: "メッセージは正常に送信されました",
  msgSendFailed: "メッセージを送信できませんでした",
  daysAgo: " 日前",
  invalidCredentials: "無効なケース番号/パスワード",
  enterRepNo: "お願いしますレポート番号を入力してください",
  resetPassSuc: "パスワードのリセットに成功しました。新しいパスワードは ",
  clickHere: "ここをクリックして ",
  tfollowUp: "ファローアップ",
  tyourCase: "なたの場合. ",
  followUpTxt: "案件番号とパスワードを入力してください。",
  emergencyNote:
    "このウェブサイトは、口頭による通知を即時に必要とする緊急事に利用することはできません。緊急事態の場合は、お住まいの地域の緊急時対応サービスにご連絡ください。",
  understand: "理解した",
  ttWhatHappened: "何が発生しましたか？",
  lblIncidentDetails: "その事案の詳細",
  ttWhereHappened: "その事案が発生した場所",
  pSelectLocation: "メニューから場所を選択してください。",
  lblCountry: "国",
  lblState: "州・都道府県",
  lblCity: "市",
  lblLocation: "場所",
  phMissingPlace: "場所がリストにない場合は、ここに入力してください。",
  requestInfo: "あなたの懸念について以下の情報を提供してください。",
  liDateofSituation: "その事案が発生した日付・時刻",
  liAssociatesInvovled:
    "今回の事案の目撃者、或いはその事案について知っているその他の人の氏名を報告してください。",
  liSituationOccured: "その事案が発生した場所",
  liReportedAnyone:
    "その事案について社内の人物に報告をしましたか？報告をした場合、誰にいつ報告をしましたか？",
  spIfNoLocation: "場所がリストに無い場合は、ここに入力してください。",
  ttWhoInvolved: "関与したのは誰ですか？",
  pOtherPerson: "この事案に関与したその他の人の名前を報告してください。",
  btnAddPerson: "もう一人追加",
  ttContactInfo: "あなたの連絡先",
  pAnonymous: "匿名を希望されますか？",
  btnReviewForm: "見直す",
  phFirstName: "ファーストネーム（名）",
  phLastName: "ラストネーム（姓）",
  lblJobTitle: "職位",
  IncidentRole: "関係者",
  btnRemove: "この指名を削除",
  phPhoneNo: "電話番号",
  phEmail: "e-メールアドレス",
  valEmail: "e-メールアドレスを入力してください。",
  ttReportReview: "作成内容の確認",
  ttConfirmDetails: "報告内容の確認をし、パスワードを作成してください。",
  ttIncidentDetails: "事案の詳細",
  ttIncidentDescription: "事案の内容",
  ttIncidentLocation: "事案の発生場所",
  pImAnonymous: "匿名",
  btnEditReport: "編集",
  fhtPasswordDesc: "パスワードは報告事案の進捗を確認する際に必要となります。",
  pPasswordNote:
    "【重要】報告事案の進捗状況を確認するページにログインする為には、案件番号とパスワードが必要となります。あなたのプライバシーを保護する為、この情報は後日取り戻すことができません。当方からあなたのe-メールドレスに直接応答したり、進捗の連絡をすることはありません。",
  ttAdditionalInfo:
    "先日ご報告頂いた懸念について、追加情報をご提供頂きありがとうございます。グローバル倫理チームが追加情報を確認致します。グローバル倫理オフィスより更なる追加情報のお願いがあるかもしれませんので、当サイトを定期的にご確認ください。",
  ttReportNo: "報告事案の進捗の確認",
  btnCompleteReport: "作成終了",
  dReportDet: "報告事案の詳細",
  sIncidentDesc: "報告事案の内容",
  ttReportHistory: "報告履歴",
  sRepSubOn: "提出された報告事案",
  btnReview: "作成内容の確認",
  pCaseClosed:
    "この事案は終結致しました。他にも懸念がある場合は、懸念を報告するページにて報告をしてください。",
  ComFrmEthics: "倫理オフィスからの連絡",
  Reporter: "報告者",
  System: "システム",
  hReport: "報告",
  hCreated: "作成済み",
  ttThanksforReport: "あなたの懸念をご報告頂き、ありがとうございます。",
  progressMsg:
    "私たちはあなたの倫理的懸念を作成しているところです。しばらくお待ちください..",
  ttFollowUpDet: "報告事案の進捗の詳細",
  pReportReview:
    "グローバル倫理オフィスは報告内容を確認し、3-5営業日以内に「報告事案の進捗状況」のページにて最初の返答を致します。",
  pAdditionalInforReq:
    "グローバル倫理オフィスより追加情報のお願いがあるかもしれませんので、当サイトを定期的にご確認ください。",
  pNeedPd:
    "報告事案の進捗状況を確認するページにログインする為の案件番号とパスワードを控えてください。あなたのプライバシーを保護する為、この情報は後日取り戻すことができません。",
  pReportNo: "案件番号",
  ttReportFlwUp: "報告事案の進捗",
  ttEnterReportNo: "案件番号とパスワードを入力してください。",
  btnFindReport: "報告した事案を探す",
  ttFollowReview: "報告事案の進捗状況を確認",
  ttConfirmDet: "報告事案の詳細を確認してください。",
  ttAddDetRes: "追加情報・返信",
  btnEdit: "編集",
  btnSubmit: "送信",
  additionalInfo: "追加情報",
  justNow: "たった今",
  fewMinutes: "数分前",
  hours: "数時間前",
  month: "数か月前",
  year: "数年前",
  forgotPd: "パスワードを忘れた。",
  yes: "はい",
  no: "いいえ",
  end: "終了",
  start: "開始",
  genricError:
    "別の方法で連絡をしてください。Ethics@Wal-mart.com.宛にE-メールを送信することも可能です。",
  wrongAnswer:
    "あなたの回答はデータと一致しませんでした。もう一度回答してください。",
  resetError:
    "Second reset prohibited within 24-hour period. Please try again tomorrow or send an email to Ethics@Wal-mart.com if you have additional information to provide. You must include your Ethics case number in the email subject line.",
  resetLimit:
    "不正解の回答が三回続くとリセットができなくなります。15分後にもう一度回答するか、追加情報がある場合は、Ethics@Wal-mart.comへE-メールにて提供をしてください。その際に、E-メールの件名に案件番号を必ず記入してください。",
  resetPasswordHeader: "案件番号のパスワードをリセットする。",
  answerTxt:
    "新しいパスワードを作成する為に、以下の秘密の質問に答えてください。(答えはスペースや大文字・小文字など、全て一致する必要があります。）",
  question1: "質問 1",
  question2: "質問 2",
  question3: "質問 3",
  btnVerify: "確認",
  btnReset: "リセット",
  securtiyQuestionHdr:
    "あなたの報告にアクセスする際に必要となるパスワードを作成する為に、秘密の質問を３つ選択し、各質問に答えてください。",
  generateButton: "パスワードを作成する。",
  pPasswordtxt: "パスワード",
  SecurityQuestions: [
    { id: 1, question: "最初の結婚相手と出会った場所（市）" },
    { id: 2, question: "父親の出生都市" },
    { id: 3, question: "初めての仕事の所在地（市）" },
    { id: 4, question: "母親の出生都市" },
    { id: 5, question: "高校時代に好きだった先生の姓" },
    { id: 6, question: "大学受験をしたが、入学しなかった学校" },
    { id: 7, question: "父親のミドルネーム" },
    { id: 8, question: "母親のミドルネーム" },
    { id: 9, question: "一番上の兄弟姉妹の誕生日（年・月）" },
    { id: 10, question: "一番上の兄弟姉妹のミドルネーム" },
    { id: 11, question: "一番下の兄弟姉妹のミドルネーム" },
    { id: 12, question: "小学校6年生の時の学校名" },
    { id: 13, question: "子供の頃の親友の名前" }
  ],
  roleIncidentOptions: [
    { value: "subject", label: "当事者" },
    { value: "witness", label: "目撃者" },
    { value: "affected_individual", label: "上記他にも直接影響を受けた人物" },
    { value: "other", label: "その他" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "役員" },
    { value: "management", label: "マネージャー" },
    { value: "hourly_associate", label: "時間給アソシエイト" },
    { value: "vendor_supplier", label: "取引先・サプライヤー" },
    { value: "customer", label: "顧客" },
    { value: "other", label: "その他" }
  ]
};

export default japanese;

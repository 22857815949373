import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {  isEqual } from "lodash";
import { selectedLanguage } from "../../lang-config";
let localLanguageData = "";
// const countryOptions = [
//   { value: "Flipkart India", label: " Flipkart India" }
//   // { value: "USA", label: "USA" },
//   // { value: "Australia", label: "Australia" },
//   // { value: "South Africa", label: "South Africa" }
// ];

// const colourStyles = {
//   container: styles => ({
//     ...styles,
//     border: "0px",
//     width: "40%",
//     paddingTop: "20px",
//     paddingLeft: "10px",
//     paddingBottom: "20px"
//   }),
//   control: styles => ({ ...styles, backgroundColor: "white" })
// };
const styles = theme => ({
  root: {
    // width: '50%',
    // height: '325px',
    // boxShadow: '0 0 14px #3c505080',
    // margin: 0 auto;
  },
  button: {
    margin: theme.spacing(1),
    float: "right"
  },
  textBox: {
    //width: "90%"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 490
  },
  buttonStyle: {
    paddingTop: "15px",
    paddingRight: "20px",
    paddingBottom: "10px"
  },
  input: {
    "&::placeholder": {
      color: "color: rgba(0,0,0,1) !important;",
      fontWeight: "bold"
    }
  }
});
// const jobTitleOptions = [
//   { value: "officer", label: "Officer" },
//   { value: "manager", label: "Management" },
//   { value: "hourly_associate", label: "Hourly Associate" },
//   { value: "vendor_supplier", label: "Vendor/Supplier" },
//   { value: "customer", label: "Customer" },
//   { value: "other", label: "Others" }
// ];
let jobTitleOptions = [];
class AnonymousForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobTitle: "",
      firstName: "",
      lastName: ""
     // phone: "",
     // email: "",
      //emailError: false
    };
    /*this.callEmailVal = debounce(function() {
      this.validEmail();
    }, 1000);*/
  }
  componentDidMount() {
    if (this.props.data != null) {
      this.setState({
        jobTitle: this.props.data.jobTitle,
        firstName: this.props.data.firstName,
        lastName: this.props.data.lastName
        //phone: this.props.data.phone
        //email: this.props.data.email
      });
    }
    if (localLanguageData.hasOwnProperty("jobTitleOptions")) {
      jobTitleOptions = localLanguageData.jobTitleOptions;
    }
    this.props.anonymousDataCbFn(this.state);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      jobTitle: nextProps.data.jobTitle,
      firstName: nextProps.data.firstName,
      lastName: nextProps.data.lastName
     // phone: nextProps.data.phone,
     // email: nextProps.data.email
    });  
  }
  /*validEmail = () => {
    const regex = /^[^@]+@[^@]+\.[^@]+$/;
    const result = regex.test(this.state.email);
    if (result === false) {
      errObject.emailErrorTxt = localLanguageData.valEmail;
      this.setState({ emailError: true });
    } else {
      errObject.emailErrorTxt = "";
      this.setState({ emailError: false });
    }
  };

  handleEmailChange = async e => {
    await this.setState(
      {
        [e.target.name]: e.target.value
      },
      function() {
        this.props.anonymousDataCbFn(this.state);
      }
    );
    this.callEmailVal();
  };*/
  handleChange = async e => {
    if (e && e.target && e.target.name === "checked") {
      await this.setState(
        {
          [e.target.name]: !this.state.checked
        },
        function() {
          this.props.anonymousDataCbFn(this.state);
        }
      );
    } /*else if (e && e.target && e.target.name === "phone") {
      const onlyNums = e.target.value.replace(/[^0-9]/g, "");
      if (onlyNums.length < 10) {
        await this.setState({ [e.target.name]: onlyNums });
      } else if (onlyNums.length === 10) {
        const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        await this.setState({ [e.target.name]: number }, function() {
          this.props.anonymousDataCbFn(this.state);
        });
      }
    }*/ else {
      await this.setState(
        {
          [e.target.name]: e.target.value
        },
        function() {
          this.props.anonymousDataCbFn(this.state);
        }
      );
    }
    this.props.anonymousDataCbFn(this.state);
  };
  render() {
    const { classes } = this.props;
    const { LanguageData } = this.props;
    localLanguageData =
      isEqual(LanguageData, "") || isEqual(LanguageData, undefined)
        ? selectedLanguage()
        : LanguageData;

    return (
      <div className={classes.root}>
        <div id="location_1">
          <TextField
            onChange={this.handleChange}
            value={this.state.firstName || ""}
            id="filled-full-width"
            style={{ margin: 8 }}
            name="firstName"
            label={localLanguageData.phFirstName}
            required
            margin="normal"
            variant="filled"
            InputProps={{ classes: { input: this.props.classes["input"] } }}
            className="textBox"
          />
          <TextField
            onChange={this.handleChange}
            value={this.state.lastName || ""}
            id="filled-full-width"
            style={{ margin: 8 }}
            name="lastName"
            label={localLanguageData.phLastName}
            required
            margin="normal"
            variant="filled"
            InputProps={{ classes: { input: this.props.classes["input"] } }}
            className="textBox"
          />
        </div>
        <FormControl className={classes.formControl}>
          <InputLabel>{localLanguageData.lblJobTitle}</InputLabel>
          <Select
            value={this.state.jobTitle || ""}
            onChange={this.handleChange}
            displayEmpty
            name="jobTitle"
          >
            {jobTitleOptions && jobTitleOptions.map((item, index) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
       { /*< div id="location_1">
          <TextField
            onChange={this.handleChange}
            id="filled-full-width"
            value={this.state.phone || ""}
            style={{ margin: 8 }}
            name="phone"
            placeholder={localLanguageData.phPhoneNo}
            margin="normal"
            variant="filled"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ classes: { input: this.props.classes["input"] } }}
            className="textBox"
          />
          <TextField
            onChange={this.handleEmailChange}
            error={this.state.emailError}
            value={this.state.email || ""}
            id="filled-full-width"
            style={{ margin: 8 }}
            name="email"
            //  onLostPointerCapture ={this.validEmail()}
            placeholder={localLanguageData.phEmail}
            margin="normal"
            variant="filled"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ classes: { input: this.props.classes["input"] } }}
            className="textBox"
          />
          <FormHelperText style={{ fontSize: "10px", color: "red" }}>
            {errObject.emailErrorTxt}
          </FormHelperText>
        </div>*/}
          </div>
    );
  }
}
export default withStyles(styles)(AnonymousForm);

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { withRouter } from "react-router-dom";
import "./reviewreport.css";
import { selectedLanguage } from "../../lang-config";
import { isEqual } from "lodash";
import { securityQuesConfig, internalAPI } from "../../config";
import { currentLang, RandomPdGen } from "../../helper";

import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

const showLang = currentLang();
let localLanguageData = "";

const styles = () => ({
  root: {
    margin: "0 auto",
    width: "65%"
  },
  textBox: {
    width: "90%"
  },
  card: {
    maxWidth: "90%",
    textAlign: "center",
    marginLeft: "2%",
    marginBottom: 20,
    borderRadius: 10
  },
  pos: {
    align: "center",
    marginBottom: 0
  },
  data: {
    backgroundColor: "#c2d3da",
    paddingLeft: "25px",
    borderRadius: "10px",
    boxShadow: "0 0 14px #3c505080"
  },

  buttonStyle: {
    paddingTop: "15px",
    paddingRight: "20px",
    paddingBottom: "10px"
  },
  reviewButton: {
    paddingTop: "15px",
    paddingRight: "20px",
    paddingBottom: "10px",
    textAlign: "center"
  },
  loaderDialogContainer: {
    padding: 25
  },
  loaderText: {
    fontSize: 15,
    marginTop: 10,
    fontWeight: 600,
    fontFamily: "Bogle"
  }
});

let jobTitle = "",
  jobTitleOptions = [],
  roleIncidentOptions = [],
  securityQuesArr = [],
  omitQuestions = { question1: "", question2: "", question3: "" };

class ReviewReportComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      secword: "",
      question1: "",
      question2: "",
      question3: "",
      securityQ1: "",
      securityQ2: "",
      securityQ3: "",
      answer1: "",
      answer2: "",
      answer3: "",
      errorData: "",
      submitLoader: false,
      disableButton: true
    };
    if (
      this.props.reportData &&
      this.props.reportData !== "" &&
      this.props.reportData !== undefined
    ) {
      this.props.location.reportData = this.props.reportData;
    }
  }
  async componentDidMount() {
    this._isMounted = true;
    if (
      this.props.location &&
      isEqual(this.props.location.reportData, undefined)
    ) {
      this.props.history.push({
        pathname: `/locale=${showLang}`
      });
    } else if (
      this.props.location.reportData.questionData !== undefined &&
      this.props.location.reportData.questionData !== ""
    ) {
      let questionData = this.props.location.reportData.questionData;
      this.setState({
        question1: questionData.question1,
        question2: questionData.question2,
        question3: questionData.question3,
        securityQ1: questionData.securityQ1,
        secuirtyQ2: questionData.securityQ2,
        secuirtyQ3: questionData.securityQ3,
        answer1: questionData.answer1,
        answer2: questionData.answer2,
        answer3: questionData.answer3
      });
    }

    if (
      document &&
      document.getElementById("root") &&
      document.getElementById("root") !== undefined
    ) {
      document.getElementById("root").scrollTop = 0;
      window.scrollTo(0, 0);
    }
    if (
      this.state.securityQ1 === "" &&
      this.state.securityQ2 === "" &&
      this.state.securityQ3 === ""
    ) {
      await this.setState({
        securityQ1: this.getAllSecurityQuestions(
          localLanguageData.SecurityQuestions
        ),
        securityQ2: this.getAllSecurityQuestions(
          localLanguageData.SecurityQuestions
        ),
        securityQ3: this.getAllSecurityQuestions(
          localLanguageData.SecurityQuestions
        )
      });
      this.enableButton();
      window.scrollTo(0, 0);

      const parent_origin =
        window.location !== window.parent.location
          ? document.referrer !== ""
            ? document.referrer
            : "*"
          : document.location.href;

      this.myScrollToTop(parent_origin);
    }
    if (localLanguageData.hasOwnProperty("jobTitleOptions")) {
      jobTitleOptions = localLanguageData.jobTitleOptions;
    }
    if (localLanguageData.hasOwnProperty("jobTitleOptions")) {
      roleIncidentOptions = localLanguageData.roleIncidentOptions;
    }
    this.generatesecword();
  }
  myScrollToTop = (parent_origin) => {
    window.parent.postMessage("hello there!", parent_origin);
  };
  enableButton = () => {
    const {
      question1,
      question2,
      question3,
      answer1,
      answer2,
      answer3,
      submitLoader
    } = this.state;

    if (
      question1 !== "" &&
      question2 !== "" &&
      question3 !== "" &&
      answer1 !== "" &&
      answer2 !== "" &&
      answer3 !== "" &&
      !submitLoader
    ) {
      this.setState({ disableButton: false });
    } else {
      this.setState({ disableButton: true });
    }
  };
  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value
    });
    this.enableButton();
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (
        document &&
        document.getElementById("root") &&
        document.getElementById("root") !== undefined
      ) {
        document.getElementById("root").scrollTop = 0;
        window.scrollTo(0, 0);
      }
    }
  }
  editReport = () => {
    this.setState({ errorData: "" });
    this.props.history.push({
      pathname: `/report/locale=${showLang}`,
      reportDatatoEdit: this.props.location.reportData,
      LanguageData: localLanguageData,
      questionData: this.state
    });
  };
  generatesecword = async () => {
    if (
      isEqual(this.state.secword, "") ||
      isEqual(this.state.secword, undefined)
    ) {
      await this.setState({ secword: RandomPdGen() });
    }
  };
  handleSubmitReport = () => {
    this.setState({ errorData: "", submitLoader: true, disableButton: true });
    this.generatesecword();

    const reportData = this.props.location.reportData;
    let partiesData = [];
    let anonymous = false;
    for (let i = 0; i < reportData.detailsData.length; i += 1) {
      partiesData.push({
        job_title: reportData.detailsData[i].jobTitle,
        involvement: reportData.detailsData[i].roleInIncident,
        first_name: reportData.detailsData[i].firstName,
        last_name: reportData.detailsData[i].lastName
      });
    }
    if (
      reportData.anonymousChecked &&
      reportData.anonymousData !== undefined &&
      isEqual(reportData.anonymousChecked, "no")
    ) {
      partiesData.push({
        job_title: reportData.anonymousData.jobTitle,
        involvement: "reporter",
        first_name: reportData.anonymousData.firstName,
        last_name: reportData.anonymousData.lastName,
        phone: reportData.phone,
        email: reportData.email
      });
      anonymous = false;
    } else if (
      reportData.anonymousChecked &&
      reportData.anonymousData !== undefined &&
      isEqual(reportData.anonymousChecked, "yes")
    ) {
      partiesData.push({
        involvement: "reporter",
        anonymous: true,
        phone: reportData.phone,
        email: reportData.email
      });
      anonymous = true;
    }
    const questionAns = [];
    questionAns.push(
      { question: this.state.question1, answer: this.state.answer1 },
      { question: this.state.question2, answer: this.state.answer2 },
      { question: this.state.question3, answer: this.state.answer3 }
    );
    let source = reportData.fromWebOD ? "portal" : "web_submission";
    const test = {
      u_issue_detail: reportData.incidentDetails,
      u_parties: JSON.stringify(partiesData),
      u_pin: this.state.secword,
      u_security_questions: JSON.stringify(questionAns),
      u_country: reportData.country,
      u_city: reportData.city,
      u_state: reportData.state,
      u_location: reportData.place,
      u_facility_address:
        reportData.place !== undefined && reportData.place !== ""
          ? reportData.place
          : reportData.missingPlace,
      u_address:
        reportData.place !== undefined && reportData.place !== ""
          ? ""
          : reportData.missingPlace,
      anonymous: anonymous,
      u_language: showLang ? showLang : "en",
      u_source: source
    };
    axios.post(internalAPI.CreateConcernApiUrl, test).then(
      (res) => {
        let response = { data: res.data.response };
        if (
          response &&
          response.data &&
          response.data !== "" &&
          response.data !== undefined
        ) {
          this.props.history.push({
            pathname: `/confirm/locale=${showLang}`,
            LanguageData: localLanguageData,
            reportNumber: response.data.number,
            secword: this.state.secword
          });
        } else {
          this.setState({
            errorData: localLanguageData.genricError,
            submitLoader: false,
            disableButton: false
          });
        }
      },
      (error) => {
        this.setState({
          errorData: localLanguageData.genricError,
          submitLoader: false,
          disableButton: false
        });
      }
    );
  };

  getAllSecurityQuestions = (data) => {
    securityQuesArr = [];
    const ActiveQuestId = securityQuesConfig
      .filter((x) => isEqual(x.active, true))
      .map((a) => a.id);
    data.forEach(function (i, v) {
      if (ActiveQuestId.indexOf(i.id) !== -1) {
        securityQuesArr.push(i);
      }
    });
    return securityQuesArr;
  };

  handleQuestionChange = async (e) => {
    if ((isEqual(e.target.value), undefined)) {
    }
    const originalQuestonSets = this.getAllSecurityQuestions(
      localLanguageData.SecurityQuestions
    );
    await this.setState({
      securityQ1: originalQuestonSets,
      securityQ2: originalQuestonSets,
      securityQ3: originalQuestonSets
    });
    if (e.target.value !== "") {
      await this.setState({
        [e.target.name]: ""
      });
    }
    await this.setState({
      [e.target.name]: e.target.value
    });
    let originalQuestonSet = originalQuestonSets;
    let newData = [];
    let newData1 = [];
    let newData2 = [];

    if (isEqual(e.target.name, "question1")) {
      omitQuestions.question1 = this.state.question1;
      newData = originalQuestonSet.filter(
        (e) => e.id !== omitQuestions.question1
      );
      newData2 = newData.filter((e) => e.id !== omitQuestions.question3);
      newData1 = newData.filter((e) => e.id !== omitQuestions.question2);
      await this.setState({
        securityQ2: this.getAllSecurityQuestions(newData2),
        securityQ3: this.getAllSecurityQuestions(newData1)
      });
    } else if (isEqual(e.target.name, "question2")) {
      omitQuestions.question2 = this.state.question2;
      newData = originalQuestonSet.filter(
        (e) => e.id !== omitQuestions.question2
      );
      newData2 = newData.filter((e) => e.id !== omitQuestions.question3);
      newData1 = newData.filter((e) => e.id !== omitQuestions.question1);
      await this.setState({
        securityQ1: this.getAllSecurityQuestions(newData2),
        securityQ3: this.getAllSecurityQuestions(newData1)
      });
    } else if (isEqual(e.target.name, "question3")) {
      omitQuestions.question3 = this.state.question3;
      newData = originalQuestonSet.filter(
        (e) => e.id !== omitQuestions.question3
      );
      newData2 = newData.filter((e) => e.id !== omitQuestions.question2);
      newData1 = newData.filter((e) => e.id !== omitQuestions.question1);
      await this.setState({
        securityQ1: this.getAllSecurityQuestions(newData2),
        securityQ2: this.getAllSecurityQuestions(newData1)
      });
    }
    this.enableButton();
  };

  render() {
    const reportData =
      this.props.location.reportData &&
      JSON.parse(
        DOMPurify.sanitize(JSON.stringify(this.props.location.reportData))
      );
    const LanguageData =
      this.props.location.LanguageData &&
      JSON.parse(
        DOMPurify.sanitize(JSON.stringify(this.props.location.LanguageData))
      );
    localLanguageData =
      isEqual(LanguageData, "") || isEqual(LanguageData, undefined)
        ? selectedLanguage()
        : LanguageData;

    const { classes } = this.props;
    let personDetails = [];
    if (
      reportData &&
      reportData.detailsData &&
      reportData.detailsData !== undefined &&
      reportData.detailsData.length > 0
    ) {
      for (let i = 0; i < reportData.detailsData.length; i += 1) {
        let jobTitle = "";
        let roleInIncident = "";
        personDetails.push(
          <div
            key={i}
            style={{
              margin: "25px",
              boxShadow: "0 0 14px #3c505080",
              float: "left",
              padding: "20px",
              width: "36%"
            }}
          >
            {reportData.detailsData[i] &&
            reportData.detailsData[i].firstName !== undefined &&
            reportData.detailsData[i].firstName !== "" ? (
              <React.Fragment>
                {localLanguageData.phFirstName}:{" "}
                {" " + reportData.detailsData[i].firstName} <br />
              </React.Fragment>
            ) : null}
            {reportData.detailsData[i] &&
            reportData.detailsData[i].lastName !== undefined &&
            reportData.detailsData[i].lastName !== "" ? (
              <React.Fragment>
                {localLanguageData.phLastName}:{" "}
                {" " + reportData.detailsData[i].lastName} <br />
              </React.Fragment>
            ) : null}
            <React.Fragment>
              {reportData.detailsData[i] &&
              reportData.detailsData[i].jobTitle !== undefined &&
              reportData.detailsData[i].jobTitle !== "" ? (
                <React.Fragment>
                  {jobTitleOptions &&
                    jobTitleOptions.forEach(function (element) {
                      if (element.value === reportData.detailsData[i].jobTitle)
                        jobTitle = element.label;
                    })}
                  {localLanguageData.lblJobTitle}: {" " + jobTitle}
                  <br />
                </React.Fragment>
              ) : null}
              {reportData.detailsData[i] &&
              reportData.detailsData[i].roleInIncident !== undefined &&
              reportData.detailsData[i].roleInIncident !== "" ? (
                <React.Fragment>
                  {roleIncidentOptions &&
                    roleIncidentOptions.forEach(function (element) {
                      if (
                        element.value ===
                        reportData.detailsData[i].roleInIncident
                      )
                        roleInIncident = element.label;
                    })}
                  {localLanguageData.IncidentRole}: {" " + roleInIncident}{" "}
                  <br />
                </React.Fragment>
              ) : null}
            </React.Fragment>
          </div>
        );
      }
    }

    if (reportData) {
      return (
        <>
          <div className={classes.root} id="mainPage">
            <h1>{localLanguageData.ttReportReview}</h1>
            <h4 style={{ marginTop: "10px" }}>
              {localLanguageData.ttConfirmDetails}
            </h4>
            <h2 style={{ color: "#041F41" }}> </h2>
            <h3 style={{ marginLeft: "25px" }}>
              {" "}
              {localLanguageData.ttIncidentDescription}:
            </h3>
            <span style={{ marginLeft: "35px" }}>
              {reportData.incidentDetails}
            </span>
            <h3 style={{ marginLeft: "25px" }}>
              {localLanguageData.ttIncidentLocation}
            </h3>
            <p style={{ marginLeft: "30px" }}>
              {" "}
              {reportData && reportData.CountryName ? (
                <React.Fragment>
                  {" "}
                  {localLanguageData.lblCountry}:{" " + reportData.CountryName}{" "}
                  <br />
                </React.Fragment>
              ) : null}
              {reportData.StateName !== undefined &&
              reportData.StateName !== "" ? (
                <React.Fragment>
                  {" "}
                  {localLanguageData.lblState}: {" " + reportData.StateName}{" "}
                  <br />{" "}
                </React.Fragment>
              ) : null}
              {reportData.CitiName !== undefined &&
              reportData.CitiName !== "" ? (
                <React.Fragment>
                  {" "}
                  {localLanguageData.lblCity}: {" " + reportData.CitiName}{" "}
                  <br />{" "}
                </React.Fragment>
              ) : null}
              {(reportData.LocationName !== undefined &&
                reportData.LocationName !== "") ||
              (reportData.missingPlace !== undefined &&
                reportData.missingPlace !== "") ? (
                <React.Fragment>
                  {localLanguageData.lblLocation}:{" "}
                  {" " +
                    (reportData.LocationName !== ""
                      ? reportData.LocationName
                      : reportData.missingPlace)}
                </React.Fragment>
              ) : null}{" "}
            </p>

            {personDetails.length > 0 ? (
              <div>
                <h3 style={{ marginLeft: "25px" }}>
                  {localLanguageData.ttPeopleInvolved}
                </h3>
                <div style={{ float: "left", width: "100%" }}>
                  {personDetails}
                </div>
              </div>
            ) : null}
            {reportData &&
            reportData.anonymousChecked &&
            reportData.anonymousChecked !== "yes" ? (
              <React.Fragment>
                <h2 style={{ color: "#041F41" }}>
                  {" "}
                  {localLanguageData.ttContactInfo}
                </h2>

                <p style={{ marginLeft: "25px" }}>
                  {reportData.anonymousData &&
                  reportData.anonymousData.firstName !== undefined &&
                  reportData.anonymousData.firstName !== "" ? (
                    <React.Fragment>
                      {localLanguageData.phFirstName}:{" "}
                      {" " + reportData.anonymousData.firstName} <br />
                    </React.Fragment>
                  ) : null}
                  {reportData.anonymousData &&
                  reportData.anonymousData.lastName !== undefined &&
                  reportData.anonymousData.lastName !== "" ? (
                    <React.Fragment>
                      {localLanguageData.phLastName}:{" "}
                      {" " + reportData.anonymousData.lastName} <br />
                    </React.Fragment>
                  ) : null}
                  <React.Fragment>
                    {reportData.anonymousData &&
                    reportData.anonymousData.jobTitle !== undefined &&
                    reportData.anonymousData.jobTitle !== "" ? (
                      <React.Fragment>
                        {jobTitleOptions &&
                          jobTitleOptions.forEach(function (element) {
                            if (
                              element.value ===
                              reportData.anonymousData.jobTitle
                            )
                              jobTitle = element.label;
                          })}
                        {localLanguageData.lblJobTitle}: {" " + jobTitle} <br />
                      </React.Fragment>
                    ) : null}
                    {reportData.anonymousData &&
                    reportData.anonymousData.phone !== undefined &&
                    reportData.anonymousData.phone !== "" ? (
                      <React.Fragment>
                        {localLanguageData.phPhoneNo}:{" "}
                        {" " + reportData.anonymousData.phone} <br />
                      </React.Fragment>
                    ) : null}
                    {reportData.anonymousData &&
                    reportData.anonymousData.email !== undefined &&
                    reportData.anonymousData.email !== "" ? (
                      <React.Fragment>
                        {localLanguageData.phEmail}:{" "}
                        {" " + reportData.anonymousData.email}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h2 style={{ color: "#041F41" }}>
                  {" "}
                  {localLanguageData.ttContactInfo}
                </h2>
                <p style={{ marginLeft: "25px" }}>
                  {" "}
                  {localLanguageData.pImAnonymous}{" "}
                </p>
              </React.Fragment>
            )}
            <p style={{ marginLeft: "25px" }}>
              {reportData &&
              reportData.phone !== undefined &&
              reportData.phone !== "" ? (
                <React.Fragment>
                  {localLanguageData.phPhoneNo}: {" " + reportData.phone} <br />
                </React.Fragment>
              ) : null}
              {reportData &&
              reportData.email !== undefined &&
              reportData.email !== "" ? (
                <React.Fragment>
                  {localLanguageData.phEmail}: {" " + reportData.email}
                </React.Fragment>
              ) : null}
            </p>
            <p>
              {
                <button
                  style={{
                    background: "#ffff",
                    color: "#041F41",
                    borderRadius: 8,
                    width: "265px",
                    height: "45px",
                    fontSize: "18px",
                    fontWeight: "bold"
                  }}
                  className={classes.button}
                  onClick={this.editReport.bind(this)}
                >
                  {localLanguageData.btnEditReport}
                </button>
              }
            </p>
            <h4>{localLanguageData.securtiyQuestionHdr + " :"}</h4>
            <div className="questions">
              <div className="questionNumbers">
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="age-helper">
                    {localLanguageData.question1}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    className="selectStyle"
                    value={this.state.question1}
                    onChange={this.handleQuestionChange}
                    name="question1"
                  >
                    {this.state.securityQ1 !== "" &&
                    this.state.securityQ1 !== undefined
                      ? this.state.securityQ1.map((item, index) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.question}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
                <TextField
                  //disabled={!this.state.checked}
                  className="textBox_review"
                  id="filled-full-width"
                  name={"answer1"}
                  value={this.state.answer1}
                  //placeholder={localLanguageData.answer}
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    classes: { input: this.props.classes["input"] }
                  }}
                  onChange={this.handleChange}
                />
              </div>
              <div className="questionNumbers">
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="age-helper">
                    {localLanguageData.question2}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    className="selectStyle"
                    value={this.state.question2}
                    onChange={this.handleQuestionChange}
                    name="question2"
                  >
                    {this.state.securityQ2 !== "" &&
                    this.state.securityQ2 !== undefined
                      ? this.state.securityQ2.map((item, index) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.question}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
                <TextField
                  //disabled={!this.state.checked}
                  className="textBox_review"
                  id="filled-full-width"
                  name={"answer2"}
                  value={this.state.answer2}
                  //placeholder={localLanguageData.answer}
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    classes: { input: this.props.classes["input"] }
                  }}
                  onChange={this.handleChange}
                />
              </div>
              <div className="questionNumbers">
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="age-helper">
                    {localLanguageData.question3}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    className="selectStyle"
                    value={this.state.question3}
                    onChange={this.handleQuestionChange}
                    name="question3"
                  >
                    {this.state.securityQ3 !== "" &&
                    this.state.securityQ3 !== undefined
                      ? this.state.securityQ3.map((item, index) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.question}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
                <TextField
                  //disabled={!this.state.checked}
                  id="filled-full-width"
                  name={"answer3"}
                  className="textBox_review"
                  value={this.state.answer3}
                  //placeholder={localLanguageData.answer}
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    classes: { input: this.props.classes["input"] }
                  }}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="generateButton" style={{ marginBottom: "20px" }}>
              <Button
                style={{
                  marginLeft: "40%",
                  marginBottom: "1%",
                  marginTop: "0px !important",
                  paddingTop: "0px !important"
                }}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleSubmitReport}
                disabled={this.state.disableButton}
              >
                {localLanguageData.btnSubmit}
              </Button>
            </div>
            <span style={{ color: "red" }}>{this.state.errorData}</span>
            <Dialog
              open={this.state.submitLoader}
              aria-labelledby="loader-dialog"
              aria-describedby="loader-dialog-desc"
            >
              <div className={classes.loaderDialogContainer}>
                <CircularProgress
                  style={{
                    height: 50,
                    width: 50,
                    position: "relative",
                    left: "45%"
                  }}
                />
                <Typography className={classes.loaderText}>
                  {localLanguageData.progressMsg}
                </Typography>
              </div>
            </Dialog>
          </div>
        </>
      );
    } else {
      return "";
    }
  }
}

export default withStyles(styles)(withRouter(ReviewReportComponent));

import { isEqual } from "lodash";

export function RandomPdGen() {
  // Define character classes to pick from randomly.
  const uppers = "ABCDEFGHJKLMNPQRSTUVWXYZ";
  const lowers = "abcdefghijkmnopqrstuvwxyz";
  const numbers = "123456789";
  const specials = "!@#$%^&*()-+_=";
  const charClasses = [uppers, lowers, numbers, specials];
  const minLen = charClasses.length;
  const regexAscConsecutiveLetter = /(abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz|0123|1234|2345|3456|4567|5678|6789)+/ig;
  const regexDescConsecutiveLetter = /(zyxw|yxwv|xwvu|wvut|vuts|utsr|tsrq|srqp|rqpo|qpon|ponm|onml|nmlk|mlkj|lkji|kjih|jihg|ihgf|hgfe|gfed|fedc|edcb|dcba|3210|4321|5432|6543|7654|8765|9876)+/ig;
  const regexRepeatCharacter = /(.)\1{3,}/g;
   // eslint-disable-next-line
  const regexQwer = /(qwer|wert|erty|rtyu|tyui|yuio|uiop|iop\[|op\[\]|p\[\]\\|op\{\}|p\{\}\||asdf|sdfg|dfgh|fghj|ghjk|hjkl|jkl;|kl;\'|jkl\:|kl\:"|zxcv|xcvb|cvbn|vbnm)+/g;
   // eslint-disable-next-line
  const regexQwerUpperCase = /(OP\{\}|P\{\}\||JKL\:|KL\:"|BNM<|NM<>|M<>\?)+/g;
  function chooseRandom(x) {
    let i = Math.floor(Math.random() * x.length);
    return typeof x === "string" ? x.substr(i, 1) : x[i];
  }
  // Define the function to actually generate a random string.
  var str = "";
  const RandomPdGen = function(maxLen) {
    maxLen = maxLen || 7;
    if (maxLen < minLen) {
      throw new Error("length must be >= " + minLen);
    }
    do {
      // Append a random char from a random char class.
      
        var usedClasses = {},
        charClass;
        str="";
      while (str.length < maxLen) {
        charClass = chooseRandom(charClasses);
        usedClasses[charClass] = true;
        str += chooseRandom(charClass);
      }
      // Ensure we have picked from every char class.
    } while (Object.keys(usedClasses).length !== charClasses.length || (regexAscConsecutiveLetter.test(str) || regexDescConsecutiveLetter.test(str) || regexRepeatCharacter.test(str) || regexQwer.test(str) || regexQwerUpperCase.test(str)));
  
    return str;
  };
  
  return RandomPdGen();
}

export function currentLang() {
  const loc = window.location.href;
  const str = loc.substring(loc.length - 9);
  const lang = str && str.split("=")[1];
  const validLang = ["en","ar","bn","jp","es","zn","fr","hi","id","ko","pt","pa","ur","iw"];
  return !isEqual(lang, undefined) && validLang.includes(lang) ? lang : "en";
}

export default { RandomPdGen, currentLang };

//arabic start
const arabic = {
  daysAgo: " منذ أيام",
  ttPeopleInvolved: "الأفراد المشاركون",
  resetPassSuc: "تم إعادة ضبط كلمة المرور بنجاح. كلمة المرور الجديدة هي ",
  clickHere: "اضغط هنا لـ",
  tfollowUp: "متابعة",
  tyourCase: "قضيتك. ",
  msgSendFailed: "فشل إرسال الرسالة",
  msgSent: "تم إرسال الرسالة بنجاح",
  invalidCredentials: "رقم القضية/كلمة المرور غير صالحة",
  enterRepNo: "من فضلك أدخل رقم تقريرك",
  resetError:
    "يحظر إعادة الضبط مرة ثانية لمدة 24 ساعة. من فضلك حاول مرة أخرى غداً أو أرسل رسالة إلكترونية إلى Ethics@Wal-mart.com إذا كان لديك معلومات إضافية. يجب أن تدرج رقم قضية الأخلاق الخاصة بك في عنوان الرسالة. ",

  followUpTxt: "من فضلك أدخل رقم البلاغ وكلمة المرور لمتابعة القضية",
  emergencyNote:
    "لا ينبغي استخدام الموقع للأمور الطارئة التي تتطلب إخطاراً شفهياً عاجلاً. في الحالات الطارئة، من فضلك تواصل مع خدمات الطوارئ المحلية.",
  understand: "أفهم",
  ttWhatHappened: "ماذا حدث؟",
  lblIncidentDetails: "تفاصيل الواقعة",
  ttWhereHappened: "أين حدثت؟",
  pSelectLocation: "اختر الموقع من قائمة الخيارات",
  lblCountry: "الدولة",
  lblState: "الولاية",
  lblCity: "المدينة",
  lblLocation: "الموقع",
  phMissingPlace: "إذا لم تجد الموقع أعلاه، أدخله هنا",
  requestInfo: "من فضلك أدخل المعلومات التالية التي تتعلق بما يقلقك",
  liDateofSituation: "التاريخ/وقت حدوث الواقعة",
  liAssociatesInvovled:
    "أسماء الزملاء المشاركين بما فيهم من قد يكون لديه علم أو شاهد الموقف الذي تصفه.",
  liSituationOccured: "الموقع الذي حدث فيه الموقف",
  liReportedAnyone:
    "هل أبلغت عن قلقك لأي شخص في الشركة؟ إذا كانت الإجابة نعم، من هو الشخص ومتى أبلغته؟",
  spIfNoLocation: "فقط إذا لم تجد الموقع أعلاه",
  ttWhoInvolved: "من كان متورطاً؟",
  pOtherPerson: "من فضلك اذكر أسماء الآخرين الذين شاركوا في الواقعة",
  btnAddPerson: "أضف واحداً آخر",
  ttContactInfo: "معلومات التواصل",
  pAnonymous: "هل ترغب في أن تظل غير معروف؟",
  btnReviewForm: "استمارة المراجعة",
  phFirstName: "الاسم الأول",
  phLastName: "اسم العائلة",
  lblJobTitle: "مسمى الوظيفة",
  IncidentRole: "الدور في الواقعة",
  btnRemove: "إزالة",
  phPhoneNo: "رقم الهاتف",
  phEmail: "البريد الإلكتروني",
  valEmail: "من فضلك أدخل بريداً إلكترونياَ سارياً",
  ttReportReview: "مراجعة البلاغ",
  ttConfirmDetails: "من فضلك تأكد من تفاصيل البلاغ واكتب كلمة المرور",
  ttIncidentDetails: "تفاصيل الواقعة",
  ttIncidentDescription: "وصف الواقعة",
  ttIncidentLocation: "محل الواقعة",
  pImAnonymous: "غير معروف",
  btnEditReport: "تعديل البلاغ",
  fhtPasswordDesc: "تستخدم كلمة المرور لمتابعة بلاغك",
  pPasswordNote:
    "هام: تحتاج لرقم بلاغك وكلمة المرور للدخول إلى صفحة المتابعة للتحقق من حالة بلاغك، أو تقديم معلومات إضافية. لحماية خصوصيتك، لا يمكن استعادة هذه المعلومات في تاريخ لاحق. ونحن لا نرد أو نتابع عبر بريدك الإلكتروني مباشرة.",
  ttAdditionalInfo:
    "شكراً لتقديمك المعلومات الإضافية حول مخاوفك الخاصة بالأخلاق. سيراجع فريقنا إجابتك وقد يستخدم صفحة متابعة البلاغ لطلب تفاصيل أو معلومات إضافية، لذا تابع من فضلك الموقع بشكل منتظم.",
  ttReportNo: "تأكيد متابعة البلاغ",
  btnCompleteReport: "إكمال البلاغ",
  dReportDet: "تفاصيل البلاغ",
  sIncidentDesc: "وصف الواقعة",
  ttReportHistory: "تاريخ الواقعة",
  sRepSubOn: "تم تقديم البلاغ",
  btnReview: "مراجعة",
  pCaseClosed:
    "تم إغلاق هذه القضية. إذا كانت لديك أي مخاوف أخرى، من فضلك قم بزيارة صفحة الإبلاغ عن مخاوفك لكي تبلغ عن مخاوفك",
  ComFrmEthics: "الرسائل من إدارة الأخلاق",
  Reporter: "المُبلغ",
  System: "النظام",
  hReport: "البلاغ",
  hCreated: "تم الإنشاء",
  ttThanksforReport: "شكراً لتقديمك مخاوفك حول الأخلاق",
  progressMsg: "نحن بصدد إنشاء مخاوفك الأخلاقية. الرجاء التحلي بالصبر ..",
  ttFollowUpDet: "تفاصيل المتابعة",
  pReportReview:
    "سيراجع مكتبنا للأخلاق العالمية بلاغك وسيقوم بالرد المبدئي خلال 3-5 أيام عمل عبر صفحة متابعة البلاغ.",
  pAdditionalInforReq:
    "قد يستخدم فريقنا صفحة متابعة البلاغ لطلب معلومات أو تفاصيل إضافية، لذا تفقد من فضلك الموقع بانتظام",
  pNeedPd:
    "ستحتاج إلى الاحتفاظ بكلمة المرور ورقم البلاغ لتتمكن من متابعة هذا البلاغ. لحماية خصوصيتك، لا يمكن استرجاع هذه المعلومات في وقت لاحق.",
  pReportNo: "رقم البلاغ",
  ttReportFlwUp: "متابعة البلاغ",
  ttEnterReportNo: "من فضلك أدخل رقم البلاغ وكلمة المرور لمتابعة القضية",
  btnFindReport: "البحث عن بلاغ",
  ttFollowReview: "متابعة المراجعة",
  ttConfirmDet: "من فضلك تأكد من تفاصيل البلاغ ",
  ttAddDetRes: "تفاصيل إضافية/رد",
  btnEdit: "تعديل",
  btnSubmit: "تقديم",

  forgotPd: "نسيت كلمة المرور",
  additionalInfo: "معلومات إضافية",
  justNow: "حالاً",
  fewMinutes: "منذ دقائق قليلة",
  hours: "منذ ساعة",
  month: "منذ شهر",
  year: "منذ سنة",
  start: "ابدأ",
  end: "إنهاء",
  yes: "نعم",
  no: "لا",
  genricError:
    "استخدم الطريقة البديلة من فضلك للتواصل معنا. يمكنك أن ترسل لنا بريداً إلكترونياً على Ethics@Wal-mart.com. ",
  wrongAnswer: "إجاباتك لم تتوافق مع البيانات لدينا. حاول مرة أخرى من فضلك.",
  resetLimit:
    "تمنع إعادة الضبط بعد ثلاث محاولات غير ناجحة. حاول مرة أخرى من فضلك خلال 15 دقيقة أو أرسل لنا بريداً إلكترونياً على Ethics@Wal-mart.com إذا كانت لديك معلومات إضافية. يجب أن تدرج رقم قضية الأخلاق في المكان المخصص للعنوان في البريد الإلكتروني.",
  resetPasswordHeader: "إعادة ضبط كلمة المرور لرقم البلاغ.",
  answerTxt:
    "أجب عن أسئلة الأمان أدناه للحصول على كلمة مرور جديدة (يجب أن تتطابق الإجابات بشكل تام حتى في الفراغات والحروف الكبيرة والصغيرة)",
  question1: "سؤال 1",
  question2: "سؤال 2",
  question3: "سؤال 3",
  btnVerify: "التأكد",
  btnReset: "إعادة الضبط",
  securtiyQuestionHdr:
    "اختر ثلاثة أسئلة للأمان وأجب عنهم لتتمكن من الحصول على كلمة مرور جديدة لبلاغك",
  generateButton: "الحصول على كلمة مرور",
  pPasswordtxt: "كلمة المرور",
  SecurityQuestions: [
    { id: 1, question: "في أية مدينة قابلت أول شريك لك؟" },
    { id: 2, question: "في أية مدينة ولد والدك؟" },
    { id: 3, question: "في أية مدينة حصلت على أول وظيفة لك؟" },
    { id: 4, question: "في أية مدينة ولدت والدتك؟" },
    { id: 5, question: "ما هو اسم العائلة لمعلمك المفضل في المدرسة الثانوية؟" },
    {
      id: 6,
      question: "ما هو اسم الكلية التي قدمت طلباً لدخولها ولكن لم تذهب إليها؟"
    },
    { id: 7, question: "ما هو الاسم الأوسط لوالدك؟" },
    { id: 8, question: "ما هو الاسم الأوسط لوالدتك؟" },
    {
      id: 9,
      question: "ما هو شهر وسنة الميلاد لأخيك/أختك الأكبر؟ (مثال، نوفمبر 1985)"
    },
    { id: 10, question: "ما  هو الاسم الأوسط لأخيك/أختك الأكبر؟" },
    { id: 11, question: "ما هو الاسم الأوسط لأخيك/أختك الأصغر؟" },
    { id: 12, question: "ما هو اسم المدرسة التي حضرت فيها الصف السادس؟" },
    { id: 13, question: "ما هو الاسم الأول لصديق طفولتك المفضل؟" }
  ],
  roleIncidentOptions: [
    { value: "subject", label: "الموضوع" },
    { value: "witness", label: "الشاهد" },
    { value: "affected_individual", label: "الشخص المتأثر" },
    { value: "other", label: "غير ذلك" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "الموظف" },
    { value: "management", label: "الإدارة" },
    { value: "hourly_associate", label: "الزميل بالساعة" },
    { value: "vendor_supplier", label: "البائع/المورد" },
    { value: "customer", label: "العميل" },
    { value: "other", label: "غير ذلك" }
  ]
};
//arabic end

export default arabic;
n
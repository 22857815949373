import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

class HealthCheckComponent extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          health: "",
        }
    }
  componentDidMount() {
    this.getStatus();
  }
  getStatus = async e => {
    await axios.get(
        `${process.env.PUBLIC_URL}/api/health-check`        
      )
      .then(
        res=> {
            console.log("testing");
             this.setState({health:res.status})
        }
      )
  }
  render() {
      return (
        <div className="MainClass">
            <h1>Status: {this.state.health+""}</h1>
        </div>  
      )
  }
}
export default (withRouter(HealthCheckComponent));
//Data for English language Start
const english = {
  msgSendFailed: "Failed to send message",
  ttPeopleInvolved: "People Involved",
  msgSent: "Your message has been sent successfully",
  invalidCredentials: "Invalid case number/Password",
  enterRepNo: "Please enter your report number",
  followUpTxt:
    "Please enter the report number and password to follow-up on the case", // report follow up,
  //report home page
  emergencyNote: `This website should not be used for emergency matters that require
      immediate verbal notification.
      <br />
      In case of emergency please contact your local emergency services.`,
  understand: "I understand",
  ttWhatHappened: "What Happened?",
  lblIncidentDetails: "Incident details",
  ttWhereHappened: "Where did it happen?",
  pSelectLocation: "Select location from menu options",
  lblCountry: "Country",
  lblState: "State",
  lblCity: "City",
  lblLocation: "Location",
  phMissingPlace: "If location not found above, enter here",
  requestInfo:
    "Please provide the following information regarding your concern",
  liDateofSituation: "Date/Time of the situation",
  liAssociatesInvovled:
    "Names of the associates involved including those who may have  knowledge or witnessed the situation you are describing",
  liSituationOccured: "The location where the situation occurred",
  liReportedAnyone:
    "Have you reported your concern to anyone in the company? If so, to whom and when?",
  spIfNoLocation: "Only needed if location was not found above",
  ttWhoInvolved: "Who was involved?",
  pOtherPerson: "Please provide the names of others involved in the incident",
  btnAddPerson: "Add another",
  ttContactInfo: "Contact Information",
  pAnonymous: "Do you wish to remain anonymous?",
  btnReviewForm: "Review Form",
  //details page - child component of report home page
  phFirstName: "First Name",
  phLastName: "Last Name",
  lblJobTitle: "Job title",
  IncidentRole: "Role In Incident",
  btnRemove: "Remove",
  phPhoneNo: "Phone Number",
  phEmail: "Email",
  valEmail: "Please enter valid email",

  // Report Review
  ttReportReview: "Report Review",
  ttConfirmDetails:
    "Please confirm the details of the report and create a Password",
  ttIncidentDetails: "Incident Details",
  ttIncidentDescription: "Description of Incident",
  ttIncidentLocation: "Location of the Incident",
  pImAnonymous: "Anonymous",
  btnEditReport: "EDIT REPORT",
  fhtPasswordDesc: "Password will be used to follow up on your report",
  pPasswordNote: ` IMPORTANT: You need your report number and Password to log-in to
    the follow-up page to see the status of your report, or provide
    an additional information. To protect your privacy, this
    information cannot be retrieved at a later date. We do not
    respond or follow-up directly to your email address.`,
  btnCompleteReport: "COMPLETE REPORT",

  // follow up confirmation
  ttReportNo: "Follow-up Confirmation for report",
  ttAdditionalInfo: ` Thank you for providing additional information on your ethics concern. <br />
    Our team will review you response and may use the Follow-Up Report page to request additional information or details, so please check the site regularly.
  `,

  // report confirm
  hReport: "Report",
  hCreated: "created",
  ttThanksforReport: "Thank you for submitting your ethics concern",
  progressMsg:
    "We are in process of creating your Ethics Concern. Please be patient..",
  ttFollowUpDet: "Follow-up Details",
  pReportReview: `Our Global Ethics Office will review your submission and will provide
   an
   <br /> initial response within 3-5 business days using our Follow-Up
   Report page.`,
  pAdditionalInforReq: ` Our team may use the Follow-Up Report page to request additional
   <br />
   information or details, so please check the site regularly`,
  pNeedPd: `You will need to note the password and report number in order <br />
   to follow-up on this report. To protect your privacy, this information
   <br /> cannot be retrieved at a later date`,
  pReportNo: "Report Number",
  ttReportFlwUp: "Report Follow-up",
  ttEnterReportNo:
    "Please enter the report number and password to follow-up on the case",
  btnFindReport: "Find Report",
  ttFollowReview: "Follow-up Review",
  ttConfirmDet: "Please confirm the details of the report",
  ttAddDetRes: "Additional Details/Response",
  btnEdit: "EDIT",
  btnSubmit: "SUBMIT",

  //Follow up reponse
  dReportDet: "Report Details",
  sIncidentDesc: "Description of the Incident",
  ttReportHistory: "Report History",
  sRepSubOn: "Report submitted on",
  btnReview: "REVIEW",
  pCaseClosed:
    "  The case has been closed. If you have additional ethics concerns, please go to Report a Concern to provide details of your concern.",
  ComFrmEthics: "Communication from Ethics",
  Reporter: "Reporter",
  System: "System",
  resetPassSuc: "Password reset successful. Your new password is ",
  clickHere: "Click here to ",
  tfollowUp: "Follow-up",
  tyourCase: "your case. ",
  //Reset Password,
  justNow: "Just now",
  fewMinutes: "few minutes ago",
  hours: "hour ago",
  month: "month ago",
  year: "year ago",
  additionalInfo: "Additional Info",
  forgotPd: "Forgot Password",
  daysAgo: " days ago",
  yes: "Yes",
  start: "Start",
  end: "End",
  no: "No",
  genricError:
    "Please use an alternative method to contact us. You can send an email to Ethics@Wal-mart.com or call 1-800-WM-Ethic if in the US (including Puerto Rico) or Canada.",
  wrongAnswer: "Your answers did not match with our data. Please try again.",
  resetError:
    "Second reset prohibited within 24-hour period. Please try again tomorrow or send an email to Ethics@Wal-mart.com if you have additional information to provide. You must include your Ethics case number in the email subject line.",
  resetLimit:
    "Reset prohibited after three unsuccessful attempts. Please try again in 15 minutes or send an email to Ethics@Wal-mart.com if you have additional information to provide. You must include your Ethics case number in the email subject line.",
  resetPasswordHeader: "Reset password for report number",
  answerTxt:
    "Answer below security questions to generate a new password (Answers should match exactly, including spacing & capitalization)",
  question1: "Question 1",
  question2: "Question 2",
  question3: "Question 3",
  btnVerify: "Verify",
  btnReset: "Reset",
  securtiyQuestionHdr:
    "Select three security questions and answer them to generate a password for your report",
  generateButton: "Generate Password",
  pPasswordtxt: "Password",
  SecurityQuestions: [
    { id: 1, question: "In what city did you meet your first spouse/partner?" },
    { id: 2, question: "In what city was your father born?" },
    { id: 3, question: "In what city was your first job?" },
    { id: 4, question: "In what city was your mother born?" },
    {
      id: 5,
      question: "What is the last name of your favorite teacher in high school?"
    },
    {
      id: 6,
      question:
        "What is the name of a college you applied to but didn't attend?"
    },
    { id: 7, question: "What is your father's middle name?" },
    { id: 8, question: "What is your mother's middle name?" },
    {
      id: 9,
      question:
        "What is your oldest sibling's birthday month and year? (e.g. November 1985)"
    },
    { id: 10, question: "What is your oldest sibling's middle name?" },
    { id: 11, question: "What is your youngest sibling's middle name?" },
    { id: 12, question: "What school did you attend for sixth grade?" },
    {
      id: 13,
      question: "What was the first name of your childhood best friend?"
    }
  ],
  roleIncidentOptions: [
    { value: "subject", label: "Subject" },
    { value: "witness", label: "Witness" },
    { value: "affected_individual", label: "Affected Individual" },
    { value: "other", label: "Other" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "Officer" },
    { value: "management", label: "Management" },
    { value: "hourly_associate", label: "Hourly Associate" },
    { value: "vendor_supplier", label: "Vendor/Supplier" },
    { value: "customer", label: "Customer" },
    { value: "other", label: "other" }
  ]
};

export default english;
n
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import "./followupconfirmation.css";
import { selectedLanguage } from "../../lang-config";
import { isEqual } from "lodash";
import { currentLang } from "../../helper";
import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);
const showLang = currentLang();
let localLanguageData = "";
const styles = (theme) => ({
  root: {
    margin: "30px auto",
    width: "75%"
  }
});

class FollowupConfirmationComponent extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location && this.props.location.responseData === undefined) {
      this.props.history.push({
        pathname: `/followup/locale=${showLang}`
      });
    }
    const parent_origin =
      window.location !== window.parent.location
        ? document.referrer !== ""
          ? document.referrer
          : "*"
        : document.location.href;
    this.myScrollToTop(parent_origin);
  }
  myScrollToTop = (parent_origin) => {
    window.parent.postMessage("scrollToTop", parent_origin);
  };

  render() {
    const { classes } = this.props;
    const LanguageData =
      this.props.location.LanguageData &&
      JSON.parse(
        DOMPurify.sanitize(JSON.stringify(this.props.location.LanguageData))
      );
    localLanguageData =
      isEqual(LanguageData, "") || isEqual(LanguageData, undefined)
        ? selectedLanguage()
        : LanguageData;

    return (
      <div className={classes.root} id="mainPage">
        <h1>
          {localLanguageData.ttReportNo} :{this.props.location.reportNumber}
        </h1>
        <h4
          dangerouslySetInnerHTML={{
            __html: localLanguageData.ttAdditionalInfo
          }}
        ></h4>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(FollowupConfirmationComponent));

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Details from "./Details";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import AnonymousForm from "./AnonymousForm";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { isEqual, debounce } from "lodash";
import { withRouter } from "react-router-dom";
import "./index.css";
import { selectedLanguage } from "../../lang-config";
import { currentLang } from "../../helper";
import { Countries } from "../../coreCountry"; //only for prod. Dev and cert does not have static countires.
import axios from "axios";
import { internalAPI } from "../../config";

import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

const showLang = currentLang();
const styles = (theme) => ({
  root: {
    margin: "30px auto",
    width: "75%"
  },
  data: {
    backgroundColor: "#c2d3da",
    paddingLeft: "25px",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: "10px",
    boxShadow: "0 0 14px #3c505080"
  },
  formControl: {
    //margin: theme.spacing(1),
    //minWidth: 150,
    //maxWidth: 300
  },
  multilineStyle: {
    Height: 120
  },
  textField1: {
    root: {
      position: "relative",
      height: "100px"
    }
  },
  group: {
    width: "10%"
  },
  ul: {
    margin: "0px",
    paddingLeft: "30px"
  },
  buttonStyle: {
    paddingTop: "15px",
    paddingRight: "20px",
    paddingBottom: "10px"
  },
  reviewButton: {
    paddingTop: "15px",
    paddingRight: "20px",
    paddingBottom: "10px",
    textAlign: "center"
  },
  input: {
    "&::placeholder": {
      color: "color: rgba(0,0,0,1) !important;",
      fontWeight: "bold"
    }
  },
  labelAsterisk: {
    color: "red"
  }
});
let LanguageData = "";
let errObject = {};

class EthicsForm extends React.Component {
  constructor(props) {
    super(props);
    let incidentDetail = this.getQueryVariable("detail");
    let fromWebOD = false;
    if (incidentDetail !== "") {
      fromWebOD = true;
    }
    this.state = {
      CountryName: "",
      StateName: "",
      CitiName: "",
      LocationName: "",
      CountriesList: "",
      CitiesList: "",
      StateList: "",
      LocationList: "",
      anonymousErr: true,
      incidentDetailsError: false,
      countryError: false,
      checked: false,
      incidentDetails: incidentDetail,
      detailsData: [],
      anonymousData: {},
      country: "",
      state: "",
      city: "",
      place: "",
      missingPlace: "",
      anonymousChecked: "no",
      addAnotherPerson: false,
      removePerson: false,
      numOfPersons: 1,
      persons: [],
      fromEdit: false,
      disableReviewForm: true,
      questionData: "",
      fontsize: "19px",
      phone: "",
      email: "",
      emailError: false,
      fromWebOD: fromWebOD
    };
    this.callEmailVal = debounce(function () {
      this.validEmail();
    }, 1000);
  }
  getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return "";
  };
  validEmail = () => {
    const regex = /^[^@]+@[^@]+\.[^@]+$/;
    const result = regex.test(this.state.email);

    if (result === false && this.state.email !== "") {
      errObject.emailErrorTxt = LanguageData.valEmail;
      this.setState({ emailError: true, disableReviewForm: true });
    } else {
      errObject.emailErrorTxt = "";
      this.setState({ emailError: false });
      this.errorCheckFn();
    }
  };

  handleEmailChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value
    });
    this.callEmailVal();
  };
  detailsCbFn = (detData) => {
    let joined = this.state.detailsData.concat(detData);
    let tempArray = joined.filter(function (key) {
      return key.index !== "" && key.index !== undefined;
    });

    let filterArray = tempArray.filter(
      (value, index, self) =>
        self.map((x) => x.index).lastIndexOf(value.index) === index
    );
    filterArray = filterArray.filter(function (el) {
      return (
        el.firstName !== "" ||
        el.lastName !== "" ||
        el.jobTitle !== "" ||
        el.roleInIncident !== ""
      );
    });
    this.setState({ detailsData: [...new Set(filterArray)] });
  };

  anonymousDataCbFn = (childData) => {
    if (isEqual(this.state.anonymousChecked, "no")) {
      if (
        isEqual(childData.lastName, undefined) ||
        isEqual(childData.firstName, undefined) ||
        isEqual(childData.lastName, "") ||
        isEqual(childData.firstName, "")
      ) {
        this.setState({ anonymousErr: true });
      } else {
        this.setState({ anonymousErr: false });
      }
    }
    this.errorCheckFn();
    this.setState({ anonymousData: childData });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    LanguageData = selectedLanguage();
    const editData = this.props.location.reportDatatoEdit;
    if (editData) {
      this.setState({
        checked: true,
        disableReviewForm: editData.disableReviewForm,
        anonymousErr: false,
        incidentDetails: editData.incidentDetails,
        detailsData: editData.detailsData,
        anonymousChecked: editData.anonymousChecked,
        anonymousData: editData.anonymousData,
        country: editData.country,
        state: editData.state,
        city: editData.city,
        place: editData.place,
        fontsize: editData.fontsize,
        missingPlace: editData.missingPlace,
        persons: editData.persons,
        numOfPersons: editData.numOfPersons,
        fromEdit: true,
        CountryName: editData.CountryName,
        StateName: editData.StateName,
        CitiName: editData.CitiName,
        LocationName: editData.LocationName,
        CountriesList: editData.CountriesList,
        StateList: editData.StateList,
        CitiesList: editData.CitiesList,
        LocationList: editData.LocationList,
        questionData: this.props.location.questionData,
        phone: editData.phone,
        email: editData.email,
        emailError: editData.emailError,
        fromWebOD: editData.fromWebOD
      });

      let array = [];
      for (let i = 1; i <= editData.numOfPersons; i += 1) {
        let j = this.generateKey(i);
        array.push(
          <Details
            LanguageData={LanguageData}
            key={j}
            number={editData.numOfPersons}
            data={editData.detailsData[i - 1]}
            handleRemovePerson={(i) => this.handleRemovePerson(i)}
            detailsCbFn={this.detailsCbFn}
          />
        );
      }
      this.setState({ persons: array });
    } else {
      let i = this.generateKey(this.state.numOfPersons);
      let joined = this.state.persons.concat(
        <Details
          LanguageData={LanguageData}
          key={i}
          number={this.state.numOfPersons}
          index={i}
          handleRemovePerson={(i) => this.handleRemovePerson(i)}
          detailsCbFn={this.detailsCbFn}
        />
      );
      this.setState({ persons: joined });
    }
    let fontSize =
      currentLang() === "bn" ||
      currentLang() === "fr" ||
      currentLang() === "hi" ||
      currentLang() === "pt" ||
      currentLang() === "pa" ||
      currentLang() === "jp"
        ? "19px"
        : "22px";
    this.setState({ fontsize: fontSize });
    this.getAllCountries();
    const parent_origin =
      window.location !== window.parent.location
        ? document.referrer !== ""
          ? document.referrer
          : "*"
        : document.location.href;
    this.myScrollToTop(parent_origin);
  }
  myScrollToTop = (parent_origin) => {
    window.parent.postMessage("scrollToTop", parent_origin);
  };
  handleIncidentDetailsChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value
    });
    this.errorCheckFn();
  };
  handleCountryChange = async (e) => {
    let _this = this;
    if (e && e.target && e.target.name === "checked") {
      await this.setState({
        [e.target.name]: !this.state.checked
      });
    } else {
      await this.setState({
        [e.target.name]: e.target.value
      });
    }
    const countryName = _this.state.CountriesList.filter(function (obj) {
      let object = "";
      if (obj.sys_id === _this.state.country) {
        object = obj.name;
      }
      return object;
    });
    await this.setState({
      CountryName: countryName[0].name
    });
    this.getAllStates();
    this.errorCheckFn();
  };
  handleStateChange = async (e) => {
    let _this = this;
    await this.setState({
      [e.target.name]: e.target.value
    });
    const stateName = _this.state.StateList.filter(function (obj) {
      let object = "";
      if (obj.sys_id === _this.state.state) {
        object = obj.name;
      }
      return object;
    });
    await this.setState({
      StateName: stateName[0].name
    });
    this.getAllCities();
  };
  handleCityChange = async (e) => {
    let _this = this;
    await this.setState({
      [e.target.name]: e.target.value
    });
    const citiName = _this.state.CitiesList.filter(function (obj) {
      let object = "";
      if (obj.sys_id === _this.state.city) {
        object = obj.name;
      }
      return object;
    });
    await this.setState({
      CitiName: citiName[0].name
    });
    this.getAllLocations();
  };
  handleLocationChange = async (e) => {
    let _this = this;
    await this.setState({
      [e.target.name]: e.target.value
    });
    const locationName = _this.state.LocationList.filter(function (obj) {
      let object = "";
      if (obj.id === _this.state.place) {
        object = obj.value;
      }
      return object;
    });

    await this.setState({
      LocationName: locationName[0].value
    });
  };
  getAllCountries = async () => {
    await axios.get(internalAPI.getAllCountries).then(
      (res) => {
        let response = { data: res.data.response };
        if (
          response &&
          response.data &&
          response.data !== "" &&
          response.data !== undefined
        ) {
          const Data = response.data.locations.sort(function (a, b) {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          this.setState({ CountriesList: Data });
        }
      },
      (error) => {
        const Data = Countries.records.sort(function (a, b) {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        this.setState({
          state: "",
          city: "",
          place: "",
          CitiName: "",
          LocationName: "",
          StateName: "",
          CountryName: "",
          CountriesList: Data
        });
      }
    );
  };
  getAllStates = async () => {
    await axios
      .get(
        internalAPI.stateSelctionApiUrl +
          `u_country_code_desc_name=` +
          this.state.country
      )
      .then(
        (res) => {
          let response = { data: res.data.response };
          if (
            response &&
            response.data &&
            response.data !== "" &&
            response.data !== undefined
          ) {
            const Data = response.data.locations.sort(function (a, b) {
              const textA = a.name.toUpperCase();
              const textB = b.name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            this.setState({
              state: "",
              city: "",
              place: "",
              CitiName: "",
              LocationName: "",
              StateName: "",
              CitiesList: "",
              LocationList: "",
              StateList: Data
            });
            if (
              response.data.locations &&
              response.data.locations.length === 0
            ) {
              this.setState({ state: this.state.country }, () => {
                this.getAllCities();
              });
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  getAllCities = async () => {
    await axios
      .get(
        internalAPI.citySelctionApiUrl +
          `u_country_code_desc_name=` +
          this.state.country +
          `&u_state_code_desc=` +
          this.state.state
      )
      .then(
        (res) => {
          let response = { data: res.data.response };
          if (
            response &&
            response.data &&
            response.data !== "" &&
            response.data !== undefined
          ) {
            const Data = response.data.locations.sort(function (a, b) {
              const textA = a.name.toUpperCase();
              const textB = b.name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            this.setState({
              city: "",
              place: "",
              CitiName: "",
              LocationName: "",
              LocationList: "",
              CitiesList: Data
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  getAllLocations = async () => {
    await axios
      .get(
        internalAPI.locationSelctionApiUrl +
          `u_country_code_desc_name=` +
          this.state.country +
          `&u_state_code_desc=` +
          this.state.state +
          `&u_addr_city=` +
          this.state.city
      )
      .then(
        (res) => {
          let response = { data: res.data.response };
          if (
            response &&
            response.data &&
            response.data !== "" &&
            response.data !== undefined
          ) {
            const loc = response.data.locations;
            const locList = [];

            loc.forEach((e) => {
              const val1 = e.u_loc_name !== null ? e.u_loc_name : "";
              const val2 =
                e.u_addr_postal_cd !== null ? "," + e.u_addr_postal_cd : "";
              const val3 =
                e.u_addr_street1 !== null ? "," + e.u_addr_street1 : "";
              const val4 =
                e.u_addr_street2 !== null && e.u_addr_street2 !== undefined
                  ? "," + e.u_addr_street2
                  : "";
              const val5 =
                e.u_addr_street3 !== null && e.u_addr_street3 !== undefined
                  ? "," + e.u_addr_street_3
                  : "";
              const val = [val1, val2, val3, val4, val5];
              locList.push({
                id: e.sys_id,
                value: "".concat(...val)
              });
            });
            this.setState({
              place: "",
              LocationName: "",
              LocationList: locList
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  errorCheckFn() {
    if (
      isEqual(this.state.country.length, 0) ||
      isEqual(this.state.incidentDetails.length, 0)
    ) {
      this.setState({ disableReviewForm: true });
    } else if (
      !isEqual(this.state.country.length, 0) &&
      !isEqual(this.state.incidentDetails.length, 0)
    ) {
      if (
        isEqual(this.state.anonymousChecked, "yes") &&
        !isEqual(this.state.disableReviewForm, false) &&
        !this.state.emailError
      ) {
        this.setState({ disableReviewForm: false });
      } else if (isEqual(this.state.anonymousChecked, "no")) {
        if (
          (isEqual(this.state.anonymousErr, true) &&
            !isEqual(this.state.disableReviewForm, true)) ||
          this.state.emailError
        ) {
          window.scrollTo(0, document.body.scrollHeight);
          this.setState({ disableReviewForm: true });
        }
        if (
          isEqual(this.state.anonymousErr, false) &&
          !isEqual(this.state.disableReviewForm, false) &&
          !this.state.emailError
        ) {
          this.setState({ disableReviewForm: false });
        }
      }
    }
  }

  handleReviewForm = () => {
    this.props.history.push({
      pathname: `/review/locale=${showLang}`,
      reportData: this.state,
      LanguageData: LanguageData
    });
  };
  generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };

  handleChange = async (e) => {
    if (e && e.target && e.target.name === "checked") {
      await this.setState({
        [e.target.name]: !this.state.checked
      });
    } else if (e && e.target && e.target.name === "phone") {
      const onlyNums = e.target.value.replace(/[^0-9]/g, "");
      const onlyNumsLength = onlyNums.length;

      if ((onlyNumsLength < 10 || onlyNumsLength > 10) && onlyNumsLength < 25) {
        await this.setState({ [e.target.name]: onlyNums });
      } else if (onlyNumsLength === 10) {
        const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        await this.setState({ [e.target.name]: number });
      }
    } else {
      await this.setState({
        [e.target.name]: e.target.value
      });
    }

    this.errorCheckFn();
  };
  handleAddPerson = (e) => {
    let array = [];
    for (let i = 1; i <= this.state.numOfPersons; i += 1) {
      let j = this.generateKey(i);
      array.push(
        <Details
          LanguageData={LanguageData}
          key={j}
          number={this.state.numOfPersons + 1}
          index={j}
          data={this.state.detailsData[i - 1]}
          handleRemovePerson={(i) => this.handleRemovePerson(i)}
          detailsCbFn={this.detailsCbFn}
        />
      );
    }
    let i = this.generateKey(this.state.numOfPersons + 1);
    let j = this.state.numOfPersons + 1;
    let joined = array.concat(
      <Details
        LanguageData={LanguageData}
        key={i}
        number={j}
        index={i}
        handleRemovePerson={(i) => this.handleRemovePerson(i)}
        detailsCbFn={this.detailsCbFn}
      />
    );
    this.setState({ persons: joined, numOfPersons: j });
  };
  handleRemovePerson(e, value) {
    let newDetailsData = [...this.state.detailsData];
    newDetailsData = newDetailsData.filter(function (obj) {
      return obj.index !== e.target.parentElement.value;
    });

    let filteredArray = [];
    for (let i = 1; i <= this.state.numOfPersons - 1; i += 1) {
      let j = this.generateKey(i);
      filteredArray.push(
        <Details
          LanguageData={LanguageData}
          key={j}
          index={j}
          number={this.state.numOfPersons - 1}
          data={newDetailsData[i - 1]}
          handleRemovePerson={(i) => this.handleRemovePerson(i)}
          detailsCbFn={this.detailsCbFn}
        />
      );
    }
    this.setState({
      persons: filteredArray,
      detailsData: newDetailsData,
      numOfPersons: this.state.numOfPersons - 1
    });
  }

  render() {
    const { classes } = this.props;
    let LanguageData =
      this.props.location.LanguageData &&
      JSON.parse(
        DOMPurify.sanitize(JSON.stringify(this.props.location.LanguageData))
      );
    LanguageData =
      isEqual(LanguageData, "") || isEqual(LanguageData, undefined)
        ? selectedLanguage()
        : LanguageData;
    return (
      <div className={classes.root} id="mainPage">
        {!this.state.checked ? (
          <div
            className={classes.data}
            style={{ backgroundColor: "#e0edf9" }}
            id="mainText_1"
          >
            <p
              style={{ fontSize: this.state.fontsize }}
              dangerouslySetInnerHTML={{ __html: LanguageData.emergencyNote }}
            />

            <FormControlLabel
              name={"checked"}
              control={
                <Checkbox
                  className={"checkbox1"}
                  name={"checked"}
                  checked={this.state.checked}
                  onChange={this.handleChange}
                />
              }
              label={
                <React.Fragment>
                  <span style={{ fontSize: "18px" }}>
                    {LanguageData.understand}{" "}
                  </span>
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </React.Fragment>
              }
            />
          </div>
        ) : null}

        <div style={{ marginTop: "45px" }}>
          <h1>{LanguageData.ttWhatHappened}</h1>
          <p>{LanguageData.requestInfo}:</p>
          <ul className={classes.ul}>
            <li>{LanguageData.liDateofSituation} </li>
            <li> {LanguageData.liAssociatesInvovled}</li>
            <li>{LanguageData.liSituationOccured}</li>
            <li> {LanguageData.liReportedAnyone}</li>
          </ul>

          <TextField
            disabled={!this.state.checked}
            id="filled-full-width"
            style={{ margin: 8 }}
            name={"incidentDetails"}
            value={this.state.incidentDetails}
            label={LanguageData.lblIncidentDetails}
            required
            fullWidth
            hiddenLabel
            margin="normal"
            multiline={true}
            variant="filled"
            helperText={`${this.state.incidentDetails.length}/4000`}
            InputProps={{ classes: { input: this.props.classes["input"] } }}
            inputProps={{
              maxLength: 4000
            }}
            onChange={this.handleIncidentDetailsChange}
          />

          <h1>{LanguageData.ttWhereHappened}</h1>
          <p style={{ height: "10px" }}>{LanguageData.pSelectLocation}</p>
          <div id="location_1">
            <FormControl className={classes.formControl} id="location_2">
              <InputLabel htmlFor="age-helper">
                {LanguageData.lblCountry}
                <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                disabled={
                  !this.state.checked || this.state.CountriesList === ""
                }
                className="selectStyle"
                value={this.state.country}
                onChange={this.handleCountryChange}
                displayEmpty
                name="country"
              >
                {this.state.CountriesList &&
                  this.state.CountriesList.map((item, index) => {
                    return (
                      <MenuItem key={item.name} value={item.sys_id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} id="location_2">
              <InputLabel htmlFor="age-helper" style={{ color: "black" }}>
                {LanguageData.lblState}
              </InputLabel>
              <Select
                disabled={!this.state.checked || this.state.StateList === ""}
                className="selectStyle"
                value={this.state.state}
                onChange={this.handleStateChange}
                displayEmpty
                name="state"
              >
                {this.state.StateList &&
                  this.state.StateList.map((item, index) => {
                    return (
                      <MenuItem key={item.name} value={item.sys_id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} id="location_2">
              <InputLabel htmlFor="age-helper">
                {LanguageData.lblCity}
              </InputLabel>
              <Select
                disabled={!this.state.checked || this.state.CitiesList === ""}
                className="selectStyle"
                value={this.state.city}
                onChange={this.handleCityChange}
                displayEmpty
                name="city"
              >
                {this.state.CitiesList &&
                  this.state.CitiesList.map((item, index) => {
                    return (
                      <MenuItem key={item.name} value={item.sys_id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <FormControl className={classes.formControl} id="location_2">
              <InputLabel htmlFor="age-helper">
                {LanguageData.lblLocation}
              </InputLabel>
              <Select
                disabled={!this.state.checked || this.state.LocationList === ""}
                className="selectStyle"
                value={this.state.place}
                onChange={this.handleLocationChange}
                displayEmpty
                name="place"
              >
                {this.state.LocationList &&
                  this.state.LocationList.map((item, index) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <TextField
              disabled={!this.state.checked}
              id="filled-full-width"
              name={"missingPlace"}
              value={this.state.missingPlace}
              style={{ margin: 8 }}
              placeholder={LanguageData.phMissingPlace}
              fullWidth
              margin="normal"
              variant="filled"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ classes: { input: this.props.classes["input"] } }}
              onChange={this.handleChange}
            />
            <span>{LanguageData.spIfNoLocation}</span>
          </div>
          <h1>{LanguageData.ttWhoInvolved}</h1>
          <p style={{ height: "10px" }}>{LanguageData.pOtherPerson}</p>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingTop: "20px",
              paddingBottom: "20px",
              margin: "10px"
            }}
          >
            {this.state.persons}
          </div>
          <div className={classes.buttonStyle}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleAddPerson}
              disabled={!this.state.checked}
            >
              {LanguageData.btnAddPerson}
            </Button>
          </div>
          <h1>{LanguageData.ttContactInfo}</h1>
          <p style={{ height: "20px" }}>{LanguageData.pAnonymous}</p>
          <RadioGroup
            aria-label="position"
            name="anonymousChecked"
            className={classes.group}
            value={this.state.anonymousChecked}
            onChange={this.handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" />}
              label={LanguageData.yes}
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label={LanguageData.no}
            />
          </RadioGroup>
          {isEqual(this.state.anonymousChecked, "no") ? (
            <AnonymousForm
              LanguageData={LanguageData}
              anonymousDataCbFn={this.anonymousDataCbFn}
              data={this.state.anonymousData}
            />
          ) : null}
          <div id="location_1">
            <TextField
              onChange={this.handleChange}
              id="filled-full-width"
              value={this.state.phone || ""}
              style={{ margin: 8 }}
              name="phone"
              placeholder={
                isEqual(this.state.anonymousChecked, "yes")
                  ? LanguageData.phPhoneNo + " (optional)"
                  : LanguageData.phPhoneNo
              }
              margin="normal"
              variant="filled"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ classes: { input: this.props.classes["input"] } }}
              className="textBox"
            />
            <TextField
              onChange={this.handleEmailChange}
              error={this.state.emailError}
              value={this.state.email || ""}
              id="filled-full-width"
              style={{ margin: 8 }}
              name="email"
              //  onLostPointerCapture ={this.validEmail()}
              placeholder={
                isEqual(this.state.anonymousChecked, "yes")
                  ? LanguageData.phEmail + " (optional)"
                  : LanguageData.phEmail
              }
              margin="normal"
              variant="filled"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ classes: { input: this.props.classes["input"] } }}
              className="textBox"
            />
            <FormHelperText style={{ fontSize: "10px", color: "red" }}>
              {errObject.emailErrorTxt}
            </FormHelperText>
          </div>

          <div className={classes.reviewButton}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleReviewForm}
              disabled={this.state.disableReviewForm}
            >
              {LanguageData.btnReviewForm}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(withRouter(EthicsForm));

//Data for hebrew language Start
const hebrew = {
  msgSendFailed: "שליחת ההודעה נכשלה",
  ttPeopleInvolved: "שמות המעורבים בתקרית",
  msgSent: "ההודעה נשלחה בהצלחה",
  invalidCredentials: "מספר המקרה ו/או סיסמה שגויים",
  enterRepNo: "יש להזין את מספר הדיווח שלך",
  followUpTxt:
    "יש להזין את מספר הדיווח ואת הסיסמה כדי להיכנס לדף המעקב של המקרה", // report follow up,
  //report home page
  emergencyNote: `אתר זה לא מיועד לדיווח על מקרי חירום שמחייבים להזעיק את שירותי החירום וההצלה ללא דיחוי. אם מדובר במקרה חירום, יש להתקשר אל מוקד החירום המתאים (משטרה, רפואת חירום, כבאות) באזורך.`,
  understand: "הבנתי",
  ttWhatHappened: "מה קרה?",
  lblIncidentDetails: "פרטי האירוע",
  ttWhereHappened: "היכן זה קרה?",
  pSelectLocation: "יש לבחור מיקום מהתפריט",
  lblCountry: "ארץ",
  lblState: "מדינה",
  lblCity: "עיר",
  lblLocation: "מיקום",
  phMissingPlace: "אם המיקום לא מופיע ברשימה שלעיל, יש לכתוב אותו כאן",
  requestInfo: "יש למסור את הפרטים הבאים על המקרה",
  liDateofSituation: "תאריך ושעת האירוע",
  liAssociatesInvovled:
    "שמות העובדים המעורבים, כולל כל מי שייתכן שיודע על האירוע או היה עד לו.",
  liSituationOccured: "המיקום שבו התרחש האירוע",
  liReportedAnyone: "האם דיווחת על המקרה למישהו בחברה? אם כן, למי דיווחת ומתי?",
  spIfNoLocation: "רק אם המיקום לא מופיע ברשימה שלעיל",
  ttWhoInvolved: "מי מעורב באירוע?",
  pOtherPerson: "יש למסור את שמותיהם של כל המעורבים באירוע",
  btnAddPerson: "הוספת שם נוסף",
  ttContactInfo: "פרטי קשר",
  pAnonymous: "האם ברצונך לדווח על המקרה באופן אנונימי?",
  btnReviewForm: "יש לוודא שהפרטים הבאים נכונים",
  //details page - child component of report home page
  phFirstName: "שם פרטי",
  phLastName: "שם משפחה",
  lblJobTitle: "תפקיד בעבודה",
  IncidentRole: "חלקו או חלקה באירוע",
  btnRemove: " הסרה",
  phPhoneNo: "מספר טלפון",
  phEmail: "כתובת מייל",
  valEmail: "יש להזין כתובת מייל במבנה תקין",

  // Report Review
  ttReportReview: "יש לעבור על פרטי הדיווח לפני שליחתו",
  ttConfirmDetails: "יש לאשר שכל הפרטים נכונים נכונים וליצור סיסמה",
  ttIncidentDetails: "פרטי האירוע",
  ttIncidentDescription: "תיאור האירוע",
  ttIncidentLocation: "המיקום שבו התרחש האירוע",
  pImAnonymous: "באופן אנונימי",
  btnEditReport: "עריכת הדיווח",
  fhtPasswordDesc: "הסיסמה משמשת לכניסה אל דף מעקב המקרה",
  pPasswordNote: `  הערה חשובה: יהיה עליך להזין את מספר המקרה ואת הסיסמה כדי להיכנס לדף מעקב המקרה או להוסיף פרטים. כדי לשמור על פרטיותך ולהגן על זהותך, לא נוכל לשלוח אליך שוב את הפרטים האלה במייל אם שכחת אותם.`,
  btnCompleteReport: "השלמת הדיווח",

  // follow up confirmation
  ttReportNo: "אישור מעקב אחרי מקרה שדווח",
  ttAdditionalInfo: ` תודה על שהוספת פרטים על החשד להתנהגות לא אתית. הצוות שלנו יעבור על המידע החדש וייתכן שיפנה אליך דרך דף מעקב המקרה כדי לבקש ממך פרטים נוספים. לפיכך, אנחנו מבקשים ממך להיכנס מדי פעם לדף מעקב המקרה ולהתעדכן בהתפתחויות.
    `,

  // report confirm
  hReport: "המקרה",
  hCreated: "תאריך יצירה",
  ttThanksforReport: "תודה לך על שדיווחת על חשד להתנהגות לא אתית",
  progressMsg: "אנחנו בתהליך של יצירת הדאגה האתית שלך. אנא התאזר בסבלנות..",
  ttFollowUpDet: "פרטי המעקב",
  pReportReview: `מחלקת האתיקה הבינלאומית שלנו תעבור על הדיווח שלך ותמסור תגובה ראשונית דרך דף המעקב של המקרה תוך 3 עד 5 ימי עבודה.`,
  pAdditionalInforReq: ` ייתכן שהצוות שלנו ישתמש בדף המעקב של המקרה כדי לבקש ממך פרטים נוספים ולכן אנחנו מבקשים ממך להיכנס מדי פעם לדף המעקב ולהתעדכן בהתפתחויות.`,
  pNeedPd: `מומלץ מאוד לשמור את מספר המקרה ואת הסיסמה מכיוון שיהיה עליך להזינם כדי להיכנס לדף מעקב המקרה. כדי לשמור על פרטיותך ולהגן על זהותך, נוכל לשלוח אליך שוב את הפרטים האלה במייל אם שכחת אותם`,
  pReportNo: "מספר הדיווח",
  ttReportFlwUp: "מעקב מקרה",
  ttEnterReportNo:
    "יש להזין את מספר הדיווח ואת הסיסמה כדי להיכנס לדף מעקב המקרה",
  btnFindReport: "חיפוש דיווח",
  ttFollowReview: "סקירת פרטי המעקב אחרי מקרה",
  ttConfirmDet: "יש לאשר שפרטי הדיווח נכונים",
  ttAddDetRes: "תגובה/בקשה לפרטים נוספים",
  btnEdit: "עריכה",
  btnSubmit: "שליחה",

  //Follow up reponse
  dReportDet: "פרטי הדיווח",
  sIncidentDesc: "תיאור האירוע",
  ttReportHistory: "היסטוריית הדיווח",
  sRepSubOn: "תאריך שליחת הדיווח",
  btnReview: "סקירה",
  pCaseClosed:
    "  המקרה נסגר. אם יש לך חשדות נוספים להתנהגות לא אתית יש להגיש דיווח חדש",
  ComFrmEthics: "פניות מצוות מחלקת האתיקה",
  Reporter: "המדווח",
  System: "המערכת",
  resetPassSuc: "הסיסמה אופסה בהצלחה. הסיסמה החדשה שלך היא: ",
  clickHere: "יש ללחוץ כאן כדי ",
  tfollowUp: "מעקב",
  tyourCase: "המקרה שלך. ",
  //Reset Password,
  justNow: "עכשיו",
  fewMinutes: "לפני זמן קצר",
  hours: "לפני שעה",
  month: "לפני חודש",
  year: "לפני שנה",
  additionalInfo: "פרטים נוספים",
  forgotPd: "שכחתי את הסיסמה",
  daysAgo: " ימים",
  yes: "כן",
  start: "התחלה",
  end: "סיום",
  no: "לא",
  genricError:
    "יש ליצור איתנו קשר בדרך אחרת. אפשר לכתוב אלינו אל Ethics@Wal-mart.com. ",
  wrongAnswer: "התשובות לא מתאימות למידע שבידינו. יש לנסות שוב.",
  resetError:
    "כאמצעי אבטחה, אפשר לאפס את הסיסמה רק פעם אחת ביום. אם ברצונך למסור פרטים נוספים יש לנסות שוב מחר או לכתוב אל Ethics@Wal-mart.com בציון מספר הדיווח שלך בשורת הנושא של המייל.",
  resetLimit:
    "כאמצעי אבטחה, לאחר שלושה ניסיונות איפוס לא מוצלחים האפשרות לאפס את הסיסמה תחסם זמנית. יש לנסות שוב בעוד 15 דקות או לכתוב אל Ethics@Wal-mart.com אם יש לך פרטים נוספים. יש לציין את מספר הדיווח שלך בשורת הנושא של המייל.",
  resetPasswordHeader: "איפוס סיסמה עבור מספר דיווח",
  answerTxt:
    "עליך לענות על שאלות האבטחה הבאות כדי ליצור סיסמה חדשה (התשובות חייבות להיות זהות לתשובות שהזנת בעת הדיווח על המקרה, כולל רווחים ואיות)",
  question1: "1 שאלה",
  question2: "2 שאלה",
  question3: "3 שאלה",
  btnVerify: "אימות",
  btnReset: "איפוס סיסמה",
  securtiyQuestionHdr:
    "יש לבחור 3 שאלות אבטחה ולענות עליהן כדי לאפס את הסיסמה עבור המקרה שעליו דיווחת:",
  generateButton: "יצירת סיסמה חדשה",
  pPasswordtxt: "סיסמה",
  SecurityQuestions: [
    {
      id: 1,
      question: "באיזו עיר הכרת את בן זוגך הראשון או את בת זוגך הראשונה?"
    },
    { id: 2, question: "באיזו עיר נולד אביך?" },
    { id: 3, question: "באיזו עיר הייתה העבודה הראשונה שלך?" },
    { id: 4, question: "באיזו עיר נולדה אמא שלך?" },
    {
      id: 5,
      question: "מהו שם המשפחה של המורה האהוב או האהובה עליך בתיכון?"
    },
    {
      id: 6,
      question: "לאיזו אוניברסיטה ניסית להתקבל אבל בסוף למדת במקום אחר?"
    },
    { id: 7, question: "מהו שמו האמצעי של אבא שלך?" },
    { id: 8, question: "מהו שמה האמצעי של אמא שלך?" },
    {
      id: 9,
      question:
        "באיזה חודש ושנה נולד אחיך הבכור/אחותך הבכורה (לדוגמה: נובמבר 1985)?"
    },
    { id: 10, question: "מהו שמו האמצעי של אחיך הבכור/אחותך הבכורה?" },
    { id: 11, question: "מהו שמו האמצעי של אחיך הקטן/אחותך הקטנה?" },
    { id: 12, question: "מהו שם בית הספר היסודי שבו למדת?" },
    {
      id: 13,
      question: "מהו שמו של חבר/חברת הילדות הטובים ביותר שלך?"
    }
  ],
  roleIncidentOptions: [
    { value: "subject", label: "הנושא" },
    { value: "witness", label: "עד" },
    { value: "affected_individual", label: "האדם שנפגע" },
    { value: "other", label: "אחר" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "נושא משרה" },
    { value: "management", label: "הנהלה" },
    { value: "hourly_associate", label: "עובד שעתי" },
    { value: "vendor_supplier", label: "ספק" },
    { value: "customer", label: "לקוח" },
    { value: "other", label: "אחר" }
  ]
};

export default hebrew;
n
const spanish = {
  ttPeopleInvolved: "Personas involucradas",
  msgSent: "Su mensaje fue enviado exitosamente",
  msgSendFailed: "Su mensaje no pudo ser enviado ahora",
  enterRepNo: "Por favor ingrese su número de informe",
  invalidCredentials: "Número de caso / contraseña no válidos",
  resetPassSuc:
    "Restablecimiento de contraseña exitoso. Su nueva contraseña es ",
  clickHere: "Click aquí para ",
  tfollowUp: "Darle seguimiento",
  tyourCase: "Tu caso. ",
  followUpTxt:
    "Por favor, introduzca el número del reporte y la contraseña para para dar seguimiento a su a su reporte o para brindarnos detalles adicionales.",
  emergencyNote:
    "Entiendo que no se debe usar este sitio web en situaciones de emergencia que requieran una notificion verbal inmediata. En caso de emergencia, llame a su servicio de emergencia local.",
  understand: "Entiendo",
  ttWhatHappened: "¿Que sucedió?",
  lblIncidentDetails: "Detalles del incidente",
  ttWhereHappened: "¿Dónde sucedió?",
  pSelectLocation: "Seleccione una ubicación del menú",
  lblCountry: "Pais",
  lblState: "Estado",
  lblCity: "Ciudad",
  lblLocation: "Lugar",
  phMissingPlace: "Si la ubicación no se encuentra arriba, ingrese aquí",
  requestInfo: "Proporcione la siguiente información acerca de su inquietud",
  liDateofSituation: "Fecha/Hora de la situación",
  liAssociatesInvovled:
    "Los nombres de los asociados involucrados, incluyendo aquellos que puedan tener conocimiento o que fueron testigos de la situación que está describiendo",
  liSituationOccured: "La ubicación donde se ocasionó la situación",
  liReportedAnyone:
    "¿Has reportado tu inquietud a alguien en la empresa? ¿Si es así, a quién y cuándo?",
  spIfNoLocation: "Sólo es necesario si no encontró la ubicación arriba",
  ttWhoInvolved: "¿Quien estaba involucrado?",
  pOtherPerson:
    "Por favor agregue los nombres de otras personas involucradas en el incidente",
  btnAddPerson: "Agrega otro",
  ttContactInfo: "Información del contacto",
  pAnonymous: "¿Desea guardar el anonimato?",
  btnReviewForm: "Revise el formulario",
  phFirstName: "Primer nombre",
  phLastName: "Apellido",
  end: "fin",
  start: "Iniciar",
  lblJobTitle: "Cargo",
  IncidentRole: "Rol en el incidente",
  btnRemove: "Remover",
  phPhoneNo: "Número de teléfono",
  phEmail: "Dirección de correo electrónico",
  valEmail: "Por favor introduzca una dirección de correo electrónico válida",
  ttReportReview: "Revisión del Reporte ",
  ttConfirmDetails: "Confirme los detalles del reporte y cree una contraseña.",
  ttIncidentDetails: "Detalles del incidente",
  ttIncidentDescription: "Descripción del incidente",
  ttIncidentLocation: "Ubicación del incidente ",
  pImAnonymous: "Anónimo ",
  btnEditReport: "Editar Reporte",
  fhtPasswordDesc: "La contraseña se usará para dar seguimiento a su reporte",
  pPasswordNote:
    "IMPORTANTE: Necesita su número de reporte y una contraseña para ingresar a la página de seguimiento y ver el estatus de su reporte, o para proporcionar información adicional. Para proteger su privacidad, esta información no puede ser recuperada en una fecha posterior. No responderemos o daremos seguimiento directamente a través de su correo electrónico.",
  ttAdditionalInfo:
    "Gracias por proporcionar información adicional sobre su inquietud. Nuestro equipo revisará su respuesta y utilizará la página de seguimiento para solicitar más información o detalles, por favor consulte el sitio regularmente.",
  ttReportNo: "Confirmación de seguimiento del reporte ",
  btnCompleteReport: "Reporte Completo",
  dReportDet: "Detalles del Reporte",
  sIncidentDesc: "Descripción del incidente ",
  ttReportHistory: "Historial del Reporte",
  sRepSubOn: "Reporte presentado el",
  btnReview: "Revisar",
  pCaseClosed:
    "Este caso se ha cerrado. Si usted tiene algun otra inquietud, visite la página “Reportar una Inquietud” para reportarlo ",
  ComFrmEthics: "Comunicación de Etica",
  Reporter: "Denunciante",
  System: "Sistema",
  hReport: "Reporte",
  hCreated: "Creado",
  ttThanksforReport: "Gracias por enviarnos su inquietud",
  progressMsg:
    "Estamos en proceso de crear su inquietud de ética. Por favor sea paciente..",
  ttFollowUpDet: "Detalles sobre su seguimiento",
  pReportReview:
    "Nuestra Oficina de Ética Global repaso su sumisión y proporcionará una respuesta inicial dentro de 3-5 días laborables a través de nuestra página de Seguimiento de Reporte.",
  pAdditionalInforReq:
    "Nuestro equipo puede utilizar la página “Seguimiento a un Reporte” para solicitar información o detalles adicionales, por lo tanto, visite el sitio regularmente",
  pNeedPd:
    "Deberá anotar la contraseña y el número del reporte para poder dar seguimiento a este reporte. Para proteger su privacidad, esta información no se puede recuperar en una fecha posterior",
  pReportNo: "Numero de Reporte",
  ttReportFlwUp: "Seguimiento de reporte",
  ttEnterReportNo:
    "Ingrese el número de Reporte y la contraseña para dar seguimiento al caso",
  btnFindReport: "Encontrar Reporte",
  ttFollowReview: "Revisión de Seguimiento",
  ttConfirmDet: "Por favor confirme los detalles del reporte",
  ttAddDetRes: "Detalles adicionales / Respuesta",
  btnEdit: "EDITAR",
  btnSubmit: "ENVIAR",
  additionalInfo: "Información adicional",
  justNow: "Justo ahora",
  fewMinutes: "hace minutos",
  hours: " hace una hora",
  month: " hace un mes",
  daysAgo: " hace días",
  minutesAgo: " hace minutos",
  year: " hace años",
  forgotPd: "Se te olvidó tu contraseña",
  yes: "Sí",
  no: "No",
  genricError:
    "Por favor, utilice un método alternativo para ponerse en contacto con nosotros. Puede enviar un correo electrónico a Ethics@Wal-mart.com.",
  resetError:
    "Second reset prohibited within 24-hour period. Please try again tomorrow or send an email to Ethics@Wal-mart.com if you have additional information to provide. You must include your Ethics case number in the email subject line.",
  wrongAnswer:
    "Sus respuestas no coinciden con nuestros datos. Por favor, inténtelo de nuevo.",
  resetLimit:
    "Restablecer prohibido después de tres intentos fallidos. Intente nuevamente en 15 minutos o envíe un correo electrónico a Ethics@Wal-mart.com si tiene información adicional para proporcionar. Debe incluir su número de caso de Ética en la línea de asunto del correo electrónico.",
  resetPasswordHeader: "Restablecer contraseña para número de informe",
  answerTxt:
    "Responda las preguntas de seguridad a continuación para generar una nueva contraseña (las respuestas deben coincidir exactamente, incluido el espacio y las mayúsculas)",
  question1: "Pregunta 1",
  question2: "Pregunta 2",
  question3: "Pregunta 3",
  btnVerify: "Verificar",
  btnReset: "Restablecer",
  securtiyQuestionHdr:
    "Seleccione tres preguntas de seguridad y responda para generar una contraseña para su informe",
  generateButton: "Generar contraseña",
  pPasswordtxt: "Contraseña",
  SecurityQuestions: [
    {
      id: 1,
      question: "¿En qué ciudad conociste a tu primer cónyuge / pareja?"
    },
    { id: 2, question: "¿En qué ciudad nació tu padre?" },
    { id: 3, question: "¿En qué ciudad fue tu primer trabajo?" },
    { id: 4, question: "¿En qué ciudad nació tu madre?" },
    {
      id: 5,
      question:
        "¿Cuál es el apellido de tu maestro favorito en la escuela secundaria?"
    },
    {
      id: 6,
      question:
        "¿Cuál es el nombre de una universidad a la que solicitó, pero no asistió?"
    },
    { id: 7, question: "¿Cuál es el segundo nombre de tu padre?" },
    { id: 8, question: "¿Cuál es el segundo nombre de tu madre?" },
    {
      id: 9,
      question:
        "¿Cuál es el mes y año de cumpleaños de tu hermano mayor? (por ejemplo, noviembre de 1985)"
    },
    { id: 10, question: "¿Cuál es el segundo nombre de su hermano mayor?" },
    { id: 11, question: "¿Cuál es el segundo nombre de su hermano menor?" },
    { id: 12, question: "¿A qué escuela asististe durante el sexto grado?" },
    {
      id: 13,
      question: "¿Cuál fue el primer nombre de su mejor amigo de su infancia?"
    }
  ],
  roleIncidentOptions: [
    { value: "subject", label: "Sujeto" },
    { value: "witness", label: "Testigo" },
    { value: "affected_individual", label: "Individuo afectado" },
    { value: "other", label: "Otro" }
  ],
  jobTitleOptions: [
    { value: "officer", label: "Oficial" },
    { value: "management", label: "Gerencia" },
    { value: "hourly_associate", label: "Asociado por hora" },
    { value: "vendor_supplier", label: "Proveedor" },
    { value: "customer", label: "Cliente" },
    { value: "other", label: "Otro" }
  ]
};

export default spanish;
